import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { FaBookOpen } from "react-icons/fa";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart, removeFromCart } from "../../redux/actions/cart";
import formatNumber from "../../utils/formatNumber";



const Cart = ({ setOpenCart }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  // const totalPrice = cart.reduce(
  //   (acc, item) => acc + item.qty * item?.originalPrice,
  //   0
  // );


  // const totalPrice = cart.reduce(
  //   (acc, item) => {
  //     const qty = Number(item.qty) || 0;
  //     const originalPrice = Number(item?.originalPrice) || 0;
  //     return acc + qty * originalPrice;
  //   },
  //   0
  // ).toFixed(2)

  const totalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.originalPrice,
    0
  );

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };

  return (
    <div className="fixed top-0 left-0 w-full bg-[#0000004b] h-screen z-20">
      <div className="fixed top-0 right-0 h-full w-[80%] 800px:w-[25%] bg-darkblue flex flex-col overflow-y-scroll justify-between shadow-sm">
        {cart && cart.length === 0 ? (
          <div className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full justify-end pt-5 pr-5 fixed top-3 right-3">
              <RxCross1
                size={25}
                className="cursor-pointer"
                onClick={() => setOpenCart(false)}
              />
            </div>
            <h5 className="font-semibold text-white"> Enrolment list is empty!</h5>
          </div>
        ) : (
          <>
            <div className="bg-darkblue">
              <div className="flex w-full justify-end pt-5 pr-5 ">
                <RxCross1
                  size={25}
                  className="cursor-pointer bg-red-900 text-white"
                  onClick={() => setOpenCart(false)}
                />
              </div>
              {/* Item length */}
              <div className={`${styles.noramlFlex} p-4 bg-deepblue mt-4`}>
                <FaBookOpen size={25} className="text-white" />
                <h5 className="pl-2 text-[20px] font-[600] text-white">
                  {cart && cart.length} Application in progress
                </h5>
              </div>

              {/* cart Single Items */}
              <br />
              <div className="w-full">
                {cart &&
                  cart.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      quantityChangeHandler={quantityChangeHandler}
                      removeFromCartHandler={removeFromCartHandler}
                    />
                  ))}
              </div>
            </div>

            <div className="px-5 mb-3">
              {/* checkout buttons */}
              <Link to="/checkout">
                <div
                  className={`h-[45px] flex items-center justify-center w-[100%] bg-deepblue rounded-[5px]`}
                >
                  <h1 className="text-white text-[18px] font-[600] process ">
                    Proceed for registration {" "}
                    {/* ${formatNumber(totalPrice)} */}
                      {formatNumber(totalPrice)}
                  </h1>
                </div>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({ data, quantityChangeHandler, removeFromCartHandler }) => {
 
  const totalPrice = data.originalPrice * 1;


  return (
    <div className="p-4">
      <div className="w-full flex items-center">
        <img
          src={`${data?.images1[0]?.url}`}
          alt=""
          className="w-[130px] h-min ml-2 mr-2 rounded-[5px]"
        />
        <div className="pl-[5px] mr-2">
          <h1 className="font-semibold text-white">{data.name}</h1>
          {/* <h4 className="font-[400] text-[15px] text-[#00000082]">
            ${data.originalPrice * 1} 
          </h4> */}
          <h4 className="font-[600] text-[17px] pt-[3px] text-white font-Roboto eyes">
            {formatNumber(totalPrice)}
            {/* ${isNaN(totalPrice) ? "0.00" : totalPrice.toFixed(2)} */}
          </h4>

        </div>
        <RxCross1
          className="cursor-pointer bg-red-900 text-white font-[600] p-1"
          onClick={() => removeFromCartHandler(data)}
          size={25}
        />
      </div>
    </div>
  );
};

export default Cart;
