import React, { useEffect, useState } from "react";
import { AiOutlineMoneyCollect } from "react-icons/ai";
import { MdBorderClear } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfAdmin } from "../../redux/actions/order";
import Loader from "../Layout/Loader";
import { getAllSellers } from "../../redux/actions/sellers";
import formatNumber from "../../utils/formatNumber";

const AdminDashboardMain = () => {
  const dispatch = useDispatch();
  const { adminOrders, adminOrderLoading } = useSelector((state) => state.order);
  const { sellers } = useSelector((state) => state.seller);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
    dispatch(getAllSellers());
  }, [dispatch]);

  const totalPages = Math.ceil((adminOrders?.length || 0) / itemsPerPage);
  const currentOrders = adminOrders?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  ) || [];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const adminEarning = adminOrders && adminOrders.reduce((acc, item) => acc + item.totalPrice, 0); //this code here shows the exact amount without interest

  const adminBalance = adminEarning?.toFixed(2);


  const columns = [
    { field: "id", headerName: "Course ID" },
    { field: "userName", headerName: "Registration Name" },
    { field: "RegistrationEmail", headerName: "Registration Email" },
    { field: "RegistrationNumber", headerName: "Mobile Number" },
    { field: "total", headerName: "Course Fee" },
    { field: "createdAt", headerName: "Application Date" },
  ];

  // const rows = [];
  // adminOrders &&
  //   adminOrders.forEach((item) => {
  //     rows.push({
  //       id: item._id,
  //       itemsQty: item?.cart?.reduce((acc, item) => acc + item.qty, 0),
  //       total: item?.totalPrice,
  //       status: item?.status,
  //       createdAt: item?.createdAt.slice(0, 10),
  //     });
  //   });

  const rows = currentOrders?.map((item) => ({
    id: item._id,
    // itemsQty: item?.cart?.reduce((acc, item) => acc + item.qty, 0),
    total: "$" + item?.totalPrice,
    // status: item.status,
    userName: item.user?.name,
    RegistrationEmail: item?.user?.email,
    RegistrationNumber: item?.user?.phoneNumber,
    //RegistrationAddress: item?.user?.addresses,
    //CourseName: item?.productId?.name,
    createdAt: item?.createdAt.slice(0, 10),
  }));
  return (
    <>
      {
        adminOrderLoading ? (
          <Loader />
        ) : (
          <div className="w-full p-2 md:p-6 bg-deepblue h-[88vh] md:h-[88vh] overflow-hidden">
            <div className="bg-white relative w-full p-2 md:p-4 h-[85vh] md:h-[82vh] rounded-xl mt-0 overflow-x-scroll md:overflow-x-hidden">
              <div className="flex items-center justify-start w-full max-w-[100%] lg:max-w-[100%] 2xl:max-w-[100%] z-10 px-4 py-2 rounded-lg bg-darkblue">
                <h3 className="text-lg font-semibold text-white sm:text-xl lg:text-2xl font-Roboto">
                  ADMIN DASHBOARD
                </h3>
              </div>

              <div className="block w-full mt-2 lg:mt-4 md:flex md:justify-between">
                {/* Total Earning section goes here */}
                <div className="w-full lg:mb-4 md:w-[30%] bg-white shadow lg:pl-1 lg:pr-3 py-2 lg:py-4 rounded-xl">
                  <div className="grid border-2 border-darkblue">
                    <div className="flex items-center justify-start px-2 py-1 bg-darkblue">

                      <AiOutlineMoneyCollect size={30} className="mr-2 text-white" />
                      <h3 className="text-[18px] font-[400] text-white">
                        Total Earning
                      </h3>
                    </div>
                    <div className="flex h-24 bg-white">
                      <h5 className="py-2 pl-[50px] text-[22px] font-[500] text-black">
                        {formatNumber(adminBalance)}
                      </h5>
                    </div>
                  </div>
                </div>
                {/* Total Earning Section Ends here */}

                {/* All Staff Section Goes Here */}
                <div className="w-full lg:mb-4 md:w-[30%] bg-white shadow lg:pl-1 lg:pr-3 py-2 lg:py-4 rounded-xl">
                  <div className="grid border-2 border-darkblue">
                    <div className="flex items-center justify-start px-2 py-1 bg-darkblue">
                      <MdBorderClear size={30} className="mr-2 text-white" />
                      <h3 className="text-[18px] font-[400] text-white">
                        All Staff
                      </h3>
                    </div>
                    <div className="flex h-12 bg-white">
                      <h5 className="py-2 pl-[50px] text-[22px] font-[500] text-black">
                        {sellers && sellers.length}
                      </h5>
                    </div>
                    <div className="flex items-center justify-center w-full h-12 bg-deepblue">
                      <Link to="/admin-sellers">
                        <h5 className=" pl-2 font-medium text-[#fff]">View Staff</h5>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* All staff Section Ends Here */}

                {/* All Applied Courses Goes Here */}
                <div className="w-full lg:mb-4 md:w-[30%] bg-white shadow lg:pl-1 lg:pr-3 py-2 lg:py-4 rounded-xl">
                  <div className="grid border-2 border-darkblue">
                    <div className="flex items-center justify-start px-2 py-1 bg-darkblue">
                      <MdBorderClear size={30} className="mr-2 text-white" />
                      <h3 className="text-[18px] font-[400] text-white">
                        All Applied Courses
                      </h3>
                    </div>
                    <div className="flex h-12 bg-white">
                      <h5 className="py-2 pl-[50px] text-[22px] font-[500] text-black">
                        {adminOrders && adminOrders.length}
                      </h5>
                    </div>
                    <div className="flex items-center justify-center w-full h-12 bg-deepblue">
                      <Link to="/admin-orders">
                        <h5 className=" pl-2 font-semibold text-[#fff]">View Courses</h5>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* All Applied Courses Ends Here */}

              <div className="hidden px-3 py-3 rounded-lg bg-darkblue sm:block">
                <h3 className="text-[22px] font-Roboto text-white">All Registered Courses</h3>
              </div>
              <div className="w-full h-[34vh] max-h-[65vh] bg-white rounded mt-4 overflow-y-scroll overflow-x-hidden hidden sm:block">
                <div className="w-full pt-1 bg-white mt-2 max-h-[70vh] overflow-x-auto">
                  <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                      <tr className="text-left bg-gray-200">
                        {columns.map((column) => (
                          <th
                            key={column.field}
                            className="px-2 py-2 text-xs font-semibold text-gray-700 sm:px-4 sm:text-sm md:text-base"
                          >
                            {column.headerName}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row) => (
                        <tr key={row.id} className="border-b">
                          {columns.map((column) => (
                            <td
                              key={column.field}
                              className="px-2 py-2 text-xs text-gray-600 sm:px-4 sm:text-sm md:text-base"
                            >
                              {row[column.field]}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* Pagination Controls */}
                  <div className="flex justify-center pb-10 mt-4">
                    <button
                      className={`px-3 py-1 mx-1 text-xs sm:text-sm font-semibold text-white bg-blue-500 rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index}
                        className={`px-3 py-1 mx-1 text-xs sm:text-sm font-semibold ${currentPage === index + 1
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200 text-gray-700"
                          } rounded`}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}
                    <button
                      className={`px-3 py-1 mx-1 text-xs sm:text-sm font-semibold text-white bg-blue-500 rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        )
      }
    </>
  );
};

export default AdminDashboardMain;
