
// const formatNumber = (digit) => {
//     return new Intl.NumberFormat('en-NG').format(digit)
// }

// export default formatNumber;

const formatNumber = (digit) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(digit);
}

export default formatNumber;