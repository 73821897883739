import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import CourseBanner from "../components/CourseBanner";
import banner from '../images/banner.png';
import logo from '../images/logo.png';
import Container from "../components/Container";
import Heading from "../components/Heading";
import OurTeam from "../components/OurTeam";

const AboutusPage = () => {
  return (
    <div>
      <Header />
      <CourseBanner imageUrl={banner}>
        <h1 className="font-bold">ABOUT US PAGE</h1>
      </CourseBanner>
      <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-12 md:py-12 md:px-6 py-9 px-4">
       
        <Container>
          <Heading left title="About Us" />
          <div className="max-w-full px-0 md:px-8 mx-auto lg:px-0 grid grid-cols-1 lg:flex items-start justify-center gap-10">
            <p className="order-last lg:order-first font-normal md:text-lg lg:text-xl xl:text-2xl  md:mt-0 lg:mt-3 xl:mt-5 leading-normal xl:leading-loose  text-justify">
              we believe in leveraging potentials to drive meaningful change and empower individuals and
              businesses to reach new heights of success. With a diverse team of experts and a passion for
              excellence, we provide a comprehensive range of consulting services tailored to meet the
              unique needs and challenges of our clients.
            </p>
            <img src={logo} alt="" className=" order-first lg:order-first hidden lg:block lg:w-[230px] lg:h-[230px] xl:w-[340px] xl:h-[340px]" />
          </div>

          <div className="md:mt-8 lg:mt-24 w-full">
            {/* <Heading left title="About Us" /> */}
            <div className="max-w-full px-0 md:px-8 mx-auto lg:px-0 grid grid-cols-1 items-start justify-center">
              <p className='font-bold md:text-xl lg:text-2xl xl:text-3xl lg:mt-0 mt-4 '>Our Mission</p>
              <p className="font-normal md:text-lg lg:text-xl xl:text-2xl  md:mt-4 lg:mt-3 xl:mt-5 leading-normal xl:leading-loose  text-justify">
                Our mission at Wumbis is to empower
                individuals and businesses by recognizing and maximizing their inherent potentials. Through
                personalized coaching, training, and resources, we unlock capabilities, foster personal and
                professional growth, and ensure job readiness and career advancement. By creating a positive
                impact on individuals, businesses, and communities, we contribute to economic growth, social
                development, and overall well-being.
              </p>
            </div>
          </div>
        </Container>
        <div className="max-w-full md:px-0 mx-auto lg:px-0 flex items-start justify-center gap-10">
          <OurTeam />
        </div>
      </div>
      <Footer />
    </div>

  )
}

export default AboutusPage
