// redux/actions/brand.js
import axios from "axios";
import { server } from "../../server";

// Create brand
export const createBrand = (name, description) => async (dispatch) => {
    try {
        dispatch({ type: "brandCreateRequest" });
        const { data } = await axios.post(`${server}/brand/create-brand`, { name, description });
        dispatch({ type: "brandCreateSuccess", payload: data.brand });
    } catch (error) {
        dispatch({ type: "brandCreateFail", payload: error.response.data.message });
    }
};

// Get all brands
export const getAllBrands = () => async (dispatch) => {
    try {
        dispatch({ type: "getAllBrandsRequest" });
        const { data } = await axios.get(`${server}/brand/get-all-brands`);
        dispatch({ type: "getAllBrandsSuccess", payload: data.brands });
    } catch (error) {
        dispatch({ type: "getAllBrandsFail", payload: error.response.data.message });
    }
};


// Update brand
export const updateBrand = (id, name, description) => async (dispatch) => {
    try {
        dispatch({ type: "brandUpdateRequest" });
        const { data } = await axios.put(`${server}/brand/update-brand/${id}`, { name, description });
        dispatch({ type: "brandUpdateSuccess", payload: data.brand });
    } catch (error) {
        dispatch({ type: "brandUpdateFail", payload: error.response.data.message });
    }
};

// Delete brand
export const deleteBrand = (id) => async (dispatch) => {
    try {
        dispatch({ type: "brandDeleteRequest" });
        await axios.delete(`${server}/brand/delete-brand/${id}`);
        dispatch({ type: "brandDeleteSuccess", payload: id });
    } catch (error) {
        dispatch({ type: "brandDeleteFail", payload: error.response.data.message });
    }
};
