import React from 'react';

const CourseBanner = ({ imageUrl, children }) => {
    return (
        <div className="relative bg-cover bg-center h-28 md:h-40 lg:h-60 xl:h-96 w-full mx-auto p-0" style={{ backgroundImage: `url(${imageUrl})` }}>
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="absolute inset-0 flex items-center justify-center text-white text-center">
                <div className="mx-auto max-w-6xl p-4 text-xl md:text-4xl lg:text-5xl xl:text-6xl font-mono">{children}</div>
            </div>
        </div>
    );
}

export default CourseBanner
