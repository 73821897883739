import React from "react";
import { AiOutlineFolderAdd } from "react-icons/ai";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { CiSettings } from "react-icons/ci";
import { Link } from "react-router-dom";

const DashboardSideBar = ({ active }) => {
  return (
    <div className="relative w-full h-[88vh] bg-deepblue shadow-sm left-0 z-10 pt-8">
      {/* single item */}
      <div className="flex items-center w-full px-4 py-3">
        <Link to="/dashboard" className="flex items-center w-full gap-4">
          <RxDashboard
            size={25}
            color={`${active === 1 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${active === 1 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
              }`}
          >
            Dashboard
          </h5>
        </Link>
      </div>

      <div className="flex items-center w-full px-4 py-3">
        <Link to="/dashboard-orders" className="flex items-center w-full gap-4">
          <FiShoppingBag
            size={25}
            color={`${active === 2 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${active === 2 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
              }`}
          >
            All Applied Courses
          </h5>
        </Link>
      </div>

      <div className="flex items-center w-full px-4 py-3">
        <Link to="/dashboard-products" className="flex items-center w-full gap-4">
          <FiPackage size={25}
            color={`${active === 3 ? "#fff" : "#fff"}`} />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] 
              ${active === 3 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
              }`}
          >
            All Available Courses
          </h5>
        </Link>
      </div>

      <div className="flex items-center w-full px-4 py-3">
        <Link
          to="/dashboard-create-product"
          className="flex items-center w-full gap-4"
        >
          <AiOutlineFolderAdd
            size={25}
            color={`${active === 4 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] 
              ${active === 4 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
              }`}
          >
            Add Course
          </h5>
        </Link>
      </div>


      {/* create category sectioin goes here */}
      {/* <div className="flex items-center w-full px-4 py-3">
        <Link to="/dashboard-create-category"
          className="flex items-center w-full gap-4"
        >
          <VscNewFile
            size={25}
            color={`${active === 13 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] 
              ${active === 13 ? "text-white font-semibold bg-green1 md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
              }`}
          >
            Create Category
          </h5>
        </Link>
      </div> */}

      <div className="flex items-center w-full px-4 py-3">
        <Link
          to="/dashboard-create-category"
          className="flex items-center w-full gap-4"
        >
          <AiOutlineFolderAdd
            size={25}
            color={`${active === 13 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] 
              ${active === 13 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
              }`}
          >
            Add Course Category
          </h5>
        </Link>
      </div>

      {/*  create category section ends here*/}

      {/* create brand section goes here  */}
      <div className="flex items-center w-full px-4 py-3">
        <Link to="/dashboard-create-brand" className="flex items-center w-full gap-4">
          <MdOutlineLocalOffer
            size={25}
            color={`${active === 12 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] 
              ${active === 12 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
              }`}
          >
            Add Sub-Category
          </h5>
        </Link>
      </div>

      {/* create brand section ends here */}



      <div className="absolute bottom-3 flex items-center w-full px-4 py-3">
        <Link to="/settings" className="flex items-center justify-center md:justify-start w-full gap-4 p-0 md:p-2 bg-lightenblue rounded-xl">
          <CiSettings
            size={25}
            color={`${active === 11 ? "deepblue" : "deepblue"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-semibold 
              ${active === 11 ? "text-deepblue" : "text-[deepblue]"
              }`}
          >
            Settings
          </h5>
        </Link>
      </div>
    </div>
  );
};

export default DashboardSideBar;
