import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Layout/Loader";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

const AllOrders = () => {
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [seller._id, dispatch]);

  const totalPages = Math.ceil((orders?.length || 0) / itemsPerPage);
  const currentOrders = orders?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  ) || [];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  function formatDate(dateString) {
    // Parse the ISO date string to a Date object
    const date = new Date(dateString);

    // Define options for formatting the date
    const options = {
      weekday: 'long', // e.g., Monday
      year: 'numeric', // e.g., 2024
      month: 'long', // e.g., September
      day: 'numeric', // e.g., 2
      hour: 'numeric', // e.g., 3
      minute: 'numeric', // e.g., 26
      hour12: true, // 12-hour format with AM/PM
    };

    // Format the date using Intl.DateTimeFormat
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }


  const columns = [
    { field: "id", headerName: "Application ID" },
    { field: "userName", headerName: "Registration Name" },
    { field: "RegistrationEmail", headerName: "Registration Email" },
    { field: "RegistrationNumber", headerName: "Mobile Number" },
    { field: "total", headerName: "Course Fee" },
    { field: "paid", headerName: "Paid At" },
  ];

  const rows = currentOrders.map((item) => ({
    id: item._id,
    userName: item.user?.name,
    RegistrationEmail: item.user?.email,
    RegistrationNumber: item.user?.phoneNumber,
    total: "$" + item.totalPrice,
    paid: formatDate(item.paidAt),
  }));

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full p-2 md:p-6 bg-lightenblue md:h-[88vh] overflow-hidden">
          <div className="bg-white relative w-full p-4 h-[85vh] max-h-[85vh] md:h-[82vh] md:max-h-[82vh] rounded-xl mt-0 overflow-x-scroll md:overflow-x-hidden">
            <div className="flex items-center justify-start w-full max-w-[100%] lg:max-w-[100%] 2xl:max-w-[100%] z-10 px-2 md:px-4 py-2 rounded-lg bg-darkblue">
              <h3 className="flex items-start justify-start gap-2 text-lg font-semibold text-white sm:text-xl lg:text-2xl font-Roboto">
                <span className="hidden sm:block"> STAFF DASHBOARD </span>  <span className="leading-tight"> [User Registered Courses] </span>
              </h3>
            </div>
            <div className="w-full pt-1 bg-white mt-2 max-h-[70vh] overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-300">
                <thead>
                  <tr className="text-left bg-gray-200">
                    {columns.map((column) => (
                      <th
                        key={column.field}
                        className="px-2 py-2 text-xs font-semibold text-gray-700 sm:px-4 sm:text-sm md:text-base"
                      >
                        {column.headerName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* {rows.map((row) => (
                    <tr key={row.id} className="border-b">
                      {columns.map((column) => (
                        <td
                          key={column.field}
                          className="px-2 py-2 text-xs text-gray-600 sm:px-4 sm:text-sm md:text-base"
                        >
                          {row[column.field]}
                        </td>
                      ))}
                    </tr>
                  ))} */}
                  {rows.map((row) => (
                    <tr key={row.id} className="border-b">
                      {columns.map((column) => (
                        <td key={column.field} className="px-2 py-2 text-xs text-gray-600 sm:px-4 sm:text-sm md:text-base">
                          {row[column.field]}
                        </td>
                      ))}
                      <td className="px-4 py-2">
                        <Link to={`/order/${row.id}`}>
                          <button className="text-blue-500 hover:underline">
                            <AiOutlineArrowRight size={20} />
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination Controls */}
              <div className="flex justify-center pb-10 mt-4">
                <button
                  className={`px-3 py-1 mx-1 text-xs sm:text-sm font-semibold text-white bg-blue-500 rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index}
                    className={`px-3 py-1 mx-1 text-xs sm:text-sm font-semibold ${currentPage === index + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700"
                      } rounded`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  className={`px-3 py-1 mx-1 text-xs sm:text-sm font-semibold text-white bg-blue-500 rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllOrders;
