import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import ProductCard from "../Route/ProductCard/ProductCard";
import Ratings from "../Products/Ratings";


const ShopProfileData = ({ isOwner }) => {
  const { products } = useSelector((state) => state.products);
  // const { events } = useSelector((state) => state.events);
  const { id } = useParams();
  const dispatch = useDispatch();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setItemsPerPage(2); // Set itemsPerPage to 2 for small screens
      } else {
        setItemsPerPage(8); // Default value for larger screens
      }
    };

    handleResize(); // Call once initially
    window.addEventListener("resize", handleResize); // Add resize listener

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the listener
    };
  }, []);

  useEffect(() => {
    dispatch(getAllProductsShop(id));
  }, [id, dispatch]);

  const [active, setActive] = useState(1);

  const allReviews =
    products && products.map((product) => product.reviews).flat();

  const totalPages = Math.ceil((products?.length || 0) / itemsPerPage);

  const currentProducts = products?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  ) || [];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  return (
    <div className="w-full h-[88vh] md:h-full bg-lightenblue py-2 px-4 overflow-hidden">
      <div className="h-full max-h-full bg-white p-3 rounded-2xl overflow-hidden">
        <div className="w-full h-full items-center justify-between sticky top-0 left-0 z-10">
          <div className="w-full flex flex-wrap pl-2 pt-2 md:py-0 items-center justify-between gap-0 md:gap-5 bg-deepblue">

            <div className="flex gap-3">
              <div className="flex items-center bg-lightenblue px-4 py-1 justify-center" onClick={() => setActive(1)}>
                <h5
                  className={`font-[600] text-[12px] ${active === 1 ? "text-white" : "text-[#fff]"
                    } cursor-pointer items-center justify-center`}
                >
                  Uploaded Courses
                </h5>
              </div>

              {/* <div className="flex items-center bg-lightenblue px-4 py-1 justify-center" onClick={() => setActive(2)}>
                <h5
                  className={`font-[600] text-[12px] ${active === 2 ? "text-black" : "text-[#fff]"
                    } cursor-pointer items-center justify-center`}
                >
                  Running Events
                </h5>
              </div> */}

              <div className="flex items-center bg-lightenblue px-4 py-1 justify-center" onClick={() => setActive(3)}>
                <h5
                  className={`font-[600] text-[12px] ${active === 3 ? "black" : "text-[#fff]"
                    } cursor-pointer flex items-center justify-center`}
                >
                  Course Reviews
                </h5>
              </div>
            </div>

            <div className=" flex items-center justify-center mr-4 gap-3 md:gap-4" onClick={() => setActive(4)}>
              <Link to="/"
                className={`font-[600] text-[12px] ${active === 4 ? "black" : "text-[#fff]"
                  } cursor-pointer flex items-center justify-center bg-red-700 px-[14px] md:px-4 py-1`}
              >
                Back to home page
              </Link>
              {isOwner && (
                <div>
                  <Link to="/dashboard">
                    <div className=" w-[124px] md:w-[150px] bg-black h-[28px] my-3 flex items-center justify-center cursor-pointer" >
                      <span className="text-[#fff] text-sm">Go to dashboard</span>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
  
          {active === 1 && (
            <div className="flex flex-col flex-wrap border-0">
              <div className="flex flex-wrap border-0 justify-between">
                {currentProducts &&
                  currentProducts.map((i, index) => (
                    <ProductCard data={i} key={index} isShop={true} />
                  ))}

              </div>
              {products && products.length === 0 && (
                <h5 className="w-full text-center py-5 text-[28px] font-bold">
                  No Course Available for this Staff!
                </h5>
              )}

              {/* Pagination Controls */}
              <div className="flex absolute mt-3 bottom-0 left-0 right-0 justify-center">
                <button
                  className={`px-3 py-1 mx-1 text-xs sm:text-sm font-semibold text-white bg-blue-500 rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index}
                    className={`px-3 py-1 mx-1 text-xs sm:text-sm font-semibold ${currentPage === index + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700"
                      } rounded`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  className={`px-3 py-1 mx-1 text-xs sm:text-sm font-semibold text-white bg-blue-500 rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {/* {active === 2 && (
            <div className="w-full h-full max-h-full">
              <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-3 lg:gap-[25px] xl:grid-cols-4 xl:gap-[20px] mb-12 border-0">
                {events &&
                  events.map((i, index) => (
                    <ProductCard
                      data={i}
                      key={index}
                      isShop={true}
                      isEvent={true}
                    />
                  ))}
              </div>
              {events && events.length === 0 && (
                <h5 className="w-full text-center py-5 text-[28px] font-bold">
                  No Events Available for this Staff!
                </h5>
              )}
            </div>
          )} */}

          {active === 3 && (
            <div className="w-full h-full max-h-full">
              {allReviews &&
                allReviews.map((item, index) => (
                  <div className="w-full flex my-4">
                    <img
                      src={`${item.user.avatar?.url}`}
                      className="w-[50px] h-[50px] rounded-full"
                      alt=""
                    />
                    <div className="pl-2">
                      <div className="flex w-full items-center">
                        <h1 className="font-[600] pr-2">{item.user.name}</h1>
                        <Ratings rating={item.rating} />
                      </div>
                      <p className="font-[400] text-[#000000a7]">{item?.comment}</p>
                      <p className="text-[#000000a7] text-[14px]">{"2days ago"}</p>
                    </div>
                  </div>
                ))}
              {allReviews && allReviews.length === 0 && (
                <h5 className="w-full text-center py-5 text-[28px] font-bold">
                  No Reviews have for this staff!
                </h5>
              )}
            </div>
          )}
        </div>
      </div>

    </div>
  );
};

export default ShopProfileData;
