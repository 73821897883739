import React, { useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { useState } from "react";

const AllProducts = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${server}/product/admin-all-products`, { withCredentials: true }).then((res) => {
      setData(res.data.products);
    })
  }, []);

  const columns = [
    { field: "id", headerName: "Course Id", },
    {
      field: "name",
      headerName: "Course Name",
    },
    {
      field: "originalPrice",
      headerName: "Course Fee",
    },
    {
      field: "Preview",
      headerName: "Preview",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/product/${params.id}`}>
              <button className="px-4 py-2 font-bold ">
                <AiOutlineEye size={20} />
              </button>
            </Link>
          </>
        );
      },
    },
  ];

  const rows = data &&
    data?.map((item) => ({
      id: item._id,
      name: item.name,
      originalPrice: "$" + item.originalPrice,
      Stock: item.stock,
      sold: item?.sold_out,
    }));

  return (
    <>
      <div className="w-full p-2 md:p-6 bg-deepblue md:h-[88vh] overflow-hidden">
        <div className="bg-white relative w-full p-4 h-[85vh] max-h-[85vh] md:h-[82vh] md:max-h-[82vh] rounded-xl mt-0 overflow-x-scroll md:overflow-x-hidden">
          <div className="flex items-center justify-start w-full max-w-[100%] lg:max-w-[100%] 2xl:max-w-[100%] z-10 px-2 md:px-4 py-2 rounded-lg bg-darkblue">
            <h3 className="flex items-start justify-start gap-2 text-lg font-semibold text-white sm:text-xl lg:text-2xl font-Roboto">
              <span className="hidden sm:block"> ADMIN </span>{" "}
              <span className="leading-tight"> [ALL STAFF UPLOADED COURSES] </span>
            </h3>
          </div>
          <div className="w-full pt-1 bg-white mt-2 max-h-[70vh] overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="text-left bg-deepblue">
                  {columns?.map((column) => (
                    <th
                      key={column.field}
                      className="px-2 py-2 text-xs font-semibold text-white sm:px-4 sm:text-sm md:text-base"
                    >
                      {column.headerName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows?.map((row) => (
                  <tr key={row.id} className="border-b">
                    {columns?.map((column) => (
                      <td
                        key={column.field}
                        className="px-2 py-2 text-xs text-gray-600 sm:px-4 sm:text-sm md:text-base"
                      >
                        {column.field === "Preview" ? (
                          // <Link to={`/shop/preview/${row.id}`}>
                          //   <Button>
                          //     <AiOutlineEye size={20} />
                          //   </Button>
                          // </Link>
                          <Link to={`/product/${row.id}`}>
                            <button className="px-4 py-2 font-bold">
                              <AiOutlineEye size={20} />
                            </button>
                          </Link>
                        ) : (
                          row[column.field]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* {
        data ? (
          <Loader />
        ) : (
            <div className="w-full h-full p-6 bg-green1 rounded-2xl ">
              <div className="w-full h-full p-4 bg-white rounded-xl">
                <div className="flex items-center justify-start w-full px-4 py-2 rounded-lg bg-green1">
                  <h3 className="text-[22px] font-Roboto text-white">All Products</h3>
                </div>
                <div className="w-full pt-1 mt-4 bg-white">
                  <DataGrid
                    rows={row}
                    columns={columns}
                    pageSize={10}
                    disableSelectionOnClick
                    autoHeight
                  />
                </div>
              </div>
            </div>
        )
      } */}

    </>
  );
};

export default AllProducts;
