import axios from "axios";
import { server } from "../../server";

// create banner
export const createBanner = (bannerData) => async (dispatch) => {
    try {
        dispatch({
            type: "bannerCreateRequest",
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const { data } = await axios.post(`${server}/banner/create-banner`, bannerData, config);

        dispatch({
            type: "bannerCreateSuccess",
            payload: data.banner,
        });
    } catch (error) {
        dispatch({
            type: "bannerCreateFail",
            payload: error.response.data.message,
        });
    }
};

// get all banners
export const getAllBanners = () => async (dispatch) => {
    try {
        dispatch({
            type: "getAllBannerRequest",
        });

        const { data } = await axios.get(`${server}/banner/get-all-banners`);

        dispatch({
            type: "getAllBannerSuccess",
            payload: data.banners,
        });
    } catch (error) {
        dispatch({
            type: "getAllBannerFailed",
            payload: error.response.data.message,
        });
    }
};

// delete banner
export const deleteBanner = (id) => async (dispatch) => {
    try {
        dispatch({
            type: "deleteBannerRequest",
        });

        const { data } = await axios.delete(`${server}/banner/delete-banner/${id}`, {
            withCredentials: true,
        });

        dispatch({
            type: "deleteBannerSuccess",
            payload: data.message,
        });
    } catch (error) {
        dispatch({
            type: "deleteBannerFailed",
            payload: error.response.data.message,
        });
    }
};
