import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteProduct, getAllProductsShop } from "../../redux/actions/product";
import { TiEdit } from "react-icons/ti";
import Loader from "../Layout/Loader";
import { toast } from "react-toastify";
import { getAllCategories } from "../../redux/actions/category";
import { getAllBrands } from "../../redux/actions/brand";

const AllProducts = () => {
  const { products = [], isLoading } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const { categories = [] } = useSelector((state) => state.categories);
  const { brands = [] } = useSelector((state) => state.brands);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (seller && seller._id) {
      dispatch(getAllProductsShop(seller._id));
      dispatch(getAllCategories());
      dispatch(getAllBrands());
    }
  }, [dispatch, seller]);

  const handleDelete = (id) => {
    dispatch(deleteProduct(id));
    setIsConfirmationOpen(false);
    toast.success("Product Deleted Successfully");
    window.location.reload();
  };

  const openConfirmationDialog = (id) => {
    setIsConfirmationOpen(true);
    setIdToDelete(id);
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationOpen(false);
    setIdToDelete(null);
  };

  const getCategoryName = (categoryId) => {
    const category = categories.find(cat => cat._id === categoryId);
    return category ? category.name : "";
  };

  const getBrandName = (id) => {
    const brand = brands.find(brand => brand._id === id);
    return brand ? brand.name : "";
  };

  const columns = [
    { field: "id", headerName: "Course Id", minWidth: 150, flex: 0.7 },
    { field: "name", headerName: "Name", minWidth: 180, flex: 1.4 },
    { field: "price", headerName: "Price", minWidth: 60, flex: 0.7 },
    { field: "category", headerName: "Course Category", minWidth: 100, flex: 0.7 },
    { field: "brands", headerName: "Sub-Category", minWidth: 100, flex: 0.7 },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 30,
      headerName: "Preview",
      sortable: false,
      renderCell: (params) => (
        <Link to={`/product/${params.id}`}>
          <button className="text-blue-500 hover:text-blue-700">
            <AiOutlineEye size={20} />
          </button>
        </Link>
      ),
    },
    {
      field: "Edit",
      flex: 0.8,
      minWidth: 30,
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => (
        <Link to={`/edit-product/${params?.id}`}>
          <button className="text-green-500 hover:text-green-700">
            <TiEdit size={20} />
          </button>
        </Link>
      ),
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 30,
      headerName: "Delete",
      sortable: false,
      renderCell: (params) => (
        <button
          onClick={() => openConfirmationDialog(params?.id)}
          className="text-red-500 hover:text-red-700"
        >
          <AiOutlineDelete size={20} />
        </button>
      ),
    },
  ];

  const rows = products?.map((item) => ({
    id: item._id,
    name: item.name,
    category: getCategoryName(item.category),
    brands: getBrandName(item.brands),
    price: "$" + item.originalPrice,
  }));

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full p-2 md:p-6 bg-lightenblue md:h-[88vh] overflow-hidden">
            <div className="bg-white relative w-full p-4 h-[85vh] max-h-[85vh] md:h-[82vh] md:max-h-[82vh] rounded-xl mt-0 md:overflow-hidden">
            <div className="flex items-center justify-start w-full max-w-[100%] lg:max-w-[100%] 2xl:max-w-[100%] z-10 px-2 md:px-4 py-2 rounded-lg bg-darkblue">
              <h3 className="flex gap-2 items-start justify-start text-lg sm:text-xl lg:text-2xl font-semibold font-Roboto text-white">
                <span className="hidden sm:block"> STAFF DASHBOARD </span>  <span className="leading-tight"> [All Available Courses] </span>
              </h3>
            </div>
            <div className="w-full md:w-full pt-1 min-h-[70vh]  lg:max-h-[79vh] bg-white mt-4 overflow-y-scroll">
                <table className="flex-col md:max-w-full md:w-full divide-y divide-gray-200">
                <thead className="">
                  <tr className="bg-gray-200 text-left">
                    {columns.map((column) => (
                      <th key={column.field} className="py-2 px-4 font-semibold text-gray-700">
                        {column.headerName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {rows.map((row) => (
                    <tr key={row.id}>
                      {columns.map((col) => (
                        <td
                          key={col.field}
                          className="px-4 py-4 whitespace-nowrap"
                        >
                          {col.renderCell
                            ? col.renderCell({ id: row.id })
                            : row[col.field.toLowerCase()]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {isConfirmationOpen && (
              <div className="fixed inset-0 z-10">
                <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                  <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                  >
                    <div className="absolute inset-0 bg-gray-700 opacity-75"></div>
                  </div>
                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 bg-deepblue">
                      <div className="sm:flex sm:items-start">
                        <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                          <AiOutlineDelete
                            size={20}
                            className="font-bold text-red-900"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <h3 className="text-lg font-medium leading-6 text-white">
                            Delete Confirmation
                          </h3>
                          <div className="mt-2">
                            <p className="text-sm text-white">
                              Are you sure you want to delete this course?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="items-center justify-center gap-8 px-4 py-3 bg-white sm:px-6 sm:flex ">
                      <button
                        onClick={() => handleDelete(idToDelete)}
                        className="px-4 py-1 font-semibold text-white bg-red-600 rounded-lg"
                      >
                        Delete
                      </button>
                      <button
                        onClick={closeConfirmationDialog}
                        className="px-4 py-1 font-semibold text-white bg-deepblue rounded-lg"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AllProducts;
