import React from 'react'
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader'
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import CreateBrand from '../../components/Shop/CreateBrand';


const ShopCreateBrand = () => {
  return (
      <div>
          <DashboardHeader />
          <div className="flex w-full">
              <div className="w-[80px] 800px:w-[330px]">
                  <DashboardSideBar active={12} />
              </div>
              <div className="max-w-[84%] md:w-full justify-center flex">
                  <CreateBrand />
              </div>
          </div>
      </div>
  )
}

export default ShopCreateBrand
