import React from "react";
import { AiOutlineLogin } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { MdOutlineAdminPanelSettings} from "react-icons/md";
import { TbAddressBook } from "react-icons/tb";
import { RxPerson } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ProfileSidebar = ({ setActive, active }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  
  const logoutHandler = () => {
    axios
      .get(`${server}/user/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
        navigate("/login");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  return (
    <div className="w-full bg-deepblue shadow-sm p-4 pt-8 h-[74vh] max-h-[74vh]">
      <div
        className="flex items-center w-full mb-8 cursor-pointer gap-4"
        onClick={() => setActive(1)}
      >
        <RxPerson size={20} color={`${active === 1 ? "#fff" : "#fff"}`} />
        <span
          className={`pl-3 font-semibold ${active === 1 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"} 800px:block hidden`}
        >
          Profile
        </span>
      </div>

      <div
        className="flex items-center w-full mb-8 cursor-pointer gap-4"
        onClick={() => setActive(2)}
      >
        <HiOutlineShoppingBag size={20} color={`${active === 2 ? "#fff" : "#fff"}`} />
        <span
          className={`pl-3 font-semibold ${
            active === 2 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
          } 800px:block hidden`}
        >
          Applied Courses
        </span>
      </div>
     

      <div
        className="flex items-center w-full mb-8 cursor-pointer gap-4"
        onClick={() => setActive(6)}
      >
        <RiLockPasswordLine size={20} color={`${active === 6 ? "#fff" : "#fff"}`} />
        <span
          className={`pl-3 font-semibold ${
            active === 6 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
          } 800px:block hidden`}
        >
          Change Password
        </span>
      </div>

      <div
        className="flex items-center w-full mb-8 cursor-pointer gap-4"
        onClick={() => setActive(7)}
      >
        <TbAddressBook size={20} color={`${active === 7 ? "#fff" : "#fff"}`} />
        <span
          className={`pl-3 font-semibold ${
            active === 7 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
          } 800px:block hidden`}
        >
          Address
        </span>
      </div>

      {user && user?.role === "Admin" && (
        <Link to="/admin/dashboard">
          <div
            className="flex items-center w-full mb-8 cursor-pointer gap-4"
            onClick={() => setActive(8)}
          >
            <MdOutlineAdminPanelSettings size={20} color={`${active === 8 ? "#fff" : "#fff"}`}
            />
            <span
              className={`pl-3 font-bold ${
                active === 8 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
              } 800px:block hidden`}
            >
              Admin Dashboard
            </span>
          </div>
        </Link>
      )}
      <div
        className="flex items-center w-full mb-0 cursor-pointer single_item gap-4"
        onClick={logoutHandler}
      >
        <AiOutlineLogin size={20} color={`${active === 8 ? "#fff" : "#fff"}`} />
        <span
          className={`ml-3 font-bold text-white bg-red-800 rounded-lg px-2 py-1 ${active === 8 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
              } 800px:block hidden`}
        >
          Log out
        </span>
      </div>
    </div>
  );
};

export default ProfileSidebar;
