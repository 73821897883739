import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { blockUser, changeUserRole, getAllUsers, unblockUser } from "../../redux/actions/user";
import { AiOutlineDelete } from "react-icons/ai";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

const AllUsers = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const handleDelete = async (id) => {
    await axios
      .delete(`${server}/user/delete-user/${id}`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
      });

    dispatch(getAllUsers());
  };

  const handleBlock = async (id) => {
    await dispatch(blockUser(id));
    dispatch(getAllUsers());
  };

  const handleUnblock = async (id) => {
    await dispatch(unblockUser(id));
    dispatch(getAllUsers());
  };

  const handleRoleChange = async (id, role) => {
    const newRole = role === "user" ? "Admin" : "user";
    await dispatch(changeUserRole(id, newRole));
    dispatch(getAllUsers());
  };


  const columns = [
    { field: "name", headerName: "User Fullname" },
    { field: "email", headerName: "Email", type: "text" },
    { field: "phoneNumber", headerName: "Mobile Number", type: "text" },
    {
      field: "addresses",
      headerName: "Address",
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-line" }}>
          {params.value.split(" | ")?.map((address, index) => (
            <p key={index}>{address}</p>
          ))}
        </div>
      ),
    },
    { field: "role", headerName: "User Role", type: "text" },
    { field: "joinedAt", headerName: "Registered Date", type: "text" },
    {
      field: "isBlocked",
      headerName: "Status",
      renderCell: (params) => {
        return params.value ? (
          <span className="text-red-500">Blocked</span>
        ) : (
          <span className="text-green-500">Active</span>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => {
        return params.row.isBlocked ? (
          <button onClick={() => handleUnblock(params.id)} className="px-4 py-2 font-bold">
            UNBLOCK
          </button>
        ) : (
           <button onClick={() => handleBlock(params.id)} className="px-4 py-2 font-bold ">
            Block
          </button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete User",
      renderCell: (params) => (
        // <Button onClick={() => setUserId(params.id) || setOpen(true)}>
        //   <AiOutlineDelete size={20} />
        // </Button>
         <button onClick={() => setUserId(params.id) || setOpen(true)} className="px-4 py-2 font-bold rounded text-b">
           <AiOutlineDelete size={20} />
          </button>
      ),
    },
    {
      field: "role",
      headerName: "User Role",
      type: "text",
      renderCell: (params) => (
        <div>
          {/* <span>{params.value}</span> */}
          <button className="px-3 py-1 text-white rounded-m bg-deepblue"
            onClick={() => handleRoleChange(params.id, params.value)}
          >
            Change
          </button>
        </div>
      ),
    }
  ];

  const rows = users?.map((item) => ({
    id: item._id,
    name: item.name,
    email: item.email,
    phoneNumber: item.phoneNumber,
    addresses: item.addresses
      ?.map((address) =>
        `${address.address1}, ${address.address2 ? address.address2 + ", " : ""}${address.city}, ${address.zipCode}, ${address.country}`
      )
      .join(" | "),
    role: item.role,
    joinedAt: item.createdAt.slice(0, 10),
    isBlocked: item.isBlocked,
  }));

  return (
    <div className="w-full p-2 md:p-6 bg-deepblue md:h-[88vh] overflow-hidden">
      <div className="bg-white relative w-full p-4 h-[85vh] max-h-[85vh] md:h-[82vh] md:max-h-[82vh] rounded-xl mt-0 overflow-x-scroll md:overflow-x-hidden">
        <div className="flex items-center justify-start w-full max-w-[100%] lg:max-w-[100%] 2xl:max-w-[100%] z-10 px-2 md:px-4 py-2 rounded-lg bg-darkblue">
          <h3 className="flex items-start justify-start gap-2 text-lg font-semibold text-white sm:text-xl lg:text-2xl font-Roboto">
            <span className="hidden sm:block"> ADMIN </span>{" "}
            <span className="leading-tight"> [REGISTERED USER ACCOUNT LIST] </span>
          </h3>
        </div>
        <div className="w-full pt-1 bg-white mt-2 max-h-[70vh] overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr className="text-left bg-gray-200">
                {columns.map((column) => (
                  <th
                    key={column.field}
                    className="px-2 py-2 text-xs font-semibold text-gray-700 sm:px-4 sm:text-sm md:text-base"
                  >
                    {column.headerName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows?.map((row) => (
                <tr key={row.id} className="border-b">
                  {columns.map((column) => (
                    <td
                      key={column.field}
                      className="px-2 py-2 text-xs text-gray-600 sm:px-4 sm:text-sm md:text-base"
                    >
                      {column.renderCell
                        ? column.renderCell({
                          value: row[column.field],
                          id: row.id,
                          row: row,
                        })
                        : row[column.field]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000090] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[32%] min-h-[17vh] bg-darkblue rounded-lg shadow overflow-hidden">
              <div className="w-full px-4 py-2 sm:flex sm:items-start">
                <div className="py-4 sm:flex sm:items-start bg-bg-darkblue">
                  <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <AiOutlineDelete size={20} className="font-bold text-red-900" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg font-medium leading-6 text-white">Delete Confirmation</h3>
                    <div className="mt-2">
                      <p className="text-sm text-white">Are you sure you want to delete this user account?</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="items-center justify-center gap-8 px-4 py-3 bg-white sm:px-6 sm:flex">
                <div className="px-4 py-2 text-white rounded-md cursor-pointer button8 bg-darkblue" onClick={() => setOpen(false)}>
                  Cancel
                </div>
                <div className="px-4 py-2 text-white bg-red-700 rounded-md cursor-pointer button7" onClick={() => setOpen(false) || handleDelete(userId)}>
                  Confirm
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllUsers;
