import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./reducers/user";
import { sellerReducer } from "./reducers/seller";
import { productReducer } from "./reducers/product";
import { cartReducer } from "./reducers/cart";
import { wishlistReducer } from "./reducers/wishlist";
import { orderReducer } from "./reducers/order";
import { bannerReducer } from "./reducers/banner";
import { testimonyReducer } from "./reducers/testimony";
import { categoryReducer } from "./reducers/category"
import { brandReducer } from "./reducers/brand"

const Store = configureStore({
  reducer: {
    user: userReducer,
    seller: sellerReducer,
    products: productReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    order: orderReducer,
    banners: bannerReducer,
    testimonys: testimonyReducer,
    categories: categoryReducer,
    brands: brandReducer,
  },
});

export default Store;
