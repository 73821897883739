import React, { useState } from "react";
import styles from "../../styles/styles";
import { Country, State } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { removeFromCart } from "../../redux/actions/cart";

import { MdPayment } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";

const Checkout = () => {
  const { user } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);
  
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [userInfo, setUserInfo] = useState(false);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [zipCode, setZipCode] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
    navigate("/"); 
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paymentSubmit = () => {
    if (address1 === "" || address2 === "" || zipCode === null || country === "" || city === "") {
      toast.error("Please choose your registration address!")
    } else {
      const shippingAddress = {
        address1,
        address2,
        zipCode,
        country,
        city,
      };

      const orderData = {
        cart,
        totalPrice,
        subTotalPrice,
        shippingAddress,
        user,
      }

      // update local storage with the updated orders array
      localStorage.setItem("latestOrder", JSON.stringify(orderData));
      navigate("/payment");
    }
  };

  const subTotalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.originalPrice,
    0
  );

  // const totalPrice = couponCodeData
  //   ? (subTotalPrice + shipping - discountPercentenge).toFixed(2)
  //   : (subTotalPrice + shipping).toFixed(2);
  
  const totalPrice = subTotalPrice.toFixed(2);


  return (
    <div className="w-full flex flex-col items-center py-2 md:py-8">
      <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
        <div className="w-full 800px:w-[65%]">
          <ShippingInfo
            user={user}
            country={country}
            setCountry={setCountry}
            city={city}
            setCity={setCity}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            address1={address1}
            setAddress1={setAddress1}
            address2={address2}
            setAddress2={setAddress2}
            zipCode={zipCode}
            setZipCode={setZipCode}
          />
        </div>
        <div className="w-full 800px:w-[35%] 800px:mt-0 mt-8">
          <CartData
            totalPrice={totalPrice}
            subTotalPrice={subTotalPrice}
          />
        </div>
      </div>

      <div className="flex w-11/12 md:w-8/12 mx-auto items-center justify-start gap-3 px-2 md:px-4">
        {cart.map((item, index) => (
          <div
            key={index}
            className={`w-[200px] bg-deepblue py-2 md:py-3 flex items-center justify-center rounded-md cursor-pointer md:w-[250px] mt-4 md:mt-6 gap-2 md:gap-4 hover:bg-red-700 md:mr-6 Cancle-pregistration`}
            onClick={() => removeFromCartHandler(item)} // Pass the item data here
          >
            <RxCross1 className="text-white text-xl bg-red-700  hidden md:block" />
            <h5 className="text-white font-medium md:font-bold text-base md:text-xl"> Cancel Registration </h5>
          </div>
        ))}

        <div
          className={`w-[200px] bg-deepblue py-2 md:py-3 flex items-center justify-center rounded-md cursor-pointer md:w-[220px] mt-4 md:mt-6 gap-2 md:gap-4 hover:bg-deepblue md:mr-6 Cancle-pregistration`}
          onClick={paymentSubmit}
        >
          <MdPayment className="text-white text-xl hidden md:block" />
          <h5 className="text-white font-medium md:font-bold text-base md:text-xl">Go to Payment</h5>
        </div>
      </div>
      
    </div>
  );
};

const ShippingInfo = ({
  user,
  country,
  setCountry,
  city,
  setCity,
  userInfo,
  setUserInfo,
  address1,
  setAddress1,
  address2,
  setAddress2,
  zipCode,
  setZipCode,
}) => {
  return (
    <div className="w-full 800px:w-[95%] rounded-md px-2 py-4 md:px-5 md:py-5 pb-0 md:pb-8">
      <div className="bg-deepblue flex w-full text-white py-1 md:py-2 px-1 md:px-4">
        <h5 className="text-[13px] md:text-[18px] font-[500] md:font-[600]">Personal Information</h5>
      </div>
      <br />
      <div className="w-full py-2 md:px-4">
        <form>
          <div className="w-full flex pb-3">
            <div className="w-[50%]">
              <label className="block text-[13px] md:text-[18px] font-semibold md:font-semibold pb-2">Full Name</label>
              <input
                type="text"
                value={user && user.name}
                required
                className={`${styles.input} !w-[95%] border-1 border-black`}
              />
            </div>
            <div className="w-[50%]">
              <label className="block text-[13px] md:text-[18px] font-semibold md:font-semibold pb-2">Email Address</label>
              <input
                type="email"
                value={user && user.email}
                required
                className={`${styles.input} border-black`}
              />
            </div>
          </div>

          <div className="w-full flex pb-3 md:mt-3">
            <div className="w-[50%]">
              <label className="block text-[13px] md:text-[18px] font-semibold md:font-semibold pb-2">Phone Number</label>
              <input
                type="number"
                required
                value={user && user.phoneNumber}
                className={`${styles.input} !w-[95%] border-black`}
              />
            </div>
            <div className="w-[50%]">
              <label className="block text-[13px] md:text-[18px] font-semibold md:font-semibold pb-2">Zip Code</label>
              <input
                type="number"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                required
                className={`${styles.input} border-black`}
              />
            </div>
          </div>

          <div className="w-full flex md:mt-3 pb-3">
            <div className="w-[50%]">
              <label className="block text-[13px] md:text-[18px] font-semibold md:font-semibold pb-2 ">Country</label>
              <select
                className="w-[95%] border h-[40px] rounded-[5px] border-black"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option className="block pb-2" value="">
                  Choose your country
                </option>
                {Country &&
                  Country.getAllCountries().map((item) => (
                    <option key={item.isoCode} value={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="w-[50%]">
              <label className="block text-[13px] md:text-[18px] font-semibold md:font-semibold pb-2">City</label>
              <select
                className="w-[95%] border h-[40px] rounded-[5px] border-black"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              >
                <option className="block text-[13px] md:text-[18px] font-semibold md:font-semibold pb-2" value="">
                  Choose your City
                </option>
                {State &&
                  State.getStatesOfCountry(country).map((item) => (
                    <option key={item.isoCode} value={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="w-full flex pb-3 md:mt-3">
            <div className="w-[50%]">
              <label className="block text-[13px] md:text-[18px] font-semibold md:font-semibold pb-2">Address1</label>
              <input
                type="address"
                required
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                className={`${styles.input} !w-[95%] border-black`}
              />
            </div>
            <div className="w-[50%]">
              <label className="block text-[13px] md:text-[18px] font-semibold md:font-semibold pb-2">Address2</label>
              <input
                type="address"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                required
                className={`${styles.input} border-black`}
              />
            </div>
          </div>

        </form>

        <div className="flex flex-col justify-center mt-4 md:mt-2 gap-2">
          <h5
            className="text-[14px] md:w-5/12 cursor-pointer font-medium bg-deepblue text-white px-4 py-1  font-Roboto"
            onClick={() => setUserInfo(!userInfo)}
          >
            Click to choose from saved address
          </h5>
          <span className="text-black md:text-sm">
            If no available address add one from profile page
          </span>
        </div>
         
        {userInfo && (
          <div className="bg-black w-[199px] px-2 flex mt-1">
            {user &&
              user.addresses.map((item, index) => (
                <div className="w-full flex mt-1 pb-1">
                  <input
                    type="checkbox"
                    className="mr-3"
                    value={item.addressType}
                    onClick={() =>
                      setAddress1(item.address1) ||
                      setAddress2(item.address2) ||
                      setZipCode(item.zipCode) ||
                      setCountry(item.country) ||
                      setCity(item.city)
                    }
                  />
                  <h2 className="font-semibold text-white text-sm">{item.addressType}</h2>
                </div>
              ))}
          </div>
        )}
      </div>


    </div>
  );
};

const CartData = ({
  totalPrice,
  subTotalPrice,

}) => {
  return (
    <div className="w-full bg-darkblue rounded-md pt-4 px-5 mt-2 md:mt-5">
      <div className="flex justify-between border-b-2 border-lightenblue ">
        <h3 className="text-[12px] md:text-[16px] font-[600] text-white bg-deepble px-2 py-1">Application Fee:</h3>
        <h5 className="text-[12px] md:text-[16px] font-[600] px-1 py-1 text-white"> ${subTotalPrice} </h5>
      </div>
      <br />

      <br />
      <div className="flex justify-between border-b-2 border-lightenblue">
        <h3 className="text-[12px] md:text-[16px] font-[600] text-white bg-deepble px-2 py-1">Total Fee:</h3>
        <h5 className="text-[12px] md:text-[16px] font-[600] px-1 py-1 text-white"> ${totalPrice} </h5>
      </div>
      <br />
    </div>
  );
};

export default Checkout;
