import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../server";
import { AiOutlineCamera } from "react-icons/ai";
import styles from "../../styles/styles";
import axios from "axios";
// import { loadSeller } from "../../redux/actions/user";
import { toast } from "react-toastify";
import { loadSeller } from "../../redux/actions/sellers";

const ShopSettings = () => {
  const { seller } = useSelector((state) => state.seller);
  const [avatar, setAvatar] = useState();
  const [name, setName] = useState(seller && seller.name);
  const [description, setDescription] = useState(
    seller && seller.description ? seller.description : ""
  );
  const [address, setAddress] = useState(seller && seller.address);
  const [phoneNumber, setPhoneNumber] = useState(seller && seller.phoneNumber);
  const [zipCode, setZipcode] = useState(seller && seller.zipCode);

  const dispatch = useDispatch();

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(
            `${server}/shop/update-shop-avatar`,
            { avatar: reader.result },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            dispatch(loadSeller());
            toast.success("Image updated successfully!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const updateHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/shop/update-seller-info`,
        {
          name,
          address,
          zipCode,
          phoneNumber,
          description,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Staff info updated succesfully!");
        dispatch(loadSeller());
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="w-full p-2 md:p-6 bg-lightenblue h-[88vh] max-h-[88vh] md:h-[88vh] overflow-hidden">
      <div className="bg-white relative w-full p-4 h-[86vh] max-h-[86vh] md:h-[82vh] md:max-h-[82vh] rounded-xl mt-0 overflow-hidden">
        <div className="w-full flex items-center justify-center">
          <div className="relative">
            <img
              src={avatar ? avatar : `${seller.avatar?.url}`}
              alt=""
              className="w-[200px] h-[200px] rounded-full cursor-pointer"
            />
            <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[10px] right-[15px]">
              <input
                type="file"
                id="image"
                className="hidden"
                onChange={handleImage}
              />
              <label htmlFor="image">
                <AiOutlineCamera />
              </label>
            </div>
          </div>
        </div>

        {/* shop info */}
        <form
          className="flex flex-col items-center md:mt-10 max-h-[50vh] h-[50vh] md:h-[45vh]  md:max-h-[48vh] md:mb-[96px] overflow-y-scroll"
          onSubmit={updateHandler}
        >
          <div className="flex flex-wrap md:flex-nowrap w-full gap-3 md:pag-4 lg:gap-8 mt-2 pr-0 lg:pr-3">
            <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
              <div className="w-full pl-[3%]">
                <label className="block pb-2 font-semibold">Staff Name</label>
              </div>
              <input
                type="name"
                placeholder={`${seller.name}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`${styles.input2}`}
                required
              />
            </div>

            <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
              <div className="w-full pl-[3%]">
                <label className="block pb-2 font-semibold">Staff description</label>
              </div>
              <input
                type="name"
                placeholder={`${seller?.description
                  ? seller.description
                  : "Enter your shop description"
                  }`}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={`${styles.input2}`}
              />
            </div>
          </div>

          <div className="flex flex-wrap md:flex-nowrap w-full gap-3 md:pag-4 lg:gap-8 mt-2 pr-0 lg:pr-3">
            <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
              <div className="w-full pl-[3%]">
                <label className="block pb-2 font-semibold">Staff Address</label>
              </div>
              <input
                type="name"
                placeholder={seller?.address}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className={`${styles.input2}`}
                required
              />
            </div>

            <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
              <div className="w-full pl-[3%]">
                <label className="block pb-2 font-semibold">Staff Phone Number</label>
              </div>
              <input
                type="number"
                placeholder={seller?.phoneNumber}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className={`${styles.input2}`}
                required
              />
            </div>
          </div>

          <div className="flex flex-wrap md:flex-nowrap w-full gap-3 md:pag-4 lg:gap-8 mt-2 pr-0 lg:pr-3">
            <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
              <div className="w-full pl-[3%]">
                <label className="block pb-2 font-semibold">Staff Adress Zip Code</label>
              </div>
              <input
                type="number"
                placeholder={seller?.zipCode}
                value={zipCode}
                onChange={(e) => setZipcode(e.target.value)}
                className={`${styles.input2}`}
                required
              />
            </div>

            <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
              <div className="w-full pl-[3%]">
                <label className="block pb-0 font-semibold">Confirm Staff Information</label>
              </div>
                <input
                  type="submit"
                  value="Update Profile"
                className={`${styles.input2} text-white mt-5 cursor-pointer bg-deepblue py-2 font-semibold border-0 hover:bg-lightenblue`}
                  required
                  readOnly
                />
              </div>
            </div> 
        </form>
      </div>
    </div>
  );
};

export default ShopSettings;
