import React, { useEffect } from 'react'
import Container from '../components/Container'
import { Link } from 'react-router-dom'
import Meta from '../components/Meta';
import banner from '../images/banner.png';
import CourseBanner from '../components/CourseBanner';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';


const ApplicationForm = () => {

    useEffect(() => { });
    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <>
            <Header />
            <Meta title="Application Form" />

            <CourseBanner imageUrl={banner}>
                <h1 className="font-bold">REGISTRATION AND PAYMENT</h1>
            </CourseBanner>
            <Container>
                <div className="mt-10 mb-16 gird grid-cols md:flex md:flex-grow w-full bg-white">
                    <div className="hidden sm:block md:flex flex-col w-full md:w-5/12 lg:w-4/12 xl:w-3/12 gap-6 p-2 bg-transparent asideDiv">
                        {/* Payment method section goes here */}
                        <div className="sticky top-20 flex flex-col w-full p-0 overflow-hidden rounded-lg shopbycategoryDiv bg-deepblue">
                            <div className='items-center w-full px-4 py-2 text-white bg-transparent border-b-2 border-gray-300 shopbycategoryHeading'>
                                <h4 className="font-bold text-xl"> Payment Process </h4>
                            </div>
                            <div className='shopbycategoryList'>
                                <ul className='pb-4 text-decoration-0'>
                                    <li> <a href="#aboutyou" className="mt-2 font-semibold text-black"> Info about you </a> </li>
                                    <li> <a href="#payment" className="mt-2 font-semibold text-black"> Make Payment </a> </li>
                                </ul>
                                    <Link to="/course" className="connectAdvisorButton rounded-lg" target="_blank">
                                        Back to Courses
                                    </Link>
                            </div>
                        </div>
                        {/*  payment method section ends here */}
                    </div>

                    <div className='grid grid-cols-1 w-full bg-white'>

                        {/* Section form personal details goes here */}
                        {/* this should be copied to each input field
                       <input onChange = {(e) => setName(e.target.value)} value = {name}></input>
                    */}
                        <form onSubmit={handleSubmit}>

                            <div className="w-12/12 p-2 overflow-hidden bg-white rounded-md mainDiv md:px-6 md:py-4">

                                <div className="flex flex-col w-full mt-4 lg:flex-row xl:flex-row md:mt-0 md:gap-x-0 lg:gap-x-0 xl:gap-x-4 md:pb-10">

                                    <div className="flex mb-0 w-full md:w-full lg:w-3/12 xl:w-3/12 md:mb-0 ">
                                        <div className="flex flex-col lg:flex-row md:flex-row gap-x-8 gap-y-4">
                                            <div className="mb-0">
                                                <p className="h4">
                                                    PERSONAL DETAILS
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="aboutyou" className="w-full md:w-full lg:w-9/12 xl:w-9/12 flex flex-col gap-y-4 mt-4 md:mt-4 lg:mt-0 xl:mt-0 ">
                                        <div className="flex flex-col lg:flex-row md:flex-row gap-x-8 gap-y-4 lg:mt-2 xl:mt-0">
                                            <div className="w-full">
                                                <p className="mb-4 text-base font-medium leading-none text-black">
                                                    First name
                                                </p>
                                                <input
                                                    type="text"
                                                    placeholder='First Name'
                                                    className="h-12 border border-gray-300 w-full lg:max-w-[352px] outline-none pl-2"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <p className="mb-4 text-base font-medium leading-none text-black">
                                                    Last name
                                                </p>
                                                <input
                                                    type="text"
                                                    placeholder='Last Name'
                                                    className="h-12 border border-gray-300 w-full lg:max-w-[352px] outline-none pl-2"
                                                />
                                            </div>
                                        </div>

                                        <div className="flex flex-col lg:flex-row md:flex-row gap-x-8 gap-y-4">
                                            <div className="w-full">
                                                <p className="mb-4 text-base font-medium leading-none text-black">
                                                    Email
                                                </p>
                                                <input
                                                    type="email"
                                                    placeholder='Email Address'
                                                    className="h-12 border border-gray-300 w-full lg:max-w-[352px] outline-none pl-2"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <p className="mb-4 text-base font-medium leading-none text-black">
                                                    Phone Number
                                                </p>
                                                <input
                                                    type="tel"
                                                    placeholder="+1-111-1111-111"
                                                    className="h-12 border border-gray-300 w-full lg:max-w-[352px] outline-none pl-2"
                                                />
                                            </div>
                                        </div>

                                        <div className="flex flex-col lg:flex-row md:flex-row gap-x-8 gap-y-4">
                                            <div className="w-full">
                                                <p className="mb-4 text-base font-medium leading-none text-black">
                                                    Qualification
                                                </p>
                                                <input
                                                    type="text"
                                                    placeholder='i.e BSc Computer Science'
                                                    className="h-12 border border-gray-300 w-full lg:max-w-[352px] outline-none pl-2"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <p className="mb-4 text-base font-medium leading-none text-black">
                                                    Current Job Role
                                                </p>
                                                <input
                                                    type="text"
                                                    className="h-12 border border-gray-300 w-full lg:max-w-[352px] outline-none pl-2"
                                                />
                                            </div>
                                        </div>

                                        <div className="flex  z-0 flex-col lg:flex-row md:flex-row gap-x-8 gap-y-4">
                                            <div className="w-full">
                                                <p className="mb-4 text-base font-medium leading-none text-black">
                                                    Country
                                                </p>
                                                <div className="border max-w-[350px] border-gray-300 dark:border-gray-700 shadow-sm rounded flex relative">
                                                    <select type="text" name="city" required id="City" className="bg-white dark:bg-gray-800 appearance-none z-10 pl-3 py-3 w-full text-sm border border-transparent focus:outline-none focus:border-indigo-700  text-gray-800 dark:text-gray-100 rounded">
                                                        <option value="">-- Select One --</option>
                                                        <option value="AF">Afghanistan</option>
                                                        <option value="AX">Åland Islands</option>
                                                        <option value="AL">Albania</option>
                                                        <option value="DZ">Algeria</option>
                                                        <option value="AS">American Samoa</option>
                                                        <option value="AD">Andorra</option>
                                                        <option value="AO">Angola</option>
                                                        <option value="AI">Anguilla</option>
                                                        <option value="AQ">Antarctica</option>
                                                        <option value="AG">Antigua and Barbuda</option>
                                                        <option value="AR">Argentina</option>
                                                        <option value="AM">Armenia</option>
                                                        <option value="AW">Aruba</option>
                                                        <option value="AU">Australia</option>
                                                        <option value="AT">Austria</option>
                                                        <option value="AZ">Azerbaijan</option>
                                                        <option value="BS">Bahamas</option>
                                                        <option value="BH">Bahrain</option>
                                                        <option value="BD">Bangladesh</option>
                                                        <option value="BB">Barbados</option>
                                                        <option value="BY">Belarus</option>
                                                        <option value="BE">Belgium</option>
                                                        <option value="BZ">Belize</option>
                                                        <option value="BJ">Benin</option>
                                                        <option value="BM">Bermuda</option>
                                                        <option value="BT">Bhutan</option>
                                                        <option value="BO">Bolivia, Plurinational State of</option>
                                                        <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                        <option value="BA">Bosnia and Herzegovina</option>
                                                        <option value="BW">Botswana</option>
                                                        <option value="BV">Bouvet Island</option>
                                                        <option value="BR">Brazil</option>
                                                        <option value="IO">British Indian Ocean Territory</option>
                                                        <option value="BN">Brunei Darussalam</option>
                                                        <option value="BG">Bulgaria</option>
                                                        <option value="BF">Burkina Faso</option>
                                                        <option value="BI">Burundi</option>
                                                        <option value="KH">Cambodia</option>
                                                        <option value="CM">Cameroon</option>
                                                        <option value="CA">Canada</option>
                                                        <option value="CV">Cape Verde</option>
                                                        <option value="KY">Cayman Islands</option>
                                                        <option value="CF">Central African Republic</option>
                                                        <option value="TD">Chad</option>
                                                        <option value="CL">Chile</option>
                                                        <option value="CN">China</option>
                                                        <option value="CX">Christmas Island</option>
                                                        <option value="CC">Cocos (Keeling) Islands</option>
                                                        <option value="CO">Colombia</option><option value="KM">Comoros</option>
                                                        <option value="CG">Congo</option>
                                                        <option value="CD">Congo, the Democratic Republic of the</option><option value="CK">Cook Islands</option>
                                                        <option value="CR">Costa Rica</option>
                                                        <option value="CI">Côte d'Ivoire</option>
                                                        <option value="HR">Croatia</option>
                                                        <option value="CU">Cuba</option>
                                                        <option value="CW">Curaçao</option>
                                                        <option value="CY">Cyprus</option>
                                                        <option value="CZ">Czech Republic</option>
                                                        <option value="DK">Denmark</option>
                                                        <option value="DJ">Djibouti</option>
                                                        <option value="DM">Dominica</option>
                                                        <option value="DO">Dominican Republic</option>
                                                        <option value="EC">Ecuador</option>
                                                        <option value="EG">Egypt</option>
                                                        <option value="SV">El Salvador</option>
                                                        <option value="GQ">Equatorial Guinea</option>
                                                        <option value="ER">Eritrea</option>
                                                        <option value="EE">Estonia</option>
                                                        <option value="ET">Ethiopia</option>
                                                        <option value="FK">Falkland Islands (Malvinas)</option>
                                                        <option value="FO">Faroe Islands</option>
                                                        <option value="FJ">Fiji</option>
                                                        <option value="FI">Finland</option>
                                                        <option value="FR">France</option>
                                                        <option value="GF">French Guiana</option>
                                                        <option value="PF">French Polynesia</option>
                                                        <option value="TF">French Southern Territories</option>
                                                        <option value="GA">Gabon</option>
                                                        <option value="GM">Gambia</option>
                                                        <option value="GE">Georgia</option>
                                                        <option value="DE">Germany</option>
                                                        <option value="GH">Ghana</option>
                                                        <option value="GI">Gibraltar</option>
                                                        <option value="GR">Greece</option>
                                                        <option value="GL">Greenland</option>
                                                        <option value="GD">Grenada</option>
                                                        <option value="GP">Guadeloupe</option>
                                                        <option value="GU">Guam</option>
                                                        <option value="GT">Guatemala</option>
                                                        <option value="GG">Guernsey</option>
                                                        <option value="GN">Guinea</option>
                                                        <option value="GW">Guinea-Bissau</option>
                                                        <option value="GY">Guyana</option>
                                                        <option value="HT">Haiti</option>
                                                        <option value="HM">Heard Island and McDonald Islands</option>
                                                        <option value="VA">Holy See (Vatican City State)</option>
                                                        <option value="HN">Honduras</option>
                                                        <option value="HK">Hong Kong</option>
                                                        <option value="HU">Hungary</option>
                                                        <option value="IS">Iceland</option>
                                                        <option value="IN">India</option>
                                                        <option value="ID">Indonesia</option>
                                                        <option value="IR">Iran, Islamic Republic of</option>
                                                        <option value="IQ">Iraq</option>
                                                        <option value="IE">Ireland</option>
                                                        <option value="IM">Isle of Man</option>
                                                        <option value="IL">Israel</option>
                                                        <option value="IT">Italy</option>
                                                        <option value="JM">Jamaica</option>
                                                        <option value="JP">Japan</option>
                                                        <option value="JE">Jersey</option>
                                                        <option value="JO">Jordan</option>
                                                        <option value="KZ">Kazakhstan</option>
                                                        <option value="KE">Kenya</option>
                                                        <option value="KI">Kiribati</option>
                                                        <option value="KP">Korea, Democratic People's Republic of</option>
                                                        <option value="KR">Korea, Republic of</option>
                                                        <option value="KW">Kuwait</option>
                                                        <option value="KG">Kyrgyzstan</option>
                                                        <option value="LA">Lao People's Democratic Republic</option>
                                                        <option value="LV">Latvia</option>
                                                        <option value="LB">Lebanon</option>
                                                        <option value="LS">Lesotho</option>
                                                        <option value="LR">Liberia</option>
                                                        <option value="LY">Libya</option>
                                                        <option value="LI">Liechtenstein</option>
                                                        <option value="LT">Lithuania</option>
                                                        <option value="LU">Luxembourg</option>
                                                        <option value="MO">Macao</option>
                                                        <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                                        <option value="MG">Madagascar</option>
                                                        <option value="MW">Malawi</option>
                                                        <option value="MY">Malaysia</option>
                                                        <option value="MV">Maldives</option>
                                                        <option value="ML">Mali</option>
                                                        <option value="MT">Malta</option>
                                                        <option value="MH">Marshall Islands</option>
                                                        <option value="MQ">Martinique</option>
                                                        <option value="MR">Mauritania</option>
                                                        <option value="MU">Mauritius</option>
                                                        <option value="YT">Mayotte</option>
                                                        <option value="MX">Mexico</option>
                                                        <option value="FM">Micronesia, Federated States of</option>
                                                        <option value="MD">Moldova, Republic of</option>
                                                        <option value="MC">Monaco</option>
                                                        <option value="MN">Mongolia</option>
                                                        <option value="ME">Montenegro</option>
                                                        <option value="MS">Montserrat</option>
                                                        <option value="MA">Morocco</option>
                                                        <option value="MZ">Mozambique</option>
                                                        <option value="MM">Myanmar</option>
                                                        <option value="NA">Namibia</option>
                                                        <option value="NR">Nauru</option>
                                                        <option value="NP">Nepal</option>
                                                        <option value="NL">Netherlands</option>
                                                        <option value="NC">New Caledonia</option>
                                                        <option value="NZ">New Zealand</option>
                                                        <option value="NI">Nicaragua</option>
                                                        <option value="NE">Niger</option>
                                                        <option value="NG">Nigeria</option>
                                                        <option value="NU">Niue</option>
                                                        <option value="NF">Norfolk Island</option>
                                                        <option value="MP">Northern Mariana Islands</option>
                                                        <option value="NO">Norway</option>
                                                        <option value="OM">Oman</option>
                                                        <option value="PK">Pakistan</option>
                                                        <option value="PW">Palau</option>
                                                        <option value="PS">Palestinian Territory, Occupied</option>
                                                        <option value="PA">Panama</option>
                                                        <option value="PG">Papua New Guinea</option>
                                                        <option value="PY">Paraguay</option>
                                                        <option value="PE">Peru</option>
                                                        <option value="PH">Philippines</option>
                                                        <option value="PN">Pitcairn</option>
                                                        <option value="PL">Poland</option>
                                                        <option value="PT">Portugal</option>
                                                        <option value="PR">Puerto Rico</option>
                                                        <option value="QA">Qatar</option>
                                                        <option value="RE">Réunion</option>
                                                        <option value="RO">Romania</option>
                                                        <option value="RU">Russian Federation</option>
                                                        <option value="RW">Rwanda</option>
                                                        <option value="BL">Saint Barthélemy</option>
                                                        <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                                        <option value="KN">Saint Kitts and Nevis</option>
                                                        <option value="LC">Saint Lucia</option>
                                                        <option value="MF">Saint Martin (French part)</option>
                                                        <option value="PM">Saint Pierre and Miquelon</option>
                                                        <option value="VC">Saint Vincent and the Grenadines</option>
                                                        <option value="WS">Samoa</option>
                                                        <option value="SM">San Marino</option>
                                                        <option value="ST">Sao Tome and Principe</option>
                                                        <option value="SA">Saudi Arabia</option>
                                                        <option value="SN">Senegal</option>
                                                        <option value="RS">Serbia</option>
                                                        <option value="SC">Seychelles</option>
                                                        <option value="SL">Sierra Leone</option>
                                                        <option value="SG">Singapore</option>
                                                        <option value="SX">Sint Maarten (Dutch part)</option>
                                                        <option value="SK">Slovakia</option>
                                                        <option value="SI">Slovenia</option>
                                                        <option value="SB">Solomon Islands</option>
                                                        <option value="SO">Somalia</option>
                                                        <option value="ZA">South Africa</option>
                                                        <option value="GS">South Georgia and the South Sandwich Islands</option>
                                                        <option value="SS">South Sudan</option>
                                                        <option value="ES">Spain</option>
                                                        <option value="LK">Sri Lanka</option>
                                                        <option value="SD">Sudan</option>
                                                        <option value="SR">Suriname</option>
                                                        <option value="SJ">Svalbard and Jan Mayen</option>
                                                        <option value="SZ">Swaziland</option>
                                                        <option value="SE">Sweden</option>
                                                        <option value="CH">Switzerland</option>
                                                        <option value="SY">Syrian Arab Republic</option>
                                                        <option value="TW">Taiwan, Province of China</option>
                                                        <option value="TJ">Tajikistan</option>
                                                        <option value="TZ">Tanzania, United Republic of</option>
                                                        <option value="TH">Thailand</option>
                                                        <option value="TL">Timor-Leste</option>
                                                        <option value="TG">Togo</option>
                                                        <option value="TK">Tokelau</option>
                                                        <option value="TO">Tonga</option>
                                                        <option value="TT">Trinidad and Tobago</option>
                                                        <option value="TN">Tunisia</option>
                                                        <option value="TR">Turkey</option>
                                                        <option value="TM">Turkmenistan</option>
                                                        <option value="TC">Turks and Caicos Islands</option>
                                                        <option value="TV">Tuvalu</option>
                                                        <option value="UG">Uganda</option>
                                                        <option value="UA">Ukraine</option>
                                                        <option value="AE">United Arab Emirates</option>
                                                        <option value="GB">United Kingdom</option>
                                                        <option value="US">United States</option>
                                                        <option value="UM">United States Minor Outlying Islands</option>
                                                        <option value="UY">Uruguay</option>
                                                        <option value="UZ">Uzbekistan</option>
                                                        <option value="VU">Vanuatu</option>
                                                        <option value="VE">Venezuela, Bolivarian Republic of</option>
                                                        <option value="VN">Viet Nam</option>
                                                        <option value="VG">Virgin Islands, British</option>
                                                        <option value="VI">Virgin Islands, U.S.</option>
                                                        <option value="WF">Wallis and Futuna</option>
                                                        <option value="EH">Western Sahara</option>
                                                        <option value="YE">Yemen</option>
                                                        <option value="ZM">Zambia</option>
                                                        <option value="ZW">Zimbabwe</option>
                                                    </select>
                                                    <div
                                                        className="px-4 flex items-center border-l border-gray-300 dark:border-gray-700 flex-col justify-center text-gray-500
                                      dark:text-gray-400 absolute right-0 bottom-0 top-0 mx-auto z-20 pointer-events-none "
                                                    >
                                                        <svg tabIndex={0} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width={16} height={16} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <polyline points="6 9 12 15 18 9" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <p className="mb-4 text-base font-medium leading-none text-black">
                                                    State
                                                </p>
                                                <input
                                                    type="text"
                                                    className="h-12 border border-gray-300 w-full lg:max-w-[352px] outline-none pl-2"
                                                />
                                            </div>
                                        </div>

                                        <div className="flex flex-col z-0 lg:flex-row md:flex-row gap-x-8 gap-y-4">
                                            <div className="w-full">
                                                <p className="mb-4 text-base font-medium leading-none text-black">
                                                    Select Course
                                                </p>
                                                <div className="border max-w-[770px] border-gray-300 dark:border-gray-700 shadow-sm rounded flex relative">
                                                    <select type="text" name="city" required id="City" className="bg-white dark:bg-gray-800 appearance-none z-10 pl-3 py-3 w-full text-sm border border-transparent focus:outline-none focus:border-indigo-700  text-gray-800 dark:text-gray-100 rounded">
                                                        <option value="">-- Select One --</option>
                                                        <option value="AF"> Cyber Security - Practical Job Ready </option>
                                                        <option value="AX"> Busniness Analysis - Practical Job Ready</option>
                                                        <option value="AL"> Data Analystics - Practical Job Ready</option>
                                                        <option value="TJ"> Salesforce consultant - Practical Job Ready </option>
                                                        <option value="TZ">Telecoms and linux administration - Practical Job Ready </option>
                                                        <option value="WF"> Project management - Practical Job Ready </option>
                                                        <option value="EH"> Get Certifified (CBAP) </option>
                                                        <option value="YE"> Recertification (CBAP) </option>
                                                        <option value="ZM">compTIA (Security +, Network +, & CySA)</option>
                                                        <option value="ZW">CISm</option>
                                                        <option value="WF"> Salesforce </option>
                                                        <option value="EH"> Linucx Admin </option>
                                                        <option value="YE"> PMP Certification </option>
                                                        <option value="ZM"> One-on-One mentorship </option>
                                                        <option value="ZW"> On the job support </option>
                                                        <option value="ZW"> Job interview Prep </option>
                                                        <option value="ZW"> Resume Revamp </option>
                                                    </select>
                                                    <div
                                                        className="px-4 flex items-center border-l border-gray-300 dark:border-gray-700 flex-col justify-center text-gray-500
                                      dark:text-gray-400 absolute right-0 bottom-0 top-0 mx-auto z-20 pointer-events-none "
                                                    >
                                                        <svg tabIndex={0} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width={16} height={16} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <polyline points="6 9 12 15 18 9" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            {/* Section for personal details ends here */}

                            {/* Section for payment goes here */}
                            <div className="w-full mt-12 md:mt-0 xl:ml-[310px] lg:max-w-3xl xl:max-w-3xl p-2 overflow-hidden bg-deepblue rounded-2xl mainDiv md:px-6 pb-8">

                                <div id="payment" className="grid grid-cols-1 md:flex w-full mt-8 lg:flex-row lg:mt-8 xl:mt-12 gap-x-4">
                                    <div className="w-full md:w-4/12 flex md:grid md:grid-cols-1 md:h-36 items-center justify-between ">
                                        <div className="flex items-start gap-x-2 ">
                                            <button type="submit" className="flex gap-x-2 items-center px-2 py-2 md:px-4 md:py-3 bg-lightenblue hover:bg-black hover:text-white">
                                                <svg
                                                    viewBox="0 0 576 512"
                                                    fill="#fff"
                                                    height="2em"
                                                    width="2em"
                                                >
                                                    <path d="M186.3 258.2c0 12.2-9.7 21.5-22 21.5-9.2 0-16-5.2-16-15 0-12.2 9.5-22 21.7-22 9.3 0 16.3 5.7 16.3 15.5zM80.5 209.7h-4.7c-1.5 0-3 1-3.2 2.7l-4.3 26.7 8.2-.3c11 0 19.5-1.5 21.5-14.2 2.3-13.4-6.2-14.9-17.5-14.9zm284 0H360c-1.8 0-3 1-3.2 2.7l-4.2 26.7 8-.3c13 0 22-3 22-18-.1-10.6-9.6-11.1-18.1-11.1zM576 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48zM128.3 215.4c0-21-16.2-28-34.7-28h-40c-2.5 0-5 2-5.2 4.7L32 294.2c-.3 2 1.2 4 3.2 4h19c2.7 0 5.2-2.9 5.5-5.7l4.5-26.6c1-7.2 13.2-4.7 18-4.7 28.6 0 46.1-17 46.1-45.8zm84.2 8.8h-19c-3.8 0-4 5.5-4.2 8.2-5.8-8.5-14.2-10-23.7-10-24.5 0-43.2 21.5-43.2 45.2 0 19.5 12.2 32.2 31.7 32.2 9 0 20.2-4.9 26.5-11.9-.5 1.5-1 4.7-1 6.2 0 2.3 1 4 3.2 4H200c2.7 0 5-2.9 5.5-5.7l10.2-64.3c.3-1.9-1.2-3.9-3.2-3.9zm40.5 97.9l63.7-92.6c.5-.5.5-1 .5-1.7 0-1.7-1.5-3.5-3.2-3.5h-19.2c-1.7 0-3.5 1-4.5 2.5l-26.5 39-11-37.5c-.8-2.2-3-4-5.5-4h-18.7c-1.7 0-3.2 1.8-3.2 3.5 0 1.2 19.5 56.8 21.2 62.1-2.7 3.8-20.5 28.6-20.5 31.6 0 1.8 1.5 3.2 3.2 3.2h19.2c1.8-.1 3.5-1.1 4.5-2.6zm159.3-106.7c0-21-16.2-28-34.7-28h-39.7c-2.7 0-5.2 2-5.5 4.7l-16.2 102c-.2 2 1.3 4 3.2 4h20.5c2 0 3.5-1.5 4-3.2l4.5-29c1-7.2 13.2-4.7 18-4.7 28.4 0 45.9-17 45.9-45.8zm84.2 8.8h-19c-3.8 0-4 5.5-4.3 8.2-5.5-8.5-14-10-23.7-10-24.5 0-43.2 21.5-43.2 45.2 0 19.5 12.2 32.2 31.7 32.2 9.3 0 20.5-4.9 26.5-11.9-.3 1.5-1 4.7-1 6.2 0 2.3 1 4 3.2 4H484c2.7 0 5-2.9 5.5-5.7l10.2-64.3c.3-1.9-1.2-3.9-3.2-3.9zm47.5-33.3c0-2-1.5-3.5-3.2-3.5h-18.5c-1.5 0-3 1.2-3.2 2.7l-16.2 104-.3.5c0 1.8 1.5 3.5 3.5 3.5h16.5c2.5 0 5-2.9 5.2-5.7L544 191.2v-.3zm-90 51.8c-12.2 0-21.7 9.7-21.7 22 0 9.7 7 15 16.2 15 12 0 21.7-9.2 21.7-21.5.1-9.8-6.9-15.5-16.2-15.5z" />
                                                </svg>
                                                <p className="mt-1 text-sm font-medium leading-none text-white">
                                                    Payment
                                                </p>
                                                <input
                                                    type="radio"
                                                    className="mt-1 accent-gray-600"
                                                    name="same"
                                                    id
                                                />
                                            </button>
                                        </div>

                                        <div className="flex items-start gap-x-4 md:mt-4">
                                            <button type="submit" className="flex gap-x-2 items-center px-2 py-2 md:px-4 md:py-3 bg-green1 hover:bg-black hover:text-white">
                                                <svg
                                                    viewBox="0 0 576 512"
                                                    fill="#fff"
                                                    height="2em"
                                                    width="2em"
                                                >
                                                    <path d="M492.4 220.8c-8.9 0-18.7 6.7-18.7 22.7h36.7c0-16-9.3-22.7-18-22.7zM375 223.4c-8.2 0-13.3 2.9-17 7l.2 52.8c3.5 3.7 8.5 6.7 16.8 6.7 13.1 0 21.9-14.3 21.9-33.4 0-18.6-9-33.2-21.9-33.1zM528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM122.2 281.1c0 25.6-20.3 40.1-49.9 40.3-12.2 0-25.6-2.4-38.8-8.1v-33.9c12 6.4 27.1 11.3 38.9 11.3 7.9 0 13.6-2.1 13.6-8.7 0-17-54-10.6-54-49.9 0-25.2 19.2-40.2 48-40.2 11.8 0 23.5 1.8 35.3 6.5v33.4c-10.8-5.8-24.5-9.1-35.3-9.1-7.5 0-12.1 2.2-12.1 7.7 0 16 54.3 8.4 54.3 50.7zm68.8-56.6h-27V275c0 20.9 22.5 14.4 27 12.6v28.9c-4.7 2.6-13.3 4.7-24.9 4.7-21.1 0-36.9-15.5-36.9-36.5l.2-113.9 34.7-7.4v30.8H191zm74 2.4c-4.5-1.5-18.7-3.6-27.1 7.4v84.4h-35.5V194.2h30.7l2.2 10.5c8.3-15.3 24.9-12.2 29.6-10.5h.1zm44.1 91.8h-35.7V194.2h35.7zm0-142.9l-35.7 7.6v-28.9l35.7-7.6zm74.1 145.5c-12.4 0-20-5.3-25.1-9l-.1 40.2-35.5 7.5V194.2h31.3l1.8 8.8c4.9-4.5 13.9-11.1 27.8-11.1 24.9 0 48.4 22.5 48.4 63.8 0 45.1-23.2 65.5-48.6 65.6zm160.4-51.5h-69.5c1.6 16.6 13.8 21.5 27.6 21.5 14.1 0 25.2-3 34.9-7.9V312c-9.7 5.3-22.4 9.2-39.4 9.2-34.6 0-58.8-21.7-58.8-64.5 0-36.2 20.5-64.9 54.3-64.9 33.7 0 51.3 28.7 51.3 65.1 0 3.5-.3 10.9-.4 12.9z" />
                                                </svg>
                                                <p className="mt-1 text-sm font-medium leading-none text-white ">
                                                    Payment
                                                </p>

                                                <input
                                                    type="radio"
                                                    className="mt-1 accent-gray-600"
                                                    name="same"
                                                    id
                                                />
                                            </button>
                                        </div>
                                    </div>

                                    <div className=" mt-8 md:mt-0  md:w-8/12 flex flex-col gap-y-4">
                                        <div className="flex flex-col lg:flex-row md:flex-row gap-x-8 gap-y-4">
                                            <div className="w-full">
                                                <p className="mb-4 text-base font-medium leading-none text-white">
                                                    Name as on Card
                                                </p>
                                                <input className="border rounded border-gray-300 p-4 w-full text-base leading-4 placeholder-gray-600 text-gray-600" type="email" placeholder="Name on card" />
                                            </div>
                                        </div>

                                        <div className="flex flex-col lg:flex-row md:flex-row gap-x-8 gap-y-4">
                                            <div className="w-full">
                                                <p className="mb-4 text-base font-medium leading-none text-white">
                                                    Card Number
                                                </p>
                                                <input className="border rounded-tl rounded-tr border-gray-300 p-4 w-full text-base leading-4 placeholder-gray-600 text-gray-600" type="email" placeholder="0000 1234 6549 15151" />
                                            </div>
                                        </div>

                                        <div className="flex flex-col lg:flex-row md:flex-row gap-x-8 gap-y-4">
                                            <div className="w-full">
                                                <p className="mb-4 text-base font-medium leading-none text-white">
                                                    Expire Date
                                                </p>
                                                <input className="border rounded-bl border-gray-300 p-4 w-full text-base leading-4 placeholder-gray-600 text-gray-600" type="email" placeholder="MM/YY" />
                                            </div>
                                            <div className="w-full">
                                                <p className="mb-4 text-base font-medium leading-none text-white">
                                                    CVC
                                                </p>
                                                <input className="border rounded-br border-gray-300 p-4 w-full text-base leading-4 placeholder-gray-600 text-gray-600" type="email" placeholder="CVC" />
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <button type='submit' className="makepaymentButton rounded-lg">
                                    Make Payment
                                </button>

                            </div>
                        </form>
                        {/* Section for payment ends here */}
                    </div>

                </div>

            </Container>
            <Footer />
        </>
    )
}

export default ApplicationForm