import { createReducer } from "@reduxjs/toolkit";

// const initialState = {
//     isLoading: true,
// };

// export const bannerReducer = createReducer(initialState, {
//     bannerCreateRequest: (state) => {
//         state.isLoading = true;
//     },
//     bannerCreateSuccess: (state, action) => {
//         state.isLoading = false;
//         state.banner = action.payload;
//         state.success = true;
//     },
//     bannerCreateFail: (state, action) => {
//         state.isLoading = false;
//         state.error = action.payload;
//         state.success = false;
//     },

//     // get all banners
//     getAllBannerRequest: (state) => {
//         state.isLoading = true;
//     },
//     getAllBannerSuccess: (state, action) => {
//         state.isLoading = false;
//         state.banners = action.payload;
//     },
//     getAllBannerFailed: (state, action) => {
//         state.isLoading = false;
//         state.error = action.payload;
//     },

//     // delete banner
//     deleteBannerRequest: (state) => {
//         state.isLoading = true;
//     },
//     deleteBannerSuccess: (state, action) => {
//         state.isLoading = false;
//         state.message = action.payload;
//     },
//     deleteBannerFailed: (state, action) => {
//         state.isLoading = false;
//         state.error = action.payload;
//     },
// });


// Initial state
const initialState = {
    isLoading: true,
};

// Reducer
export const bannerReducer = createReducer(initialState, (builder) => {
    builder
        .addCase('bannerCreateRequest', (state) => {
            state.isLoading = true;
        })
        .addCase('bannerCreateSuccess', (state, action) => {
            state.isLoading = false;
            state.banner = action.payload;
            state.success = true;
        })
        .addCase('bannerCreateFail', (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            state.success = false;
        })
        .addCase('getAllBannerRequest', (state) => {
            state.isLoading = true;
        })
        .addCase('getAllBannerSuccess', (state, action) => {
            state.isLoading = false;
            state.banners = action.payload;
        })
        .addCase('getAllBannerFailed', (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        .addCase('deleteBannerRequest', (state) => {
            state.isLoading = true;
        })
        .addCase('deleteBannerSuccess', (state, action) => {
            state.isLoading = false;
            state.message = action.payload;
        })
        .addCase('deleteBannerFailed', (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
});