import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Meta from "../Meta";
import Container from "../Container";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

const ShopResetPassword = () => {
    const { token } = useParams();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
     const navigate = useNavigate();
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        await axios
            .put(`${server}/shop/shop-password-reset/${token}`, {
                password,
                confirmPassword,
            })
            .then((res) => {
                toast.success("Password updated successfully");
                navigate("/login");
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };
    
    return (
        <>
            <Meta title={"Reset-Password"} />
            <Header />
            <div className="flex min-h-full flex-1 flex-col justify-center bg-gray-100 bg-cover bg-center" style={{ backgroundImage: `url('../images/background.jpg')` }}>
                <div className="w-full h-auto bg-black bg-opacity-70">
                    <Container>
                        <div className="mt-0 sm:mx-auto sm:w-full sm:max-w-[480px] bg-transparent pb-1 pt-4 rounded-lg">
                            <img
                                className="mx-auto h-28 w-auto"
                                src="../images/logo.png"
                                alt="Your Company"
                            />

                            <h2 className="mt-2 text-center text-2xl font-semibold leading-9 tracking-tight text-white">
                                Reset Staff Password
                            </h2>

                            <h6 className="mt-1 text-center text-sm font-semibold tracking-tight text-white">
                                We will send you an email to reset your password
                            </h6>

                            <div className="bg-deepblue px-6 py-4 mt-2 md:mt-4 shadow sm:rounded-lg sm:px-12">
                                <form className="space-y-6" onSubmit={handleSubmit}>

                                    <div>
                                        <div className="mt-2">
                                            <input
                                                type="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="Enter new password"
                                                required
                                                className="registerInput"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="mt-2">
                                            <input
                                                type="password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                placeholder="Confirm new password"
                                                required
                                                className="registerInput"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="flex w-full justify-center bg-lightenblue px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Change Password
                                        </button>
                                    </div>
                                </form>

                            </div>

                            <p className="mt-6 text-center text-sm text-black font-semibold mb-4">
                                <Link to="/shop-login" className="font-semibold leading-6 text-white hover:text-white px-4 py-2 bg-crimson hover:bg-black">
                                    Cancel Password Reset
                                </Link>
                            </p>
                        </div>
                    </Container>
                </div>
            </div>
    <Footer />
        </>
    )

}

export default ShopResetPassword
