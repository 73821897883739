import axios from "axios";
import { server } from "../../server";

// create product
export const createProduct = (name, description, category, brands, tags, days, enddays, modeoftutor, aimsandobjectives, aboutthecourse, duration, originalPrice, startTime, endTime, timezone,
    startDate, shopId, images1, images2, images3) => async (dispatch) => {
    try {
        dispatch({ type: "productCreateRequest" });

        const { data } = await axios.post(`${server}/product/create-product`, name, description, category, brands, tags, days, enddays, modeoftutor, aimsandobjectives, aboutthecourse, duration, originalPrice, startTime, endTime, timezone,
            startDate, shopId, images1, images2, images3);
        dispatch({ type: "productCreateSuccess", payload: data.product, });
    } catch (error) {
        dispatch({
            type: "productCreateFail",
            payload: error.response.data.message,
        });
    }
};


// Edit product
export const updateProduct = (id, data) => async (dispatch) => {
    try {
        dispatch({
            type: "productUpdateRequest",
        });

        const { data: response } = await axios.put(`${server}/product/update-product/${id}`, data, { withCredentials: true });

        dispatch({
            type: "productUpdateSuccess",
            payload: response.product,
        });
    } catch (error) {
        dispatch({
            type: "productUpdateFail",
            payload: error.response.data.message,
        });
    }
};

// get products by brand

export const getProductsByBrand = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'getProductsByBrandRequest' });
        const { data } = await axios.get(`${server}/product/get-products-by-brand/${id}`);
        dispatch({ type: 'getProductsByBrandSuccess', payload: data.products });
    } catch (error) {
        dispatch({ type: 'getProductsByBrandFailed', payload: error.response.data.message });
    }
};

// Get Product details
export const getProductDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: "productDetailsRequest" });

        const { data } = await axios.get(`${server}/product/get-product/${id}`);

        dispatch({
            type: "productDetailsSuccess",
            payload: data.product,
        });
    } catch (error) {
        dispatch({
            type: "productDetailsFail",
            payload: error.response.data.message,
        });
    }
};


// get All Products of a shop
export const getAllProductsShop = (id) => async (dispatch) => {
    try {
        dispatch({
            type: "getAllProductsShopRequest",
        });

        const { data } = await axios.get(
            `${server}/product/get-all-products-shop/${id}`
        );
        dispatch({
            type: "getAllProductsShopSuccess",
            payload: data.products,
        });
    } catch (error) {
        dispatch({
            type: "getAllProductsShopFailed",
            payload: error.response.data.message,
        });
    }
};

// delete product of a shop
export const deleteProduct = (id) => async (dispatch) => {
    try {
        dispatch({
            type: "deleteProductRequest",
        });

        const { data } = await axios.delete(
            `${server}/product/delete-shop-product/${id}`,
            {
                withCredentials: true,
            }
        );

        dispatch({
            type: "deleteProductSuccess",
            payload: data.message,
        });
    } catch (error) {
        dispatch({
            type: "deleteProductFailed",
            payload: error.response.data.message,
        });
    }
};

// get all products
export const getAllProducts = () => async (dispatch) => {
    try {
        dispatch({
            type: "getAllProductsRequest",
        });

        const { data } = await axios.get(`${server}/product/get-all-products`);
        dispatch({
            type: "getAllProductsSuccess",
            payload: data.products,
        });
    } catch (error) {
        dispatch({
            type: "getAllProductsFailed",
            payload: error.response.data.message,
        });
    }
};
