import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "react-slideshow-image/dist/styles.css";
import { Zoom } from "react-slideshow-image";
import { toast } from "react-toastify";
import { FaCircleArrowRight, FaCircleArrowLeft } from "react-icons/fa6";
import BannerCard from './BannerCard';
import { getAllBanners } from '../../../redux/actions/banner';


const Banner = () => {

    const dispatch = useDispatch();
    const { banners, error, isLoading } = useSelector((state) => state.banners);
    useEffect(() => {
        dispatch(getAllBanners());
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    let zoomInProperties = {
        scale: 1,
        duration: 5000,
        transitionDuration: 300,
        infinite: true,
        prevArrow: (
            <div className="prevArrow-div2 relative z-10">
                <FaCircleArrowLeft className="prevArrow-div-icon2" />
            </div>
        ),
        nextArrow: (
            <div className="nextArrow-div2 relative  z-10">
                <FaCircleArrowRight className="nextArrow-div-icon2" />
            </div>
        ),
    };

    return (
        <div className=" px-0 z-0">
            {!isLoading && banners && banners.length > 0 && (
                <Zoom {...zoomInProperties}>

                    {
                        banners?.map((i, index) => {
                            if (i.tags === "text and image") {
                                return (
                                    <BannerCard data={i} key={index} />
                                );
                            }
                            return null;
                        })
                    }
                    {
                        // Check if there are no "special" tags and show the message if true
                        banners?.every(banner => banner.tags !== "text and image") && (
                            <p className="font-semibold text-lg"> No Banner Found </p>
                        )
                    }
                </Zoom>
            )}
        </div>
    )
}

export default Banner


