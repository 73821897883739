// components/CreateBrand.jsx
import { useDispatch, useSelector } from "react-redux";
import { createBrand, getAllBrands, deleteBrand, updateBrand } from "../../redux/actions/brand";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { TiEdit } from "react-icons/ti";
import { AiOutlineDelete } from "react-icons/ai";
import styles from "../../styles/styles";

const CreateBrand = () => {
    const dispatch = useDispatch();
    const { brands, error, success } = useSelector((state) => state.brands);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [editId, setEditId] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    useEffect(() => {
        dispatch(getAllBrands());
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
        if (success) {
            toast.success("Operation successful!");
            setName("");
            setDescription("");
            setEditId(null);
        }
    }, [error, success]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (editId) {
            dispatch(updateBrand(editId, name, description));
        } else {
            dispatch(createBrand(name, description));
        }
        setName("");
        setDescription("");
    };

    const handleEdit = (brand) => {
        setName(brand.name);
        setDescription(brand.description);
        setEditId(brand._id);
    };

    const handleDelete = (id) => {
        setDeleteId(id);
        setIsDeleteModalOpen(true);
    };

    const confirmDelete = () => {
        dispatch(deleteBrand(deleteId));
        setIsDeleteModalOpen(false);
    };

   
    return (
        <div className="w-full p-2 md:p-6 bg-lightenblue md:h-[88vh] overflow-hidden">
            <div className="bg-white relative w-full p-4 h-[85vh] max-h-[85vh] md:h-[82vh] md:max-h-[82vh] rounded-xl mt-0 overflow-x-scroll md:overflow-x-hidden">
                <div className="flex items-center justify-start w-full max-w-[100%] lg:max-w-[100%] 2xl:max-w-[100%] z-10 px-2 md:px-4 py-2 rounded-lg bg-darkblue">
                    <h3 className="flex items-start justify-start gap-2 text-lg font-semibold text-white sm:text-xl lg:text-2xl font-Roboto">
                        <span className="hidden sm:block"> STAFF DASHBOARD </span>  <span className="leading-tight"> [Add Courses Sub-Category] </span>
                    </h3>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col w-full gap-2 py-2 mt-2 md:flex-row md:items-center md:justify-between md:mt-4 md:gap-8">
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Brand Name" required className={`${styles.input4} border-1 border-gray-700`} />
                        <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} className={`${styles.input4} border-1 border-gray-600`} placeholder="Brand Description"></input>
                        <button type="submit" className="w-full items-center justify-center md:w-44 flex px-4 py-2 text-[12px] font-semibold text-white bg-deepblue hover:bg-lightenblue">
                            {editId ? "Update Sub-Category" : "+ Sub-Category"}
                        </button>
                    </div>
                </form>
               
                <div className="mt-4 overflow-x-auto">
                    <table className="min-w-full border border-collapse border-gray-300 table-auto">
                        <thead className="text-white bg-darkblue">
                            <tr>
                                <th className="px-4 py-0 border border-gray-300 md:py-2 ">Sub-Category ID</th>
                                <th className="px-4 py-0 border border-gray-300 md:py-2">Sub-Category Name</th>
                                <th className="px-4 py-2 border border-gray-300">Description</th>
                                <th className="px-4 py-0 border border-gray-300 md:py-2">Edit</th>
                                <th className="px-4 py-0 border border-gray-300 md:py-2">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {brands.map((brand) => (
                                <tr key={brand._id} className="text-center">
                                    <td className="px-4 py-2 border border-gray-300">{brand._id}</td>
                                    <td className="px-4 py-2 border border-gray-300">{brand.name}</td>
                                    
                                    <td className="px-4 py-2 border border-gray-300">{brand.description}</td>
                                    <td className="px-4 py-2 border border-gray-300">
                                        <button onClick={() =>handleEdit(brand)} className="text-green-600">
                                            <TiEdit size={20} />
                                        </button>
                                    </td>
                                    <td className="px-4 py-2 border border-gray-300">
                                        <button onClick={() => handleDelete(brand._id)} className="text-red-600">
                                            <AiOutlineDelete size={20} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {isDeleteModalOpen && (
                <div className="fixed inset-0 z-10 overflow-x-auto">
                    <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-700 opacity-75"></div>
                        </div>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 bg-deepblue">
                                <div className="sm:flex sm:items-start">
                                    <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                                        {/* Icon */}
                                        <AiOutlineDelete size={20} className="font-bold text-red-900" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 className="text-lg font-medium leading-6 text-white">Delete Confirmation</h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-white">Are you sure you want to delete this brand?</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="items-center justify-center gap-8 px-4 py-3 bg-white sm:px-6 sm:flex ">
                                <button
                                    onClick={confirmDelete}
                                    className="px-4 py-1 font-semibold text-white bg-red-600 rounded-lg"
                                >
                                    Delete
                                </button>
                                <button
                                    onClick={() => setIsDeleteModalOpen(false)}
                                    className="px-4 py-1 font-semibold text-white rounded-lg bg-deepblue"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateBrand;
