import React from "react";
import { FiShoppingBag } from "react-icons/fi";
import { Link } from "react-router-dom";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsHandbagFill } from "react-icons/bs";
import { MdSpaceDashboard } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { TfiLayoutSliderAlt } from "react-icons/tfi";
import { TfiLayoutSlider } from "react-icons/tfi";
import { MdOutlineKeyboardReturn } from "react-icons/md";

const AdminSideBar = ({ active }) => {
  return (
    <div className="relative w-full h-[88vh] bg-deepblue shadow-sm left-0 z-10 pt-8">
      {/* single item */}
      <div className="flex items-center w-full px-4 py-2">
        <Link to="/admin/dashboard" className="flex items-center w-full gap-4">
          <MdSpaceDashboard 
            size={30}
            color={`${active === 1 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 1 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
            }`}
          >
            Dashboard
          </h5>
        </Link>
      </div>

      <div className="flex items-center w-full px-4 py-2">
        <Link to="/admin-orders" className="flex items-center w-full gap-4">
          <FiShoppingBag
            size={30}
            color={`${active === 2 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 2 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
            }`}
          >
            All Registered Course
          </h5>
        </Link>
      </div>

      <div className="flex items-center w-full px-4 py-2">
        <Link to="/admin-sellers" className="flex items-center w-full gap-4">
          <FaUsers
            size={30}
            color={`${active === 3 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 3 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
            }`}
          >
            All Registered Staffs
          </h5>
        </Link>
      </div>

      <div className="flex items-center w-full px-4 py-2">
        <Link to="/admin-users" className="flex items-center w-full gap-4">
          <HiOutlineUserGroup
            size={30}
            color={`${active === 4 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 4 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
            }`}
          >
            All Registered Users
          </h5>
        </Link>
      </div>

      <div className="flex items-center w-full px-4 py-2">
        <Link to="/admin-products" className="flex items-center w-full gap-4">
          <BsHandbagFill
            size={30}
            color={`${active === 5 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 5 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
            }`}
          >
            All Uploaded Course
          </h5>
        </Link>
      </div>

      {/* Create Banner Section Goes Here */}
      <div className="flex items-center w-full px-4 py-2">
        <Link to="/admin-create-banner" className="flex items-center w-full gap-4">
          <TfiLayoutSliderAlt
            size={30}
            color={`${active === 10 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${active === 10 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
              }`}
          >
            Create Banner
          </h5>
        </Link>
      </div>

      <div className="flex items-center w-full px-4 py-2">
        <Link to="/admin-view-banners" className="flex items-center w-full gap-4">
          <TfiLayoutSlider 
            size={30}
            color={`${active === 12 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${active === 12 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
              }`}
          >
            View All Banners
          </h5>
        </Link>
      </div>

      {/* Create Banner Section Ends Here */}

      
      {/* Create Testimony Section Goes Here */}
      <div className="flex items-center w-full px-4 py-2">
        <Link to="/admin-create-testimonys" className="flex items-center w-full gap-4">
          <TfiLayoutSliderAlt
            size={30}
            color={`${active === 13 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${active === 13 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
              }`}
          >
            Create Testimony
          </h5>
        </Link>
      </div>

      <div className="flex items-center w-full px-4 py-2">
        <Link to="/admin-view-testimonys" className="flex items-center w-full gap-4">
          <TfiLayoutSlider
            size={30}
            color={`${active === 14 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${active === 14 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
              }`}
          >
            View All Testimony
          </h5>
        </Link>
      </div>

      {/* Create Testimony Section Ends Here */}

      
      {/* <div className="flex items-center w-full px-4 py-3">
        <Link to="/admin-events" className="flex items-center w-full gap-4">
          <TbCalendarEvent 
            size={30}
            color={`${active === 7 ? "#fff" : "#fff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 7 ? "text-white font-medium bg-darkblue md:pr-2 pl-4 md:py-1 w-full" : "text-[#fff]"
            }`}
          >
            All Events
          </h5>
        </Link>
      </div> */}


      <div className="absolute bottom-3 w-full flex items-center px-4 py-2">
        <Link
          to="/profile"
          className="w-full flex items-center gap-4 p-2 bg-lightenblue rounded-xl"
        >
          <MdOutlineKeyboardReturn 
            size={30}
            // color={`${active === 9 ? "text-deepblue" : "text-deepblue"}`}
            color={"deepblue"}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 9 ? "text-deepblue font-bold" : "text-deepblue font-bold"
            }`}
          >
            Back to Profile
          </h5>
        </Link>
      </div>

    </div>
  );
};

export default AdminSideBar;
