// redux/actions/category.js
import axios from "axios";
import { server } from "../../server";

// Create category
export const createCategory = (name, description, brands) => async (dispatch) => {
    try {
        dispatch({ type: "categoryCreateRequest" });
        const { data } = await axios.post(`${server}/category/create-category`, { name, description, brands });
        dispatch({ type: "categoryCreateSuccess", payload: data.category });
    } catch (error) {
        dispatch({ type: "categoryCreateFail", payload: error.response.data.message });
    }
};

// Get all categories
export const getAllCategories = () => async (dispatch) => {
    try {
        dispatch({ type: "getAllCategoriesRequest" });
        const { data } = await axios.get(`${server}/category/get-all-categories`);
        dispatch({ type: "getAllCategoriesSuccess", payload: data.categories });
    } catch (error) {
        dispatch({ type: "getAllCategoriesFail", payload: error?.response?.data?.message });
    }
};

// Update category
export const updateCategory = (id, name, description, brands) => async (dispatch) => {
    try {
        dispatch({ type: "categoryUpdateRequest" });
        const { data } = await axios.put(`${server}/category/update-category/${id}`, { name, description, brands });
        dispatch({ type: "categoryUpdateSuccess", payload: data.category });
    } catch (error) {
        dispatch({ type: "categoryUpdateFail", payload: error.response.data.message });
    }
};

// Delete category
export const deleteCategory = (id) => async (dispatch) => {
    try {
        dispatch({ type: "categoryDeleteRequest" });
        await axios.delete(`${server}/category/delete-category/${id}`);
        dispatch({ type: "categoryDeleteSuccess", payload: id });
    } catch (error) {
        dispatch({ type: "categoryDeleteFail", payload: error.response.data.message });
    }
};
