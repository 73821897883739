import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import opeyemiolatunji from '../images/opeyemiolatunji.jpg';
import olugbengaajayi from '../images/olugbengaajayi.jpg';
import olumideoluloye from '../images/olumideoluloye.jpg';

const OurTeam = () => {
    const [isVisible, setIsVisible] = useState(false);
    const allTeamRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.3 }
        );

        if (allTeamRef.current) {
            observer.observe(allTeamRef.current);
        }

        return () => {
            if (allTeamRef.current) observer.unobserve(allTeamRef.current);
        };
    }, []);

    const teamsVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.7,
                ease: "easeOut",
                staggerChildren: 0.7
            }
        }
    };

    const teamItemVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 }
    };

    return (
        <>
            <div
                ref={allTeamRef}
                className="w-full grid grid-rows bg-ping-600 mt-4 md:mt-2 md:py-2 lg:mt-8 lg:py-6 px-6 md:px-14 lg:px-20 gap-y-2 lg:mb-0 md:mb-10"
            >
                <div className="mx-auto container xl:px-20 lg:px-0 md:px-6 px-0 py-3 md:py-4 lg:py-12">
                    <div className="flex items-center justify-center flex-col">
                        <h2 className="text-lg md:text-2xl lg:text-3xl xl:text-4xl font-bold leading-7 md:bg-white md:text-deepblue text-center bg-deepblue text-white py-2 px-2 md:px-0 rounded-lg">
                            Faces Of Team Members Behind Our Success
                        </h2>
                        <p className="text-sm md:text-lg lg:text-lg xl:text-xl font-medium lg:font-normal xl:font-medium font-sans leading-6 md:text-center text-justify text-black mt-3 md:mt-2 md:w-12/12 lg:w-10/12 xl:w-8/12">
                            Meet the passionate and dedicated professionals behind Wumbis. Our team brings together a
                            diverse range of backgrounds, expertise, and perspectives to provide comprehensive consulting
                            services that meet the unique needs of our clients.
                        </p>
                    </div>
                    <motion.div
                        className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 pb-6 pt-4 md:pt-12  lg:pt-20 gap-y-8"
                        variants={teamsVariants}
                        initial="hidden"
                        animate={isVisible ? "visible" : "hidden"}
                    >
                        <motion.div
                            className="w-full flex items-center justify-center flex-col teams"
                            variants={teamItemVariants}
                        >
                            <div className="w-full px-5 -mb-10 relative z-0">
                                <img src={opeyemiolatunji} alt="opeyemi olatunji" className="ourteamImage" />
                            </div>
                            <div className="ourteamNameDiv">
                                <p className="text-xl font-semibold leading-5 text-center text-white">
                                    OPEYEMI OLATUNJI
                                </p>
                                <p className="ourteamSecondParagraph">Cyber Security</p>
                            </div>
                        </motion.div>
                        <motion.div
                            className="w-full flex items-center justify-center flex-col teams"
                            variants={teamItemVariants}
                        >
                            <div className="w-full px-5 -mb-10 relative z-0">
                                <img src={olugbengaajayi} alt="olugbenga ajayi" className="ourteamImage" />
                            </div>
                            <div className="ourteamNameDiv">
                                <p className="text-xl font-semibold leading-5 text-center text-white">
                                    OLUGBEGA AJAYI
                                </p>
                                <p className="ourteamSecondParagraph">Project Management</p>
                            </div>
                        </motion.div>
                        <motion.div
                            className="w-full flex items-center justify-center flex-col teams"
                            variants={teamItemVariants}
                        >
                            <div className="w-full px-5 -mb-10 relative z-0">
                                <img src={olumideoluloye} alt="Olumide Oluloye" className="ourteamImage" />
                            </div>
                            <div className="ourteamNameDiv">
                                <p className="text-xl font-semibold leading-5 text-center text-white">
                                    OLUMIDE OLULOYE
                                </p>
                                <p className="ourteamSecondParagraph">Telecoms & Linux Administrator</p>
                            </div>
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </>
    );
};

export default OurTeam;
