import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import { RxAvatar } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Container from "../Container";
import Meta from "../Meta";

const Singup = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [avatar, setAvatar] = useState(null);

  const handleFileInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    axios
      .post(`${server}/user/create-user`, { name, email, phoneNumber, password, avatar })
      .then((res) => {
        toast.success(res.data.message);
        setName("");
        setEmail("");
        setPhoneNumber("");
        setPassword("");
        setAvatar();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <Meta title={"Register Page"} />
      <div className="flex min-h-full flex-1 flex-col justify-center bg-gray-100 bg-cover bg-center" style={{ backgroundImage: `url('../images/background.jpg')` }}>
        <div className="w-full h-auto bg-black bg-opacity-70">
          <Container>
            <div className="mt-0 sm:mx-auto sm:w-full sm:max-w-[480px] bg-transparent py-4 rounded-lg">
              <img
                className="mx-auto h-20 w-auto "
                src="../images/logo.png"
                alt="Your Company"
              />

              <h2 className="mt-2 text-center text-2xl font-semibold leading-9 tracking-tight text-white">
                Create New User Account
              </h2>

              <div className="bg-deepblue px-4 py-4 md:py-8 shadow sm:rounded-lg sm:px-12 mt-2 md:mt-2">
                <form className="space-y-6" onSubmit={handleSubmit}>

                  <div className='mt-0'>
                    <label
                      htmlFor="name"
                      className="block text-sm font-bold text-white"
                    >
                      Full Name
                    </label>

                    <div className="mt-1">
                      <input
                        type="text"
                        name="text"
                        autoComplete="name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>


                  <div className='mt-0'>
                    <label
                      htmlFor="email"
                      className="block text-sm font-bold text-white"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        name="email"
                        autoComplete="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className='mt-0'>
                    <label
                      htmlFor="mobile"
                      className="block text-sm font-bold text-white"
                    >
                      Mobile No
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="phoneNumber"
                        required
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className='mt-0'>
                    <label
                      htmlFor="password"
                      className="block text-sm font-bold text-white"
                    >
                      Password
                    </label>
                    <div className="relative mt-1">
                      <input
                        type={visible ? "text" : "password"}
                        name="password"
                        autoComplete="current-password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                      {visible ? (
                        <AiOutlineEye
                          className="absolute cursor-pointer right-2 top-2"
                          size={25}
                          onClick={() => setVisible(false)}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="absolute cursor-pointer right-2 top-2"
                          size={25}
                          onClick={() => setVisible(true)}
                        />
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="mt-2">
                      <label
                        htmlFor="avatar"
                        className="block text-sm font-medium text-gray-700"
                      ></label>
                      <div className="flex items-center mt-2">
                        <span className="inline-block w-8 h-8 overflow-hidden rounded-full">
                          {avatar ? (
                            <img
                              src={avatar}
                              alt="avatar"
                              className="object-cover w-full h-full rounded-full"
                            />
                          ) : (
                            <RxAvatar className="w-8 h-8 text-black bg-white" />
                          )}
                        </span>
                        <label
                          htmlFor="file-input"
                          className="flex items-center justify-center px-4 py-2 ml-5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                        >
                          <span className="text-sm font-bold text-black cursor-pointer">Upload Passport</span>
                          <input
                            type="file"
                            name="avatar"
                            id="file-input"
                            accept=".jpg,.jpeg,.png"
                            onChange={handleFileInputChange}
                            className="sr-only"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        name="remember-me"
                        id="remember-me"
                        className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <label htmlFor="remember-me" className="ml-3 block text-sm md:text-base lg:text-base leading-6 text-white">
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm leading-6">
                      <Link to="/user-forgot-password" className="font-semibold text-sm md:text-base lg:text-base text-white hover:text-lightenblue">
                        Forgot password?
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center bg-lightenblue px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Create Account
                    </button>
                  </div>
                </form>

              </div>

              <p className="mt-4 mb-4 text-center text-sm text-white font-semibold">
                Already Exist?{' '}
                <Link to="/login" className="ml-3 font-semibold leading-6 text-white hover:text-white px-4 py-2 bg-deepblue">
                  User Login
                </Link>
              </p>
            </div>
          </Container>
        </div>
      </div>

    </>
  )
};

export default Singup;
