import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { server } from "../../server";
import styles from "../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductsShop } from "../../redux/actions/product";

const ShopInfo = ({ isOwner }) => {
  const [data, setData] = useState({});
  const { products } = useSelector((state) => state.products);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductsShop(id));
    setIsLoading(true);
    axios.get(`${server}/shop/get-shop-info/${id}`).then((res) => {
      setData(res.data.shop);
      setIsLoading(false);
    }).catch((error) => {
      // console.log(error);
      setIsLoading(false);
    })
  }, [id, dispatch])


  const logoutHandler = async () => {
    axios.get(`${server}/shop/logout`, {
      withCredentials: true,
    });
    window.location.reload();
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings = products && products.reduce((acc, product) => acc + product.reviews.reduce((sum, review) => sum + review.rating, 0), 0);

  const averageRating = totalRatings / totalReviewsLength || 0;

  return (
    <>
      {
        isLoading ? (
          <div className="w-full h-[90vh] max-h-[100%] p-1 bg-darkblue">
          {/* <Loader /> */}
            <h1 className="font-semibold"> Loading please Wait</h1>
          </div>
        ) : (
          <div className="w-full h-[90vh] max-h-[90vh] p-1 bg-darkblue">
            <div>
              <div className="w-full py-5">
                <div className="w-full flex item-center justify-center">
                  <img
                    src={`${data.avatar?.url}`}
                    alt=""
                    className="w-[150px] h-[150px] object-cover rounded-full"
                  />
                </div>
                <div className="flex text-[#fff] py-[10px] items-center justify-center px-6">
                  <h3 className="text-center text-lg py-2 px-4 rounded-2xl text-white bg-lightenblue">{data.name}</h3>
                </div>
                <div className="flex flex-col text-[12px] text-[#fff] py-[10px] items-center justify-center px-6">
                  <p className="font-medium bg-black px-4 py-1 rounded-xl"> Staff Bio Discription </p>
                  <p className="font-normal flex flex-wrap  w-full overflow-x-auto mt-2"> {data.description} </p>
                </div>
              </div>

              {/* <div className="grid grid-cols-1 w-full items-center justify-between gap-3 px-6">
                  <div className="flex w-full items-start justify-between">
                    <span className="font-medium text-[#fff] text-sm px-2 py-1 bg-black rounded-sm">Address:</span>
                    <h4 className="text-[#fff] text-sm py-1 px-2 ">{data.address}</h4> 
                  </div>
                  <div className="flex w-full items-start justify-between">
                    <span className="font-medium text-[#fff] text-sm px-2 py-1 bg-black rounded-sm">Phone Number</span>
                    <h4 className="text-[#fff] text-sm  px-2 py-1 ">{data.phoneNumber}</h4>
                  </div>
                </div> */}

              <div className="flex w-full overflow-hidden px-4">
                <table className=" divide-y divide-gray-200 border border-gray-300">
                  <tbody className="w-10/12 bg-white divide-y divide-black">
                    <tr>
                      <td className="px-3 py-2 flex-wrap text-sm font-medium text-white bg-lightenblue"> Address </td>
                      <td className="px-3 flex flex-wrap py-2 text-sm font-semibold text-black"> {data.address} </td>
                    </tr>

                    <tr>
                      <td className="px-3 py-2 whitespace-nowrap text-sm font-medium text-white bg-lightenblue"> Phone Number </td>
                      <td className="px-3 py-2 flex-wrap text-sm font-semibold text-black">{data.phoneNumber}</td>
                    </tr>

                    <tr>
                      <td className="px-3 py-2 whitespace-nowrap text-sm font-medium text-white bg-lightenblue"> Total Courses </td>
                      <td className="px-3 py-2 flex-wrap text-sm font-semibold text-black"> {products && products.length} </td>
                    </tr>

                    <tr>
                      <td className="px-3 py-2 whitespace-nowrap text-sm font-medium text-white bg-lightenblue">Staff Ratings</td>
                      <td className="px-3 py-2 flex-wrap text-sm font-semibold  text-black">{averageRating}/5</td>
                    </tr>

                    <tr>
                      <td className="px-3 py-2 whitespace-nowrap text-sm font-medium text-white bg-lightenblue">Joined On</td>
                      <td className="px-3 py-2 flex-wrap text-sm font-semibold text-black">{data?.createdAt?.slice(0, 10)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {isOwner && (
                <div className="py-3 px-4 gap-2 flex justify-between">
                  <Link to="/settings">
                    <div className={`${styles.button4} !h-[42px] !bg-deepblue !rounded-[5px]`}>
                      <span className="text-white">Edit Staff Profile</span>
                    </div>
                  </Link>
                  <div className={`${styles.button4} !bg-red-900 !h-[42px] !rounded-[5px]`}
                    onClick={logoutHandler}
                  >
                    <span className="text-white">Log Out</span>
                  </div>
                </div>
              )}
            </div>
          </div >
        )
      }
    </>
  );
};

export default ShopInfo;
