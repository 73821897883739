import React from "react";
import { Link } from "react-router-dom";
import formatNumber from "../../../utils/formatNumber";


const ProductCard = ({ data, isEvent }) => {


  return (
    <>
      <div className="w-full h-[250px] md:w-[227px] lg:w-[270px] flex flex-col md:flex-none  md:h-[300px] rounded-lg shadow-sm p-3 relative cursor-pointer">
        {/* <div className="flex justify-end"></div> */}
        <Link className="bg-deepblue" to={`${isEvent === true ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
          <img
            src={`${data.images1 && data.images1[0]?.url}`}
            alt=""
            className="w-full h-[155px] md:h-[170px] lg:h-[200px] object-fill"
          />
        </Link>
        <Link to={`${isEvent === true ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
          <div className="px-1 pb-3 text-center bg-gray-900 border-t-2 border-white md:px-2">
            <h4 className="text-xs font-semibold pb-0 mt-4  text-white ">
              {data.name.length > 30 ? data.name.slice(0, 30) + "..." : data.name}
            </h4>
          </div>

          {/* <div className="lg:grid hidden md:block items-center justify-between py-2 grid-cols sm:flex w-full"> */}
          <div className=" hidden md:block items-center justify-between w-full">
            <div className="flex bg-green1 w-full">
              <h5 className="flex w-full bg-deepblue items-center justify-between text-white border-b-2 border-deepblue">
                {/* <span className="font-[600] text-white text-sm bg-deepblue w-full p-1">Course Fee </span> */}
                {/* <span className="font-[600] text-deepblue text-sm bg-white w-full p-1">
                  {"$" + formatNumber(data.originalPrice)}
                </span> */}

                {/* <h5 className="flex font-[500]  text-sm">
                  {data.originalPrice === 0
                    ? "$" + data.originalPrice
                    : "$" + data.discountPrice}

                </h5> */}
                {/* <h4 className="font-[500]  text-sm">
                  {data.originalPrice ? "$" + data.originalPrice : null}

                </h4> */}

                <span className="font-[600] text-white text-sm bg-deepblue w-full p-1">Course Fee </span>
                <span className="flex font-[600] text-deepblue justify-end text-sm bg-white w-full p-1">
                  {formatNumber(data.originalPrice)}
                </span>

              </h5>
            </div>

          </div>
        </Link >
      </div >
    </>
  );
};

export default ProductCard;
