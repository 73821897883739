import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/styles";
import ProductCard from "../ProductCard/ProductCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FeaturedProduct = () => {
  const { allProducts } = useSelector((state) => state.products);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 20;

  // Filter featured products
  const featuredProducts = allProducts?.filter(
    // (product) => product.tags === "Home Section"
    (product) => product.tags === "Home Section"

  );

  // Return null if no featured products are found
  if (!featuredProducts || featuredProducts.length === 0) {
    return null;
  }

  // Calculate the indices for slicing the products array
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = featuredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  // Calculate total pages
  const totalPages = Math.ceil(featuredProducts.length / productsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <div className={`${styles.section}`}>
        <div
          className={`${styles.heading2} !bg-deepblue flex items-center justify-between pr-3`}
        >
       
          <h1 className="">All Available Courses</h1>
         
        </div>
        <div className="flex w-full flex-wrap mb-12 border-0 md:gap-x-[10px] lg:gap-x-[21px] pl-2 md:pl-0">
          {currentProducts.map((product) => (
            <ProductCard data={product} key={product._id} />
          ))}
        </div>
        {/* Pagination */}
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`px-3 py-1 mx-1 rounded ${currentPage === index + 1 ? "bg-blue-500 text-white" : "bg-gray-300"
                }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedProduct;
