import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import iimb from '../../images/iimb.jpg'

const navigation = {
  about: [
    { name: 'Our Team', to: '/aboutus' },
    { name: 'Contact Us', to: '/contactus' },
    { name: 'Programs', to: '#' },
    { name: 'Courses', to: '#' },
  ],
  application: [
    { name: 'Get acess to video', to: '#' },
    { name: 'Certification Preparation', to: '#' },
    { name: 'Get One on One Mentorship', to: '#' },
    { name: 'Attend Live Class', to: '#' },
  ],

  legal: [
    { name: 'Privacy', to: '/privacy' },
    { name: 'Terms', to: '#' },
    { name: 'Blogs', to: '#' },
    { name: 'Location', to: '/location' },

  ],
  social: [
    {
      name: 'Facebook',
      to: 'https://www.facebook.com/share/r/a6WReyvKCBjaYi9M/?mibextid=oFDknk',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      to: 'https://www.instagram.com/wumbis_concept?igsh=ZHFlNmptcTkwNGl2',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'X',
      to: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
      ),
    },
    {
      name: 'TikTok',
      to: 'https://www.tiktok.com/@wumbis.concept?_t=8oGu8eQ3ZjC&_r=1',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M9.95 2v13.717a2.665 2.665 0 1 1-1.207-2.224V9.02a5.27 5.27 0 1 0 3.91 5.1V8.398a6.96 6.96 0 0 0 4.7 1.847V7.314a4.32 4.32 0 0 1-2.938-1.152 4.158 4.158 0 0 1-1.52-3.003H11.9V2H9.95Z" />
        </svg>
      ),
    },
    {
      name: 'whatsapp',
      to: 'https://wa.me/c/13069107944',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" className="w-6 h-6 text-green-500" {...props}>
          <path d="M17.472 14.382c-.297-.148-1.758-.867-2.031-.968-.273-.099-.471-.148-.67.15-.197.297-.768.965-.941 1.162-.173.198-.346.223-.642.075-.297-.148-1.255-.462-2.39-1.475-.883-.787-1.48-1.76-1.654-2.057-.173-.297-.018-.458.13-.605.134-.134.297-.347.446-.521.148-.173.198-.297.297-.495.099-.198.05-.371-.025-.521-.075-.148-.67-1.612-.917-2.205-.242-.577-.487-.497-.67-.497-.173-.012-.371-.012-.57-.012s-.52.074-.791.372c-.272.297-1.042 1.017-1.042 2.482 0 1.465 1.067 2.879 1.216 3.075.148.198 2.1 3.209 5.081 4.498.71.307 1.262.49 1.694.626.712.227 1.36.195 1.87.118.57-.085 1.758-.718 2.006-1.41.248-.692.248-1.284.173-1.41-.074-.123-.272-.198-.57-.347m-5.474 7.617h-.01C7.66 21.988 4.243 19.647 2.57 16.1.898 12.552 1.741 8.423 4.564 5.618 6.514 3.667 9.257 2.55 12.118 2.55c2.424 0 4.737.947 6.462 2.673 1.725 1.726 2.675 4.037 2.68 6.454.005 3.62-1.91 6.92-4.993 8.662-.814.451-1.717.797-2.662.924-.897.119-1.785.145-2.707-.264z" />
        </svg>
      ),
    },
  ],
}



const Footer = () => {

  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your newsletter signup logic here (e.g., sending a request to your backend)
  };


  return (
    <footer className="footerContainer" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-full px-6 pt-10 pb-8 mx-auto lg:pb-4 md:pt-16 lg:px-16 xl:px-32 lg:pt-16">

        <div className="flex w-full">
          <div className="grid justify-between w-full grid-cols-1 md:grid-cols-7">

            <div className="md:col-span-3 md:mr-5">
              <div className="mt-0 sm:flex-shrink-0 md:mt-2">
                <h3 className="text-lg font-bold leading-6 text-white md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">New to Wumbisconcept?</h3>
                <p className="mt-2 text-sm leading-4 text-white xl:text-base">Subscribe to our newsletter to get updates on our latest offers</p>
              </div>
              <div className="items-center pb-4 mt-4 sm:flex justify-evenly xl:mt-10 lg:mt-10 xl:pb-0 lg:pb-0">
                <div className="w-full">
                  <form onSubmit={handleSubmit} className='items-center justify-center w-full gap-3 lg:flex'>
                    <div className='flex-grow w-full overflow-hidden lg:flex lg:px-0'>
                      <div className='flex items-center w-full p-2 pb-0 bg-white justify-evenly lg:w-44 lg:px-1 lg:py-0 xl:px-2 xl:py-1 xl:w-52 2xl:w-64 '>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6 2xl:ml-[5px] 2xl:h-10 2xl:w-10 ' viewBox="0 0 512 512" fill='#0000CF'>
                            <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 
                                            64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                          </svg>
                        </span>

                        <input
                          type="email"
                          className="w-full p-2 text-sm font-medium leading-none text-gray-600 placeholder-gray-600 bg-gray-100 focus:outline-none lg:py-1 lg:px-1 lg:w-36 2xl:py-3 2xl:px-2 2xl:w-60 2xl:ml-0 "
                          placeholder="Enter your email address here"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className='bg-white'>
                        <button
                          type='submit'
                          className="w-full px-6 py-4 mt-2 text-base font-semibold leading-none text-white focus:outline-none hover:bg-lightenblue hover:text-black bg-deepblue lg:mt-0 lg:ml-0 lg:w-24 lg:text-sm lg:py-2 lg:px-2 xl:mt-0 2xl:py-4 2xl:px-2 2xl:w-28 ">SUBSCRIBE</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="mt-10 md:mt-0 md:col-span-2 md:mr-5">
              <h3 className="footerh3">Application</h3>
              <ul className="mt-2 space-y-1 lg:mt-4 md:space-y-2">
                {navigation.application.map((item) => (
                  <li key={item.name}>
                    <Link to={item.to} className="footerLink">
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-10 md:mt-0 md:mr-5">
              <h3 className="footerh3">About Us</h3>
              <ul className="mt-2 space-y-1 lg:mt-4 md:space-y-2">
                {navigation.about.map((item) => (
                  <li key={item.name}>
                    <Link to={item.to} className="footerLink">
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-10 md:mt-0">
              <h3 className="footerh3">Legal</h3>
              <ul className="mt-2 space-y-1 lg:mt-4 md:space-y-2">
                {navigation.legal.map((item) => (
                  <li key={item.name}>
                    <Link to={item.to} className="footerLink">
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>


          </div>

        </div>

        {/* <div className="pt-8 mt-8 border-t border-white/10 md:flex md:items-center md:justify-between lg:mt-8"> */}
        <div className="md:flex md:items-center md:justify-between pt-8 mt-8 border-t border-white/10 lg:mt-8">

          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <Link key={item.name} to={item.to} className="text-gray-500 hover:text-gray-400">
                <span className="sr-only">{item.name}</span>
                <item.icon className="w-6 h-6" aria-hidden="true" />
              </Link>
            ))}
          </div>

          <div className="mt-6 md:mt-0">
            <img src={iimb} alt="Logo"
              className=" md:mx-auto h-16 md:h-16"
            />
          </div>

          {/* <div className='max-w-xs mx-auto'>
              <img src={iimb} alt="Logo"
                className="block h-auto max-w-full mx-auto"
              />
            </div> 
            */}

          <p className="mt-8 text-base leading-5 text-white md:order-1 md:mt-0">
            &copy; {new Date().getFullYear()} wumbisconcept. All rights reserved
          </p>



        </div>
      </div>
    </footer>
  );
}

export default Footer;
