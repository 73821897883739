import React from 'react'

const ExistingProductHeading = () => {
  return (
      <div className="w-full grid grid-rows bg-ping-600 mt-8 md:mt-12 lg:mt-16 px-6 md:px-10 lg:px-4 xl:px-[70px] gap-y-2">
          <h2 className="md:text-2xl lg:text-3xl xl:text-3xl text-lg font-bold leading-7 text-deepblue text-start">Explore Our Existing Programs</h2>
          <p className=" md:text-base md:font-medium lg:text-base lg:font-medium leading-5 md:text-start text-justify text-black mt-0 md:mt-2 md:w-12/12 lg:w-10/12 xl:w-10/12">
              Programs have been designed to ensure they bridge the conventional training gaps - thereby equipping you with all you need to succeed.
          </p>
      </div>
  )
}

export default ExistingProductHeading
