import React from 'react'
import { Link } from 'react-router-dom'
import { navItems } from '../../static/data'

const Navbar = ({active}) => {
  return (
    // <div className={`block 800px:${styles.noramlFlex2} bg-deepblue md:bg-yellow-800 rounded-xl py-2 items-center justify-between`}>
    <div className={`flex flex-col md:flex-row md:w-[500px] lg:w-[600px] rounded-xl py-2 items-start md:items-center justify-between md:gap-0 gap-1 md:px-3 lg:px-6`}>
         {
            navItems && navItems.map((i,index) => (
              <div className="w-full md:w-auto text-white bg-deepblue active:bg-darkblue hover:bg-darkblue" key={index} >
                <Link to={i.url}
                  // className={`${active === index + 1 ? " text-white bg-darkblue" : "text-black  800px:text-[#fff]"}  py-3 pb-[10px]  flex 800px:pb-[8px] font-[500] px-2 cursor-pointer text-white  }`}
                  className={" py-1 w-full items-center justify-center rounded-md  flex font-[500] px-2 cursor-pointer text-white md:bg-deepblue active:bg-darkblue hover:bg-darkblue "}
                    >
                    {i.title}
                    </Link>
                </div>
            ))
         }
    </div>
  )
}

export default Navbar