import React from 'react';
import CourseBanner from '../components/CourseBanner';
import banner from '../images/banner.png';
import Container from '../components/Container';
import Heading from '../components/Heading';

import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Testimony from './Route/Testimony/Testimony';




const OurTestimony = () => {
    return (
        <div>
            <Header />

            <CourseBanner imageUrl={banner}>
                <h1 className="font-bold">OUR TESTIMONIAL PAGE</h1>
                {/* <p className="mt-2 text-lg md:text-xl lg:text-2xl xl:text-3xl">Your banner subheading or description goes here.</p> */}
            </CourseBanner>
            <div className="px-0 py-0 mb-6 lg:py-4 lg:mt-4 lg:mb-0 lg:px-0">

                <Container>
                    <Heading left title="Our Testimonial" />
                    <div className="p-2">
                        <Testimony />
                    </div>

                </Container>

            </div>

            <Footer />
        </div>
    );
}

export default OurTestimony
