import React, { useState } from "react";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useParams, useNavigate, Link } from "react-router-dom";
import Meta from "../Meta";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";

const Resetpassword = () => {

    const { token } = useParams();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        await axios
            .put(`${server}/user/user-password-reset/${token}`, {
                password,
                confirmPassword,
            })
            .then((res) => {
                toast.success("Password updated successfully");
                navigate("/login");
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };


    return (
        <>
            <Header />
            <Meta title={"Reset Password Page"} />
            <div className="flex flex-col justify-center min-h-screen bg-center bg-cover" style={{ backgroundImage: `url('../images/background.jpg')` }}>
                <div className="flex flex-col justify-center flex-1 min-h-screen px-4 py-12 bg-black bg-opacity-80 sm:px-6 lg:px-8">
                    <div className="mt-0 sm:mx-auto sm:w-full sm:max-w-[480px] bg-transparent py-2 rounded-lg">
                        <img
                            className="w-auto h-16 mx-auto md:h-28 "
                            src="../images/logo.png"
                            alt="Your Company"
                        />

                        <h2 className="mt-0 text-lg font-semibold leading-9 tracking-tight text-center text-white md:mt-2 md:text-2xl">
                            Reset User Password
                        </h2>

                        <h6 className="mt-0 mb-2 text-sm font-medium tracking-tight text-center text-white md:mt-1">
                            We will send you an email to reset your password
                        </h6>

                        <div className="px-6 py-8 shadow bg-deepblue sm:rounded-lg sm:px-12">
                            <form className="space-y-6" onSubmit={handleSubmit}>
                                <div>
                                    <div className="mt-2">
                                        <input
                                            type="password"
                                            placeholder="New Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                            className="registerInput"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="mt-2">
                                        <input
                                            type="password"
                                            placeholder="Confirm Password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                            className="registerInput"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        className="flex w-full justify-center bg-lightenblue hover:bg-gray-900 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Change Password
                                    </button>
                                </div>
                            </form>

                        </div>

                        <p className="mt-6 mb-4 text-sm font-semibold text-center text-black">
                            <Link to="/login" className="px-4 py-2 font-semibold leading-6 text-white bg-red-500 hover:text-white hover:bg-black">
                                Cancel Password Reset
                            </Link>
                        </p>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default Resetpassword
