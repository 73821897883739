import React from 'react';

const Heading = ({ title, left }) => {
    return (

        <div className={left ? "text-start ml-0 md:ml-8 lg:ml-0 mb-4 mt-4 md:mt-8 md:mb-6 lg:mt-8 xl:mt-0 xl:mb-0 text-black bg-white" : "text-center px-4 py-3 md:py-6 w-full bg-deepblue text-white rounded-xl "
        }>
            <h1 className='font-bold text-lg md:text-2xl lg:text-2xl xl:text-3xl  leading-7 font-sans'>{title} </h1>
        </div>
    );
}


export default Heading;

