import React from 'react'
import Container from '../components/Container';
import Meta from '../components/Meta';
import { Link } from 'react-router-dom';
import { MdKeyboardBackspace } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io5";
import CourseBanner from '../components/CourseBanner';
import banner from '../images/banner.png';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';

const posts = [
    {
        id: 1,
        title: 'Boost your conversion rate',
        href: '#',
        description:
            'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        imageUrl:
            'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
        date: 'Mar 16, 2020',
        datetime: '2020-03-16',
        category: { title: '', href: '#' },
        author: {
            name: 'Michael Foster',
            role: 'Co-Founder / CTO',
            href: '#',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },


    // More posts...
]

const SingleBlog = () => {
    return (
        <>
            <Header />
            <CourseBanner imageUrl={banner}>
                <h1 className="font-bold">BLOG PAGE</h1>
                {/* <p className="mt-2 text-lg md:text-xl lg:text-2xl xl:text-3xl">Your banner subheading or description goes here.</p> */}
            </CourseBanner>
            <Meta title={"Single Blog"} />
            <Container>
                {/* <BreadCrumb title="Single Blog" /> */}
                {/* container wrapper that will contain all the two sections goes here*/}
                <div className="grid grid-cols-1 md:flex h-auto gap-5 p-2 my-5 bg-white w-12/12">

                    {/* Our Store Aside Dive Goes here */}
                    <div className="hidden md:block flex-col w-3/12 md:w-4/12 lg:w-4/12 xl:w-3/12 gap-6 p-2 bg-transparent asideDiv">
                        {/* shop by category section goes here */}
                        <div className="flex flex-col w-full p-0 overflow-hidden rounded-md shopbycategoryDiv bg-deepblue">
                            <div className='items-center w-full px-4 py-2 text-white bg-transparent border-b-2 border-gray-300 shopbycategoryHeading'>
                                <h4 className="font-bold "> OUR QUICK ACCESS LINK</h4>
                            </div>
                            <div className='items-center w-full px-4 py-2 text-black shopbycategoryList bg-lightenblue'>
                                <ul className='pb-4 text-decoration-0'>
                                    <li> <Link to="/" className="mt-2 font-semibold"> Our Team </Link> </li>
                                    <li> <Link to="/" className="mt-2 font-semibold"> Courses </Link> </li>
                                    <li> <Link to="/" className="mt-2 font-semibold"> Blogs </Link> </li>
                                    <li> <Link to="/enrol" className="mt-2 font-semibold"> Register Now </Link> </li>
                                </ul>
                            </div>
                        </div>
                        {/*  shop by category section ends here */}


                    </div>
                    {/* Our Store aside div ends here */}


                    {/* Our Store main div goes here */}
                    <div className=" w-full md:w-8/12 lg:w-8/12 xl:w-9/12 px-2 py-2 pb-8 md:pb-12 lg:pb-12 lg:px-2 lg:py-2 overflow-hidden bg-white rounded-md mainDiv">

                        {/* Product List Section Goes Here */}
                        {posts.map((post) => (
                            <article key={post.id} className="flex flex-col items-start justify-between">
                                <div className="relative group">
                                    <h3 className="mt-3 text-lg md:text-xl font-semibold leading-6 text-black group-hover:text-lightenblue">
                                        <a href={post.href}>
                                            <span className="absolute inset-0" />
                                            {post.title.toUpperCase()}
                                        </a>
                                    </h3>
                                </div>
                                <div className="relative w-full mt-4">
                                    <img
                                        src={post.imageUrl}
                                        alt=""
                                        className="aspect-[16/9] h-96 w-full rounded-lg bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                    />
                                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                </div>
                                <div className="w-full">
                                    <div className="flex items-center mt-8 text-xs font-semibold gap-x-4 ">
                                        <time dateTime={post.datetime} className="text-black">
                                            {post.date}
                                        </time>
                                        {/* <a
                                            href={post.category.href}
                                            className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-black hover:bg-gray-100"
                                        >
                                            {post.category.title}
                                        </a> */}
                                    </div>
                                    <div className="relative w-full group">
                                        <p className="mt-5 text-sm leading-6 text-justify text-gray-600">{post.description}</p>
                                    </div>

                                    <div className="relative group">
                                        <p className="mt-2 text-sm font-semibold leading-6 text-justify text-gray-600 line-clamp-3">{post.author.name}</p>
                                    </div>
                                </div>

                                {/* Form Section for blog goes here */}
                                <div className="w-full">

                                    <div className="relative flex items-center justify-between mt-8">
                                        <Link to=""
                                            type="button"
                                            className=" flex gap-2 items-center rounded-full bg-deepblue px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green1"
                                        >
                                            <MdKeyboardBackspace />

                                            Read More
                                        </Link>

                                        <div className="flex items-center justify-between gap-2 mr-2" >

                                            <Link to="">
                                                <FaFacebook className="text-2xl text-deepblue" />
                                            </Link>

                                            <Link to="">
                                                <FaTwitter className="text-2xl text-deepblue" />
                                            </Link>

                                            <Link to="">
                                                <FaSquareInstagram className="text-2xl text-deepblue" />
                                            </Link>

                                            <Link to="">
                                                <IoLogoWhatsapp className="text-2xl text-deepblue" />
                                            </Link>

                                        </div>
                                    </div>

                                    <form action="#" method="POST" className="py-3 pb-6 mt-4 rounded-lg lg:flex-auto bg-lightenblue md:px-4">
                                        <div className="mt-2">
                                            <h4 className="ml-2 font-semibold text-white">
                                                Leave A Comment
                                            </h4>
                                        </div>
                                        <div className="grid grid-cols-1 px-2 mt-3 gap-x-6 gap-y-6 sm:grid-cols-2 bg-lightenblue">

                                            <div>
                                                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-white">
                                                    Name
                                                </label>

                                                <div className="mt-2.5">

                                                    <input
                                                        type="text"
                                                        name="first-name"
                                                        id="first-name"
                                                        autoComplete="given-name"
                                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-white">
                                                    Email
                                                </label>
                                                <div className="mt-2.5">
                                                    <input
                                                        id="email"
                                                        name="email"
                                                        type="email"
                                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>


                                            <div className="sm:col-span-2">
                                                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-white">
                                                    Comment
                                                </label>
                                                <div className="mt-2.5">
                                                    <textarea
                                                        id="comment"
                                                        name="comment"
                                                        placeholder='comment'
                                                        rows={4}
                                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        defaultValue={''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pl-2 mt-10">
                                            <button
                                                type="submit"
                                                className="rounded-full bg-deepblue px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green3"
                                            >
                                                Post a comment
                                            </button>
                                        </div>
                                    </form>

                                </div>
                                {/* Form Section for blog ends here */}
                            </article>
                        ))}
                        {/* Product List Section Ends here */}

                    </div>
                    {/* Our Store main div ends here */}
                </div>
                {/* container wrapper that will contain all the two sections ends here*/}
            </Container>
            <Footer />
        </>
    )
}

export default SingleBlog