import axios from "axios";
import { server } from "../../server";

// load user
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({
      type: "LoadUserRequest",
    });
    const { data } = await axios.get(`${server}/user/getuser`, {
      withCredentials: true,
    });
    dispatch({
      type: "LoadUserSuccess",
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: "LoadUserFail",
      payload: error.response.data.message,
    });
  }
};

// user update information
export const updateUserInformation =
  (name, email, phoneNumber, password) => async (dispatch) => {
    try {
      dispatch({
        type: "updateUserInfoRequest",
      });

      const { data } = await axios.put(
        `${server}/user/update-user-info`,
        {
          email,
          password,
          phoneNumber,
          name,
        },
        {
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Credentials": true,
          },
        }
      );

      dispatch({
        type: "updateUserInfoSuccess",
        payload: data.user,
      });
    } catch (error) {
      dispatch({
        type: "updateUserInfoFailed",
        payload: error.response.data.message,
      });
    }
  };

// update user address
export const updatUserAddress =
  (country, city, address1, address2, zipCode, addressType) =>
    async (dispatch) => {
      try {
        dispatch({
          type: "updateUserAddressRequest",
        });

        const { data } = await axios.put(
          `${server}/user/update-user-addresses`,
          {
            country,
            city,
            address1,
            address2,
            zipCode,
            addressType,
          },
          { withCredentials: true }
        );

        dispatch({
          type: "updateUserAddressSuccess",
          payload: {
            successMessage: "User address updated succesfully!",
            user: data.user,
          },
        });
      } catch (error) {
        dispatch({
          type: "updateUserAddressFailed",
          payload: error.response.data.message,
        });
      }
    };

// delete user address
export const deleteUserAddress = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteUserAddressRequest",
    });

    const { data } = await axios.delete(
      `${server}/user/delete-user-address/${id}`,
      { withCredentials: true }
    );

    dispatch({
      type: "deleteUserAddressSuccess",
      payload: {
        successMessage: "User deleted successfully!",
        user: data.user,
      },
    });
  } catch (error) {
    dispatch({
      type: "deleteUserAddressFailed",
      payload: error.response.data.message,
    });
  }
};

// get all users --- admin
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllUsersRequest",
    });

    const { data } = await axios.get(`${server}/user/admin-all-users`, {
      withCredentials: true,
    });

    dispatch({
      type: "getAllUsersSuccess",
      payload: data.users,
    });
  } catch (error) {
    dispatch({
      type: "getAllUsersFailed",
      payload: error.response.data.message,
    });
  }
};


// Block user
export const blockUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: "BlockUserRequest" });

    const { data } = await axios.put(`${server}/user/block-user/${id}`, null, {
      withCredentials: true,
    });

    dispatch({ type: "BlockUserSuccess", payload: data.message });
  } catch (error) {
    dispatch({
      type: "BlockUserFail",
      payload: error.response.data.message,
    });
  }
};

// Unblock user
export const unblockUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: "UnblockUserRequest" });

    const { data } = await axios.put(`${server}/user/unblock-user/${id}`, null, {
      withCredentials: true,
    });

    dispatch({ type: "UnblockUserSuccess", payload: data.message });
  } catch (error) {
    dispatch({
      type: "UnblockUserFail",
      payload: error.response.data.message,
    });
  }
};

// Change user role
export const changeUserRole = (id, role) => async (dispatch) => {
  try {
    dispatch({ type: "ChangeUserRoleRequest" });

    const { data } = await axios.put(
      `${server}/user/update-role/${id}`,
      { role },
      {
        withCredentials: true,
      }
    );

    dispatch({ type: "ChangeUserRoleSuccess", payload: data.message });
  } catch (error) {
    dispatch({
      type: "ChangeUserRoleFail",
      payload: error.response.data.message,
    });
  }
};
