import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";

const OrderSuccessPage = () => {
  return (
    <div>
      <Header />
      <Success />
      <Footer />
    </div>
  );
};

const Success = () => {
  return (
    <div>
      <h5 className="text-center mt-24 lg:mt-36 mb-2 text-[25px] font-semibold font-600 text-[#000000a1]">
        Your Course Have Been <span className="font-bold text-black"> Successfully Registered </span>  With  Wumbis Concept Inc
      </h5>
      <h6 className="text-center mb-14 text-[15px] font-semibold font-600 text-black">
        Check your email for confirmation
      </h6>
      <br />
      <br />
    </div>
  );
};

export default OrderSuccessPage;
