// BrandPage.jsx
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { getProductsByBrand } from "../redux/actions/product"; // Create this action
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";

const BrandPage = () => {
    const { id } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const { products, isLoading, error } = useSelector((state) => state.products);
    const brandName = location.state?.brandName || "Brand";

    // console.log(products)
    useEffect(() => {
        dispatch(getProductsByBrand(id));
    }, [dispatch, id]);


    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (

        <div>
            <Header activeHeading={1} />
            <div className={`${styles.section}`}>
                <div className={`${styles.heading2} flex items-center justify-start pr-3`}>
                    <h1>{brandName}</h1>
                </div>
                {/* <div className="mb-12 border-0 gap-x-[23px] mt-4 flex"> */}
                <div className="grid grid-cols-2 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12 border-0">

                    {/* {
                        products && products?.map((product) => {
                            return (
                                <ProductCard key={product._id} product={product} />

                            );
                        })
                    }  */}

                    {
                        products && products.length > 0 ? (
                            products.map((product) => (
                                <ProductCard key={product._id} data={product} />
                            ))
                        ) : (
                            <div className="flex items-center justify-center text-black w-full h-20 font-bold text-2xl">No course available</div>
                        )
                    }

                </div>
            </div>
            <Footer />
        </div>
    );
};

export default BrandPage;
