import React, { useState } from 'react';
import ShopInfo from "../../components/Shop/ShopInfo";
import ShopProfileData from "../../components/Shop/ShopProfileData";
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader';

const ShopHomePage = () => {
  const [isShopInfoVisible, setIsShopInfoVisible] = useState(false);

  const toggleShopInfo = () => {
    setIsShopInfoVisible(!isShopInfoVisible);
  };

  return (
    <div>
      <DashboardHeader />
      <div className="relative flex w-full">
        {/* Toggle Button for Small Screens */}
        <button
          className="md:hidden direction-rtl rotate-90 bg-red-600 text-white absolute top-20 left-[-55px] transform -translate-y-1/2 z-50 px-2"
          onClick={toggleShopInfo}
        >
          Show my profile
        </button>

        {/* Shop Info (Visible on Small Screens only if toggled, always visible on md: and above) */}
        {(isShopInfoVisible || window.innerWidth >= 768) && (
          <div className={`w-full 800px:w-[330px] shopInfo ${window.innerWidth < 768 ? '' : 'block'}`}>
            <ShopInfo isOwner={true} />
          </div>
        )}

        <div className="w-full md:max-w-[84%] md:w-full justify-center flex Pro-data">
          <ShopProfileData isOwner={true} />
        </div>
      </div>
    </div>
  );
};

export default ShopHomePage;
