import React, { useState } from "react";
import {
  AiOutlineArrowRight,
  AiOutlineCamera,
  AiOutlineDelete,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../server";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import {
  deleteUserAddress,
  loadUser,
  updatUserAddress,
  updateUserInformation,
} from "../../redux/actions/user";
import { Country, State } from "country-state-city";
import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { getAllOrdersOfUser } from "../../redux/actions/order";

const ProfileContent = ({ active }) => {
  const { user, error, successMessage } = useSelector((state) => state.user);
  // console.log(user)
  const [name, setName] = useState(user && user.name);
  const [email, setEmail] = useState(user && user.email);
  const [phoneNumber, setPhoneNumber] = useState(user && user.phoneNumber);
  const [password, setPassword] = useState("");
  const [avatar , setAvatar] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
  }, [dispatch, error, successMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserInformation(name, email, phoneNumber, password));
  };

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(
            `${server}/user/update-avatar`,
            { avatar: reader.result },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            dispatch(loadUser());
            toast.success("avatar updated successfully!");
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className="w-full p-3 bg-lightenblue md:overscroll-y-none">
      <div className="w-full h-full pt-4 pb-4 overflow-hidden bg-white rounded-xl md:pt-8 md:pb-0">
        {/* profile */}
        {active === 1 && (
          <>
            <div className="flex justify-center w-full">
              <div className="relative">
                <img
                  src={`${user?.avatar?.url}`}
                  className="w-[90px] h-[90px] md:w-[150px] md:h-[150px] rounded-full object-cover border-[3px] border-lightenblue"
                  alt=""
                />
                <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[5px] right-[5px]">
                  <input
                    type="file"
                    id="image"
                    className="hidden"
                    onChange={handleImage}
                  />
                  <label htmlFor="image">
                    <AiOutlineCamera />
                  </label>
                </div>
              </div>
            </div>

            <div className="w-full px-5 md:mt-8">
              <form onSubmit={handleSubmit}>
                <div className="block w-full pb-0 md:pb-3 800px:flex">

                  <div className=" w-[100%] 800px:w-[50%] mt-1 md:mt-0">
                    <label className="block pb-1 font-medium md:pb-2 md:font-semibold text-lightenblue">Full Name</label>
                    <input
                      type="text"
                      className={`${styles.input} !w-[95%] mb-1 md:mb-4 800px:mb-0 border-1 border-lightenblue`}
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  {/* Full Name Section Ends Here */}

                  {/* Email Address Section Goes Here */}
                  <div className=" w-[100%] 800px:w-[50%] mt-1 md:mt-0">
                    <label className="block pb-1 font-medium md:pb-2 md:font-semibold text-lightenblue">Email Address</label>
                    <input
                      type="text"
                      className={`${styles.input} !w-[95%] mb-1 md:mb-4 800px:mb-0 border-1 border-lightenblue`}
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                {/* Email Address Section Ends Here */}

                <div className="block w-full pb-0 mt-2 md:mt-0 md:pb-3 800px:flex">
                  {/* Phone Number Section Goes Here */}
                  <div className=" w-[100%]  800px:w-[50%] mt-1 md:mt-0">
                    <label className="block pb-1 font-medium md:pb-2 md:font-semibold text-lightenblue">Phone Number</label>
                    <input
                      type="number"
                      className={`${styles.input} !w-[95%] mb-1 md:mb-4 800px:mb-0 border-1 border-lightenblue`}
                      required
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  {/* Phone Number Section Goes Here */}

                  {/* Enter Password Section Goes Here */}
                  <div className="w-[100%] 800px:w-[50%] mt-1 md:mt-0">
                    <label className="block pb-2 ffont-medium md:font-semibold text-lightenblue">Enter your password</label>
                    <input
                      type="password"
                      className={`${styles.input} !w-[95%] mb-4 800px:mb-0 border-1 border-lightenblue`}
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  {/* Enter Password Section Ends Here */}

                </div>

                {/* Update Profile Submit Button Section Goes Here */}
                <input
                  className={`w-[265px] md:w-[250px] h-[40px] border bg-darkblue font-bold text-center text-white rounded-[3px] mt-0 md:mt-8 cursor-pointer`}
                  required
                  value="Update Profile"
                  type="submit"
                />
                {/* Update Profile Submit Button Section Ends Here  */}


              </form>
            </div>
          </>
        )}
        {/* Profile Section Ends here */}

        {/* order */}
        {active === 2 && (
          <div>
            <AllOrders />
          </div>
        )}
        {/* Order Section Ends here */}


        {/* Change Password */}
        {active === 6 && (
          <div>
            <ChangePassword />
          </div>
        )}
        {/* Change Password Section ends here */}


        {/*  user Address */}
        {active === 7 && (
          <div>
            <Address />
          </div>
        )}
        {/* user address section ends here */}
      </div>

    </div>
  );
};

const AllOrders = () => {
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7; // Set items per page

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  // Calculate total pages
  const totalPages = Math.ceil((orders?.length || 0) / itemsPerPage);

  // Get the current orders to display based on pagination
  const currentOrders = orders?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  ) || [];

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    { field: 'userName', headerName: 'Registration Name' },
    { field: 'RegistrationEmail', headerName: 'Registration Email' },
    { field: 'RegistrationNumber', headerName: 'Mobile Number' },
    { field: 'total', headerName: 'Course Fee' },
  ];

  const rows = currentOrders.map((item) => ({
    id: item._id,
    userName: item.user?.name,
    RegistrationEmail: item.user?.email,
    RegistrationNumber: item.user?.phoneNumber,
    total: '$' + item.totalPrice,
  }));

  return (
    <div className="w-full px-4 pt-0 bg-white md:px-8 md:pr-5">
      <div className="flex items-center justify-start w-full max-w-[100%] lg:max-w-[100%] 2xl:max-w-[100%] z-10 px-2 md:px-4 py-2 rounded-lg bg-darkblue">
        <h3 className="flex items-start justify-start gap-2 text-lg font-semibold text-white sm:text-xl lg:text-2xl font-Roboto">
          APPLIED COURSES
        </h3>
      </div>
      <div className="mt-2 overflow-x-auto ">
        <table className="min-w-full bg-white border border-gray-300 ">
          <thead>
            <tr className="text-left bg-gray-200">
              {columns.map((column) => (
                <th key={column.field} className="px-4 py-2 font-semibold text-gray-700">
                  {column.headerName}
                </th>
              ))}
              <th className="px-4 py-2 font-semibold text-gray-700">Actions</th>
            </tr>
          </thead>
          <tbody className="">
            {rows.map((row) => (
              <tr key={row.id} className="border-b">
                {columns.map((column) => (
                  <td key={column.field} className="px-4 py-2 text-gray-600">
                    {row[column.field]}
                  </td>
                ))}
                <td className="px-4 py-2">
                  <Link to={`/user/order/${row.id}`}>
                    <button className="text-blue-500 hover:underline">
                      <AiOutlineArrowRight size={20} />
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination Controls */}
        <div className="flex justify-center pb-10 mt-4">
          <button
            className={`px-3 py-1 mx-1 text-sm font-semibold text-white bg-blue-500 rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`px-3 py-1 mx-1 text-sm font-semibold ${currentPage === index + 1
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
                } rounded`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className={`px-3 py-1 mx-1 text-sm font-semibold text-white bg-blue-500 rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>


    </div>
  );
};




const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate()

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.success);
        toast.success("Passowrd Updated Succesfuly");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        navigate('/login')
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };
  return (
    <div className="w-full px-4 pt-0 bg-white md:px-8 md:pr-5">
      <div className="flex items-center justify-start w-full max-w-[100%] lg:max-w-[100%] 2xl:max-w-[100%] z-10 px-2 md:px-4 py-2 rounded-lg bg-darkblue">
        <h3 className="flex items-start justify-start gap-2 text-lg font-semibold text-white sm:text-xl lg:text-2xl font-Roboto">
          CHANGE PASSWORD
        </h3>
      </div>
      <div className="w-full mt-4 md:px-2">
        <form onSubmit={passwordChangeHandler}>

          <div className="flex flex-col gap-0 md:flex-row md:gap-4">
            <div className="w-full md:mt-2">
              <label className="block font-bold md:font-semibold md:text-base lg:text-md lg:font-bold text-black md:pb-2">Input Your Old Password</label>
              <input
                type="password"
                className={`${styles.input}!w-full mb-4 800px:mb-0 border-1 border-lightenblue px-2 py-2`}
                placeholder="Old Password"
                required
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>

            <div className="w-full md:mt-2">
              <label className="block font-bold md:font-semibold md:text-base lg:text-md lg:font-bold text-black md:pb-2">Input New Password</label>
              <input
                type="password"
                className={`${styles.input} !w-full mb-4 800px:mb-0 border-1 border-lightenblue px-2 py-2`}
                required
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>

            <div className="w-full md:mt-2">
              <label className="block font-bold md:font-semibold md:text-base lg:text-md lg:font-bold text-black md:pb-2">Confirm New Password</label>
              <input
                type="password"
                className={`${styles.input} !w-full mb-4 800px:mb-0 border-1 border-lightenblue px-2 py-2`}
                required
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

          </div>
          <div className="w-full md:w-[33%] lg:w-[33%]">
            <div className="mt-2 ">
              <input
                className={`w-full h-[40px] border text-center font-semibold bg-darkblue  text-white rounded-[3px] mt-8 cursor-pointer border-1 border-darkblue`}
                required
                value="Update Your Password"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const Address = () => {
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [addressType, setAddressType] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState(user && user.phoneNumber);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const addressTypeData = [
    {
      name: "Default",
    },
    {
      name: "Home",
    },
    {
      name: "Office",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (addressType === "" || country === "" || city === "") {
      toast.error("Please fill all the fields!");
    } else {
      dispatch(
        updatUserAddress(
          country,
          city,
          address1,
          address2,
          zipCode,
          addressType,
        )
      );
      setOpen(false);
      setCountry("");
      setCity("");
      setAddress1("");
      setAddress2("");
      setZipCode(null);
      setAddressType("");
    }
  };

  const handleDelete = (item) => {
    const id = item._id;
    dispatch(deleteUserAddress(id));
  };

  return (
    <div className="w-full px-5">
      {open && (
        <div className="fixed w-full h-screen bg-[#0000004b] top-0 left-0 flex items-center justify-center z-50 ">
          <div className="w-[90%] md:w-[45%] h-auto bg-lightenblue rounded-lg overflow-hidden shadow relative">
            <div className="flex justify-end w-full p-3">
              <RxCross1
                size={30}
                className="text-white bg-red-600 cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </div>
            <h1 className="text-center text-[25px] font-Poppins text-white">
              Add New Address
            </h1>
            <div className="w-full mt-3 bg-darkblue rounded-t-xl">
              <form onSubmit={handleSubmit} className="w-full">

                <div className="w-full p-4">
                  <div className="flex w-full">

                    <div className="w-full pb-2">
                      <label className="block pb-2 font-semibold text-white">Country</label>
                      <select
                        name=""
                        id=""
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        className="w-[95%] border h-[40px] rounded-[5px]"
                      >
                        <option value="" className="block pb-2 font-semibold border">
                          choose your country
                        </option>
                        {Country &&
                          Country.getAllCountries().map((item) => (
                            <option
                              className="block pb-2"
                              key={item.isoCode}
                              value={item.isoCode}
                            >
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="w-full pb-2">
                      <label className="block pb-2 text-white">Choose your City</label>
                      <select
                        name=""
                        id=""
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className="w-[95%] border h-[40px] rounded-[5px]"
                      >
                        <option value="" className="block pb-2 border ">
                          choose your city
                        </option>
                        {State &&
                          State.getStatesOfCountry(country).map((item) => (
                            <option
                              className="block pb-2"
                              key={item.isoCode}
                              value={item.isoCode}
                            >
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>

                  </div>

                  <div className="flex w-full">
                    <div className="w-full pb-2">
                      <label className="block pb-2 text-white">Address 1</label>
                      <input
                        type="address"
                        className={`${styles.input2}`}
                        required
                        value={address1}
                        placeholder="Input bulding number"
                        onChange={(e) => setAddress1(e.target.value)}
                      />
                    </div>
                    <div className="w-full pb-2">
                      <label className="block pb-2 text-white">Address 2</label>
                      <input
                        type="address"
                        className={`${styles.input2}`}
                        required
                        value={address2}
                        placeholder="Input street name"
                        onChange={(e) => setAddress2(e.target.value)}
                      />
                    </div>
                  </div>


                  <div className="flex w-full">
                    <div className="w-full pb-2">
                      <label className="block pb-2 text-white">Zip Code</label>
                      <input
                        type="number"
                        className={`${styles.input2}`}
                        required
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                      />
                    </div>

                    <div className="w-full pb-2">
                      <label className="block pb-2 text-white">Address Type</label>
                      <select
                        name=""
                        id=""
                        value={addressType}
                        onChange={(e) => setAddressType(e.target.value)}
                        className="w-[95%] border h-[40px] rounded-[5px]"
                      >
                        <option value="" className="block pb-2 border">
                          Choose your Address Type
                        </option>
                        {addressTypeData &&
                          addressTypeData.map((item) => (
                            <option
                              className="block pb-2"
                              key={item.name}
                              value={item.name}
                            >
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="flex w-full">
                    <div className="w-full pb-2 ">
                      <input
                        type="submit"
                        className={`${styles.input3} text-white mt-5 cursor-pointer bg-lightenblue py-2 font-semibold border-0 hover:bg-black`}
                        required
                        value="Add Address"
                        readOnly
                      />
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center justify-between md:px-3 md:w-full">
        <div className="flex items-center justify-between w-full max-w-[100%] lg:max-w-[100%] 2xl:max-w-[100%] z-0 px-2 md:px-4 py-2 rounded-lg bg-darkblue">

          <h3 className="flex gap-2 text-lg font-semibold text-white sm:text-xl lg:text-2xl font-Roboto">
            <h3 className="flex items-start justify-start gap-2 text-lg font-semibold text-white sm:text-xl lg:text-2xl font-Roboto">
              <span className="hidden sm:block"> CONTACT  </span> <span className="leading-tight">ADDRESS </span>
            </h3>
          </h3>

          <h3
            className={`bg-lightenblue flex px-4 py-2 items-center justify-center gap-1 font-semibold rounded-lg cursor-pointer`}
            onClick={() => setOpen(true)}
          >
            <span className="leading-tight">Add  New </span> <span className="hidden sm:block">  Address  </span>
            
          </h3>
          
        </div>

      </div>
      <br />
      {user &&
        user.addresses.map((item, index) => (
          <div
            className="w-full z-10 bg-white h-min 800px:h-[70px] rounded-[4px] flex flex-wrap items-center px-3 shadow justify-between md:pr-10 mb-5"
            key={index}
          >
            <div className="flex items-start">
              <h5 className="md:pl-5 font-Roboto font-[600]">{item.addressType}</h5>
            </div>

            <div className="flex items-start md:col-span-2 md:pl-8">
              <h6 className="text-[15px] font-Roboto 800px:text-[unset]">
                {` ${item.address1}${", "} ${item.address2} `}
              </h6>
            </div>

            <div className="flex md:pl-8 items-star">
              <h6 className="text-[15px] font-Roboto 800px:text-[unset]">
                {item.address1} {item.country}
              </h6>
            </div>

            <div className="flex items-start md:pl-8">
              <h6 className="text-[15px] font-Roboto 800px:text-[unset]">
                {`${item.address1}${", "} ${item.city}`}
              </h6>
            </div>

            <div className="flex items-start md:pl-8">
              <h6 className="font-semibold font-Roboto text-[15px] 800px:text-[unset]">
                {user && user.phoneNumber}
              </h6>
            </div>

            <div className="min-w-[10%] flex items-center justify-between md:pl-8">
              <AiOutlineDelete
                size={25}
                className="cursor-pointer"
                onClick={() => handleDelete(item)}
              />
            </div>
          </div>
        ))}

      {user && user.addresses.length === 0 && (
        <h5 className="text-center pt-8 text-[18px]">
          You do not have any saved address!
        </h5>
      )}
    </div>
  );
};
export default ProfileContent;
