import React, { useEffect } from "react";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles/styles";
import { getAllOrdersOfUser } from "../redux/actions/order";

import { FaArrowAltCircleLeft } from "react-icons/fa";

const UserOrderDetails = () => {
  const { orders } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch, user._id]);

  const data = orders && orders.find((item) => item._id === id);

 
  const goBack = () => {
    navigate(-1);
  };

  function formatTime(timeString) {
    // Split the time string into hours and minutes
    let [hours, minutes] = timeString.split(':').map(Number);

    // Determine AM or PM suffix
    let suffix = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    // Return the formatted time
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${suffix}`;
  }


  return (
    <div className={`py-4 md:pt-14 min-h-screen md:min-h-[75vh] px-4 ${styles.section} bg-white`}>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-1 md:gap-3">
          <BsFillBagFill size={30} color="#000" />
          <h1 className="md:pl-2 text-[15px]  md:text-[25px] font-semibold">Applied Course Details</h1>
        </div>
        <Link onClick={() => goBack()} >
          <div
            className={`${styles.button} flex gap-1 !bg-darkblue !rounded-[4px] text-[#fff] font-semibold !h-[32px] text-[13px]`}
          > <FaArrowAltCircleLeft />
            Applied courses list
          </div>
        </Link>
      </div>

      <div className="container mx-auto py-4 px-2 md:px-0">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-lg">
            <thead>
              <tr className="bg-gray-800 text-white">
                <th className="py-2 px-4 text-left">Course ID</th>
                <th className="py-2 px-4 text-left">Registration Date</th>
                <th className="py-2 px-4 text-left">Applied Course Title</th>
                <th className="py-2 px-4 text-left">Duration</th>
                <th className="py-2 px-4 text-left">Lecture Period</th>
                <th className="py-2 px-4 text-left">App. Fee</th>
                <th className="py-2 px-4 text-left">Contact Information</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data?.cart?.map((course) => (
                  <tr key={course.id} className="border-t border-gray-300">

                    {/* <h4 className="text-[#fff] bg-darkblue py-2">
          <span className="font-semibold pl-4 pr-[52px] py-4">Payment Status: </span>
          <span className="font-medium bg-lightenblue px-4 py-4">
            {data?.paymentInfo?.status ? data?.paymentInfo?.status : "Not Paid"}
          </span>
        </h4> */}

                    <td className="py-2 px-4">#{data?._id?.slice(0, 8)}</td>
                    <td className="py-2 px-4">{data?.createdAt?.slice(0, 10)}</td>
                    {data &&
                      data?.cart.map((item, index) => (
                        <td className="py-2 px-4 flex flex-col items-center justify-center gap-1">
                          <img
                            src={`${item.images1[0]?.url}`}
                            alt=""
                            className="w-full h-[70px]"
                          />
                          {item.name}
                          {/* {item.startDate} */}
                        </td>
                      ))}
                    <td className="py-2 px-4">
                      {data &&
                        data?.cart.map((item, index) => (
                          <td className="">
                            {item.duration}
                          </td>
                        ))}
                    </td>
                    <td className="py-2 px-1">
                      {data &&
                        data?.cart.map((item, index) => (
                          <td className="py-2 px-4 flex flex-col">
                            <span className="flex items-center justify-start"> <p className="font-semibold mr-1"> Start Date: </p> {new Date(item.startDate).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</span>
                            <p className="font-semibold mr-1">
                              Lecture days and Time
                            </p>
                            <span>
                              {item.days} {" - "} {formatTime(item.startTime)}
                            </span>
                            <span> {item.enddays} {" - "} {formatTime(item.endTime)}
                            </span>
                          </td>
                        ))}
                    </td>
                    <td className="py-2 px-4">${data?.totalPrice}</td>
                    <td className="py-2 px-4">{data?.shippingAddress?.address1 +
                      " " +
                      data?.shippingAddress?.address2}. {" "}
                      {data?.shippingAddress?.country}, {" "} {data?.shippingAddress?.city}.</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserOrderDetails;
