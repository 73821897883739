import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/styles";

import {
  AiOutlineHeart,
  AiOutlineLogin,
  AiOutlineSearch,
} from "react-icons/ai";
import { BiMenuAltRight } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import Cart from "../cart/Cart";
import Wishlist from "../Wishlist/Wishlist";
import { RxCross1 } from "react-icons/rx";
import logo2 from '../../images/logo.png';
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { getAllCategories } from "../../redux/actions/category";
import { getAllBrands } from "../../redux/actions/brand";
import DropDown2 from "./DropDown2";
import { FiChevronDown, FiChevronLeft, FiChevronRight, FiChevronUp } from "react-icons/fi";

const Header = ({ activeHeading }) => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);
  const { wishlist } = useSelector((state) => state.wishlist);
  // const { cart } = useSelector((state) => state.cart);
  const { allProducts } = useSelector((state) => state.products);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [active, setActive] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [open, setOpen] = useState(false);
  const [sellerName, setSellerName] = useState(""); // State to hold seller's name
  const [sellerAvatar, setSellerAvatar] = useState("");
  const [userName, setUserName] = useState(""); // State to hold users's name
  const [userAvatar, setUserAvatar] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchBoxRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownVisibleUser, setDropdownVisibleUser] = useState(false);


  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllBrands());
  }, [dispatch]);

  // const { categories: categoriesData } = useSelector(state => state.categories);
  const { categories } = useSelector(state => state.categories);

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllBrands());
  }, [dispatch]);

  useEffect(() => {
    if (isSeller) {
      // Fetch the seller's information from the backend
      axios
        .get(`${server}/shop/getSeller`, { withCredentials: true })
        .then((res) => {
          if (res.data.success) {
            setSellerName(res.data.seller.name); // Set the seller's name
            setSellerAvatar(res.data.seller.avatar.url);
          }
        })
        .catch((error) => {
          console.error("Error fetching seller info:", error);
        });
    }
  }, [isSeller]);

  useEffect(() => {
    if (isAuthenticated) {
      // Fetch the user's information from the backend
      axios
        .get(`${server}/user/getuser`, { withCredentials: true })
        .then((res) => {
          if (res.data.success) {
            setUserName(res.data.user.name); // Set the seller's name
            setUserAvatar(res.data.user.avatar.url);
          }
        })
        .catch((error) => {
          console.error("Error fetching user info:", error);
        });
    }
  }, [isAuthenticated]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts =
      allProducts &&
      allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
    setSearchData(filteredProducts);
  };

  const handleClickOutside = (event) => {
    if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
      setSearchData(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // window.addEventListener("scroll", () => {
  //   if (window.scrollY > 70) {
  //     setActive(true);
  //   } else {
  //     setActive(false);
  //   }
  // });

  const logoutHandler = () => {
    axios
      .get(`${server}/user/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
        navigate("/login");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const stafflogoutHandler = async () => {
    axios.get(`${server}/shop/logout`, {
      withCredentials: true,
    });
    window.location.reload();
  };

  const firstCharacter = sellerName ? sellerName?.slice(0, 10) + "..." : "";
  const userFirstCharacter = userName ? userName?.slice(0, 10) + "..." : "";

  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleDropdownToggleUser = () => {
    setDropdownVisibleUser(!dropdownVisibleUser);
  };

  return (
    <div className={`${styles.section2}  bg-while border-b-2 border-white`}>
      <div className={`${styles.section2} bg-blue-700`}>

        {/* Upper Nav section goes here */}
        <div className="flex justify-between h-auto max-w-full mx-auto bg-deepblue px-4 md:px-8 lg:px-8 xl:px-24">
          {/* Telephone Section side */}
          <div className="flex items-center justify-start w-3/12 h-10 md:w-6/12">
            <h5 className='headerUpperh5'><span className="hidden md:visible md:block mr-1"> E-mail: </span> info@wumbisconcept.com</h5>
          </div>

          <div className="flex items-center justify-end w-8/12 h-10 md:w-7/12 gap-x-1 md:gap-x-4">
            {/* Phone number */}
            <div className="flex items-center justify-end h-8 md:w-6/12">
              <h5 className='headerUpperh5'> <span className="hidden md:visible md:block mr-2"> Hotline: </span>+1 306 910 7944 </h5>
            </div>

            {/* Refer Friend */}
            <div className="items-center justify-end hidden w-5/12 h-8 md:flex md:w-4/12 sm:block">
              <h5 className='headerUpperh5'>
                <Link to="#">
                  Refer a Friend
                </Link>
              </h5>
            </div>

          </div>
        </div>
        {/* Upper Nav section ends here */}

        {/* div with logo section goes here */}
        <div className="hidden 800px:h-[97px] 800px:my-[4px] 800px:flex items-center justify-between md:pb-3 bg-white lg:pt-0 px-8 lg:px-24">
          <div className="flex h-24 px-2 py-1 bg-white rounded-xl items-center justify-center overflow-hidden sm:mb-1 sm:mt-4">
            <Link to="/">
              <img
                src={logo2}
                alt=""
                className="w-20 h-20 lg:w-20 lg:h-20"
              />
            </Link>
          </div>
          {/* search box */}
          <div className="relative w-[50%] lg:mt-4" ref={searchBoxRef}>
            <input
              type="text"
              placeholder="Search Product..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="h-[40px] w-full px-2 border-green1 border-[2px] rounded-md"
            />
            <AiOutlineSearch
              size={30}
              className="absolute right-2 top-1.5 cursor-pointer"
            />
            {searchData && searchData.length !== 0 ? (
              <div className="absolute min-h-[10vh] bg-white shadow-sm-2 z-[50] rounded-t-none rounded-b rounded-md p-4">
                {searchData &&
                  searchData.map((i, index) => {
                    return (
                      <Link to={`/product/${i._id}`} key={index}>
                        <div className="flex w-full mb-2 items-start-py-3">
                          <img
                            src={`${i.images1[0]?.url}`}
                            alt=""
                            className="w-[30px] h-[30px] mr-[10px]"
                          />
                          <h1>{i.name}</h1>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            ) : null}
          </div>

          <div className="lg:mt-4">
            {isSeller ? (
              <div className="flex items-center cursor-pointer list-drop2 bg-white px-2 py-1 rounded-xl" onClick={handleDropdownToggle}>

                <div className="flex gap-2">
                  <div className="flex items-center justify-center text-black gap-2">
                    <span className="flex font-semibold text-gray-600"><i>  Wel. back </i> {" "} </span>
                    <span className="font-bold"> {firstCharacter} </span>
                  </div>

                  <img
                    src={sellerAvatar}
                    alt="Seller Avatar"
                    className="w-[50px] h-[50px] rounded-full object-cover"
                  />
                </div>


                {/* <p className="font-semibold ml-2 text-black">Profile Info</p> */}
                {dropdownVisible ? (
                  <FiChevronUp className="ml-2 bg-deepblue p-1 rounded-full text-white text-2xl" />
                ) : (
                  <FiChevronDown className="ml-2 bg-deepblue p-1 rounded-full text-white text-2xl" />
                )}

                {dropdownVisible && (
                  <div className="absolute z-50 ml-[113px] flex flex-col top-[14%] mt-5 w-40 bg-deepblue rounded-sm shadow-lg border-2 border-white">
                    <Link to="/dashboard" className=" px-4 py-1 text-white hover:bg-darkblue  flex items-center justify-center w-full">
                      Dashboard
                    </Link>
                    {/* <Link to="/profile" className="block px-4 py-2 text-white hover:bg-darkblue">
                      Profile
                    </Link> */}
                    <Link to="/" className=" px-4 py-1 text-white hover:bg-red-700 flex items-center justify-center w-full" onClick={stafflogoutHandler}>
                      Logout
                    </Link>
                  </div>
                )}
              </div>
            ) : (
              <div className="hidden sm:block">
                <div className="flex w-full gap-2">
                  <Link
                    to="/login"
                    className="items-center justify-center px-3 py-1 font-bold text-white bg-deepblue"
                  >
                    Login
                  </Link>
                  <Link
                    to="/sign-up"
                    className="items-center justify-center px-3 py-1 font-bold text-white bg-red-700"
                  >
                    Register
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* div with logo section ends here */}
      </div>

      <div
        className={`${active === true ? "shadow-sm fixed top-0 left-0 z-10" : null
          } transition hidden 800px:flex items-center justify-between w-full h-[70px] md:h-[50px] bg-deepblue lg:px-6 Action-Controller`}
      >
        <div
          className={`${styles.section} relative ${styles.noramlFlex} items-start justify-between bg-deepblue px-2 md:px-0 lg:px-2`}
        >
          {/* categories */}
          <div>
            <DropDown2 categoriesData={categories} />
          </div>

          {/* navitems */}
          {/* <div className={`${styles.noramlFlex}`}> */}
          <div>
            <Navbar active={activeHeading} />
          </div>

          <div className="flex">
            <div className={`${styles.noramlFlex}`}>
              <div
                className="relative cursor-pointer md:mr-[9px] lg:mr-[15px]"
                onClick={() => setOpenWishlist(true)}
              >
                <AiOutlineHeart size={30} color="rgb(255 255 255 / 83%)" />
                <span className="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                  {wishlist && wishlist.length}
                </span>
              </div>
            </div>

            <div className={`${styles.noramlFlex}`}>
              <div className="relative cursor-pointer md:mr-[0px] lg:mr-[0px]">
                {isAuthenticated ? (
                  <div className="flex items-center cursor-pointer list-drop2 bg-deepblue px-2 py-0 rounded-xl" onClick={handleDropdownToggleUser}>

                    <div className="flex gap-2">
                      <div className="flex items-center justify-center text-black gap-2">
                        <span className="flex font-semibold text-gray-300"><i>  Wel. back </i> {" "} </span>
                        <span className="font-bold text-white"> {userFirstCharacter} </span>
                      </div>

                      <img
                        src={userAvatar}
                        alt="User Avatar"
                        className="w-[40px] h-[40px] rounded-full object-cover"
                      />
                    </div>


                    {/* <p className="font-semibold ml-2 text-black">Profile Info</p> */}
                    {dropdownVisibleUser ? (
                      <FiChevronUp className="ml-2 bg-deepblue p-1 rounded-full text-white text-2xl" />
                    ) : (
                      <FiChevronDown className="ml-2 bg-deepblue p-1 rounded-full text-white text-2xl" />
                    )}

                    {dropdownVisibleUser && (
                      <div className="absolute ml-[93px] flex flex-col mt-28 w-40 bg-deepblue rounded-sm shadow-lg border-2 border-white z-50 Not-Showing">
                        <Link to="/profile" className=" px-4 py-1 text-white hover:bg-darkblue  flex items-center justify-center w-full">
                          Dashboard
                        </Link>
                        {/* <Link to="/profile" className="block px-4 py-2 text-white hover:bg-darkblue">
                      Profile
                    </Link> */}
                        <Link to="/" className=" px-4 py-1 text-white hover:bg-red-700 flex items-center justify-center w-full" onClick={logoutHandler}>
                          Logout
                        </Link>
                      </div>
                    )}
                  </div>
                ) : (
                  <Link to="/login">
                    <CgProfile size={30} color="rgb(255 255 255 / 83%)" />
                  </Link>
                )}
              </div>
            </div>

            {/* cart popup */}
            {openCart ? <Cart setOpenCart={setOpenCart} /> : null}

            {/* wishlist popup */}
            {openWishlist ? (
              <Wishlist setOpenWishlist={setOpenWishlist} />
            ) : null}
          </div>
        </div>
      </div>

      {/* mobile header */}
      <div className={`${active === true ? "shadow-sm fixed top-0 left-0 z-10" : null} w-full h-[60px] z-50 top-0 left-0
  shadow-sm 800px:hidden`}>
        <div className="flex items-center justify-between w-full bg-white px-2 border-b-2 border-deepblue ">

          <div>
            <Link to="/" className="bg-white flex px-3 py-2 rounded-xl w-16 h-16 md:w-56 md:h-14 mt-[1px]">
              <img src={logo2} alt="" className="cursor-pointer " />
            </Link>
          </div>
          <div className="">
            <BiMenuAltRight size={40} className="md:ml-2 text-deepblue md:text-deepblue lg:text-white" onClick={() =>
              setOpen(true)}
            />
          </div>

          {/* wishlist popup */}
          {openWishlist ?
            <Wishlist setOpenWishlist={setOpenWishlist} /> : null}
        </div>

        {/* header sidebar */}
        {open && (
          <div className={`fixed w-full bg-[#0000005f] z-20 h-full top-0 left-0`}>
            <div className="fixed w-[80%] bg-white max-h-[500px] md:max-h-[450px] top-0 left-0 z-10 overflow-y-scroll rounded-r-lg">
              <div className="flex justify-between w-full pr-3">

                <div className="flex items-center justify-start mt-3">
                  <div className="">
                    <div className="relative mr-[15px]" onClick={() => setOpenWishlist(true) || setOpen(false)}
                    >
                      <AiOutlineHeart size={30} className="ml-3 text-deepblue" />
                      <span
                        className="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                        {wishlist && wishlist.length}
                      </span>
                    </div>
                  </div>
                </div>

                <RxCross1 size={30} className="mt-5 ml-4 text-white bg-red-700" onClick={() => setOpen(false)}
                />
              </div>
              {/* Search Section for mobile */}
              <div className="relative w-full mt-6 px-2" ref={searchBoxRef}>
                <input type="text" placeholder="Search Product..." value={searchTerm} onChange={handleSearchChange}
                  className="h-[40px] w-full px-2 border-green1 border-[2px] rounded-md" />
                <AiOutlineSearch size={30} className="absolute right-5 top-1.5 cursor-pointer" />
                {searchData && searchData.length !== 0 ? (
                  <div className="absolute min-h-[10vh] bg-white shadow-sm-2 z-[50] p-4 rounded-t-none rounded-b rounded-md">
                    {searchData &&
                      searchData.map((i, index) => {
                        return (
                          <Link to={`/product/${i._id}`} key={index}>
                            <div className="flex w-full mb-2 items-start-py-3">
                              <img src={`${i.images1[0]?.url}`} alt="" className="w-[30px] h-[30px] mr-[10px]" />
                              <h1>{i.name}</h1>
                            </div>
                          </Link>
                        );
                      })}
                  </div>
                ) : null}
              </div>
              {/* Search section for mobile ends here */}
              {/* categories */}
              <div className="w-full mt-2 mb-2 px-2 ">
                <DropDown2 categoriesData={categories} />
              </div>
              {/* category section ends here */}
              <div className="rounded-md bg-white px-2 md:px-0 lg:px-2">

                <Navbar active={activeHeading} />

                <div className="relative flex w-full px-0 mt-4 ml-0 justify-left">
                  {isSeller ? (
                    <div className="flex items-center cursor-pointer list-drop2 px-2 py-1 rounded-xl w-full"
                      onClick={handleDropdownToggle}>

                      <div className="flex gap-3">
                        <div className="flex items-center justify-center text-black gap-2">
                          <span className="flex font-semibold text-gray-800"><i> Wel. Back </i> </span>
                          <span className="font-semibold"> {firstCharacter} </span>
                        </div>

                        <img src={sellerAvatar} alt="Seller Avatar" className="w-[40px] h-[40px] rounded-full object-cover" />
                      </div>


                      {/* <p className="font-semibold ml-2 text-black">Profile Info</p> */}
                      {dropdownVisible ? (
                        <FiChevronLeft className="ml-3 bg-deepblue p-1 rounded-full text-white text-2xl" />
                      ) : (
                        <FiChevronRight className="ml-3 bg-deepblue p-1 rounded-full text-white text-2xl" />
                      )}

                      {dropdownVisible && (
                        <div
                          className="absolute z-50 mt-[-5px] py-0 w-[240px] bg-deepblue hover:bg-deepblue rounded-sm shadow-lg border-2 border-white">
                          <Link to="/dashboard"
                            className=" px-4 py-1 text-white hover:bg-darkblue flex items-center justify-center w-full">
                            Go to dashboard
                          </Link>
                          <Link to="/" className=" px-4 py-1 text-white hover:bg-red-700 flex items-center justify-center w-full"
                            onClick={stafflogoutHandler}>
                            Logout
                          </Link>
                        </div>
                      )}
                    </div>
                  ) : (null)}
                </div>

                <div className="flex w-full px-0 pb-4 mt-2 ml-0 justify-left ">
                  {isAuthenticated ? (
                    <div className="flex items-center justify-between w-full px-1 md:px-5 ">
                      <Link to="/profile" className="items-center justify-center flex gap-2">
                        <img src={`${user.avatar?.url}`} alt=""
                          className="w-[60px] h-[60px] rounded-full border-[3px] border-lightenblue" />
                        <p className="text-white text-base font-semibold bg-deepblue px-2 py-1"> View Profile </p>
                      </Link>

                      <div className="flex items-center justify-start gap-2 font-bold cursor-pointer " onClick={logoutHandler}>
                        <AiOutlineLogin size={20} className="font-extrabold text-black" />
                        <span className="font-extrabold font-600 text-black">
                          Log out
                        </span>
                      </div>
                    </div>

                  ) : (
                    <div className="flex items-center justify-between w-full p-2 px-0 md:px-4 rounded-md ">
                      <Link to="/login"
                        className="text-[18px] text-[#fff] bg-deepblue px-6 py-2 items-center justify-center font-bold hover:bg-black focus:bg-green4">
                        Login
                      </Link>
                      <Link to="/sign-up"
                        className="text-[18px] text-[#fff] bg-deepblue px-6 py-2 items-center justify-center font-bold hover:bg-black focus:bg-green4">
                        Sign up
                      </Link>
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default Header;