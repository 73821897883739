const styles = {
  custom_container: "w-11/12 hidden sm:block",
  heading: 'text-[27px] text-center md:text-start font-[600] font-Roboto pb-[20px]',
  heading2: ' mt-4 md:mt-10 text-[17px] md:text-[20px] lg:text-[27px] text-center md:items-center font-300 md:font-[600] font-Roboto py-1 md:py-2 md:mt-4 mb-2 flex bg-darkblue pl-2 text-white border-b-darkblue  border-4 ',
  heading3: ' mt-4 md:mt-10 text-[17px] md:text-[27px] text-center md:items-center font-300 md:font-[600] font-Roboto py-1 md:py-0 md:mt-4 mb-4 flex text-white border-b-deepblue border-b-4 ',
  section: 'w-11/12 mx-auto',
  section2: 'w-full mx-auto p-0',
  section3: 'mx-auto px-4 md:px-12 lg:px-16 mt-8 md:mt-0',
  productTitle: 'text-[25px] font-[600] font-Roboto text-[#333]',
  productTitle2: 'sm-[20px] md:text-[60px] font-[600] font-Roboto text-[#333]',
  productDiscountPrice: "font-bold text-[18px] text-[#000] font-Roboto",
  price: "font-[500] text-[12px] text-[#fff] mt-[0px] line-through bg-red-700 items-center justify-center rounded-lg h-5 px-2 py-0 ml-4 ",
  shop_name: "pt-3 text-[15px] text-blue-400 pb-3 hidden md:block",
  shop_name2: "pt-3 text-[15px] text-black font-semibold pb-3",
  active_indicator: "absolute bottom-[-27%] left-0 h-[3px] w-full bg-[crimson]",
  active_indicator2: "absolute bottom-[-27%] left-0 h-[3px] w-full bg-darkblue",
  button: 'w-[150px] bg-black h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer',
  button2: 'w-[150px] md:w-[150px] bg-darkblue h-[40px] sm:font-xs  md:h-[50px] my-3 flex items-center justify-center rounded-lg cursor-pointer',
  button3: 'w-[250px] bg-deepblue h-[50px] my-3 flex items-center justify-center rounded-md cursor-pointer',
  button4: 'w-[150px] bg-green4 h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer',
  button5: 'w-[240px] bg-green2 h-[40px] my-3 flex items-center justify-center rounded-md cursor-pointer hover:bg-green3 hover:text-black font-semibold',
  button6: 'w-[170px] bg-lightenblue h-[40px] mt-1 flex items-center justify-center rounded-lg cursor-pointer',
  button7: 'bg-darkblue px-4 py-1 text-white rounded-lg font-semibold cursor-pointer',
  button8: 'bg-red-600 px-4 py-1 text-white rounded-lg font-semibold cursor-pointer',
  cart_button: "px-[15px] h-[30px] md:px-[20px] md:h-[38px] rounded-[20px] bg-deepblue flex items-center justify-center cursor-pointer",
  cart_button_text: "text-[#fff] text-[10px] font-[600] md:text-[16px] md:font-[600]",
  input: "w-full border p-1 rounded-[5px] font-[300] md:font-[500] bg-white ",
  input2: "w-[95%] border p-1 rounded-[5px] py-2",
  input3: "w-[98%] border p-1 rounded-[5px] py-2",
  input4: "w-full lg:w-[35%] border px-1 rounded-[5px] py-2",
  activeStatus: "w-[10px] h-[10px] rounded-full absolute top-0 right-1 bg-[#40d132]",
  noramlFlex: "flex items-center",
  noramlFlex2: "flex w-[600px] items-start justify-center",
};

export default styles;
