import React, { useState } from 'react';
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Container from '../components/Container';
import Meta from '../components/Meta';
import { IoHome } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPersonWalkingArrowLoopLeft } from "react-icons/fa6";
import CourseBanner from '../components/CourseBanner';
import banner from '../images/banner.png';

const ContactusPage = () => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // const response = await fetch('http://localhost:8000/api/v2/contact', {
            const response = await fetch('https://wumbisconceptbackend-3.onrender.com/api/v2/contact', {
                //  const response = await fetch('wumbisdeployment.vercel.app/api/v2/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();
            if (result.success) {
                alert('Message sent successfully');
            } else {
                alert('Failed to send message');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to send message');
        }
    };

    return (
        <>
            <Header />
            <CourseBanner imageUrl={banner}>
                <h1 className=" font-bold">CONTACT US PAGE</h1>
                {/* <p className="mt-2 text-lg md:text-xl lg:text-2xl xl:text-3xl">Your banner subheading or description goes here.</p> */}
            </CourseBanner>
            <Meta title={"Contact Us"} />
            <Container>
                {/* <BreadCrumb title="Contact Us" /> */}

                <div className="py-10 bg-white md:pb-16 lg:pb-24 xl:pb-32 lg:px-6">

                    <div className="px-2 py-4 md:px-6 lg:py-12 bg-white  isolate lg:px-0">

                        <div className="max-w-2xl mx-auto lg:max-w-7xl">

                            <div className="grid grid-cols-1 rounded-xl bg-deepblue px-2 py-3 md:px-6 md:py-6 mt-0">
                                <h2 className="text-2xl md:text-4xl font-bold tracking-tight text-white">Let’s talk</h2>
                                <p className=" font-semibold mt-0 md:mt-2 text-base md:text-lg leading-8 text-white">
                                    Reach out fast to our response team
                                </p>
                            </div>

                            <div className="flex flex-col rounded-xl bg-white md:bg-deepblue p-0 md:px-6 md:py-10 gap-6 md:gap-16 mt-8 lg:flex">

                                <form onSubmit={handleSubmit}>
                                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2 bg-lightenblue rounded-xl px-4 py-8 md:p-4 lg:w-7/12">
                                        <div>
                                            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-white">
                                                First name
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    id="firstname"
                                                    value={formData.firstName}
                                                    onChange={handleChange}
                                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-white">
                                                Last name
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    id="firstname"
                                                    value={formData.lastName}
                                                    onChange={handleChange}
                                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-white">
                                                Email
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="phone" className="block text-sm font-semibold leading-6 text-white">
                                                Phone Number
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="tel"
                                                    name="phone"
                                                    id="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="subject" className="block text-sm font-semibold leading-6 text-white">
                                                Subject
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    id="subject"
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-white">
                                                Message
                                            </label>
                                            <div className="mt-1">

                                                <textarea
                                                    type="text"
                                                    rows={4}
                                                    name="message"
                                                    id="subject"
                                                    value={formData.message}
                                                    defaultValue={''}
                                                    onChange={handleChange}
                                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-4 flex w-full">
                                            <button
                                                type="submit"
                                                className="block w-full rounded-md bg-deepblue px-3.5 py-4 text-center text-sm font-semibold text-white shadow-sm hover:bg-deepblue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                SUBMIT
                                            </button>
                                        </div>

                                    </div>
                                </form>
                                <div className="bg-lightenblue lg:mt-0 p-4 md:p-4 lg:flex-none md:h-56 rounded-xl lg:w-3/12">
                                    <figure className="mt-0">
                                        <blockquote className="text-lg font-semibold leading-8 ">
                                            <h2 className="text-xl md:text-2xl text-deepblue">
                                                Get In Touch With Us
                                            </h2>
                                            <ul className="mt-2 md:mt-4 gap-y-4">
                                                <li className="flex items-center">
                                                    <IoHome className="flex-shrink-0 w-5 h-5 mr-2 md:mr-2 text-deepblue" aria-hidden="true" /> <p className="font-medium md:font-semibold text-base md:text-lg"> Ikorodu Lagos State </p>
                                                </li>
                                                <li className="flex items-center">
                                                    <FaPhone className="flex-shrink-0 w-5 h-5 mr-2 md:mr-2 text-deepblue" aria-hidden="true" /> <p className="font-medium md:font-semibold text-base md:text-lg"> +1 306 910 7944 </p>
                                                </li>
                                                <li className="flex items-center">
                                                    <MdEmail className="flex-shrink-0 w-5 h-5 mr-2 md:mr-2 text-deepblue" aria-hidden="true" /> <p className="font-medium md:font-semibold text-base md:text-lg"> info@wumbisconcept.com </p>
                                                </li>
                                                <li className="flex items-center">
                                                    <FaPersonWalkingArrowLoopLeft className="flex-shrink-0 w-5 h-5 mr-2 md:mr-2 text-deepblue" aria-hidden="true" /> <p className="font-medium md:font-semibold text-base md:text-lg"> Monday - Sunday (10am - 8pm) </p>
                                                </li>
                                            </ul>
                                        </blockquote>

                                    </figure>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
            <Footer />

        </>
    );
}

export default ContactusPage
