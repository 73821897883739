// add to cart
// export const addTocart = (data) => async (dispatch, getState) => {
//   dispatch({
//     type: "addToCart",
//     payload: data,
//   });

//   localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
//   return data;
// };

// // remove from cart
// export const removeFromCart = (data) => async (dispatch, getState) => {
//   dispatch({
//     type: "removeFromCart",
//     payload: data._id,
//   });
  
//   localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
//   return data;
// };


export const addTocart = (data) => async (dispatch, getState) => {
  // Remove any existing items from the cart
  dispatch(removeFromCart({ _id: getState().cart.cart[0]?._id }));

  // Add the new item to the cart
  dispatch({
    type: "addToCart",
    payload: data,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
  return data;
};

export const removeFromCart = (data) => ({
  type: "removeFromCart",
  payload: data,
});