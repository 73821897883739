import React from 'react'
import AdminSideBar from '../components/Admin/Layout/AdminSideBar';
import AdminHeader from '../components/Layout/AdminHeader';
import CreateBanner from '../components/Admin/CreateBanner';

const AdminCreateBanner = () => {
  return (
      <>
          <AdminHeader />
          <div className="flex justify-between w-full">
              <div className="w-[80px] 800px:w-[330px]">
                  <AdminSideBar active={10} />
              </div>
              <div className="w-full justify-center flex">
                  <CreateBanner />
              </div>
          </div>
      </>
  )
}

export default AdminCreateBanner
