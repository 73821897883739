import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
// import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Meta from "../Meta";
import Container from "../Container";
import Custominput from "../Custominput";
import { IoHome } from "react-icons/io5";
import { FaUserAlt } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   await axios
  //     .post(
  //       `${server}/user/login-user`,
  //       {
  //         email,
  //         password,
  //       },
  //       { withCredentials: true }
  //   ).then((res) => {
  //       if (res.data.isBlocked) {
  //         toast.warning(res.data.message);
  //         navigate("/");
  //         window.location.reload();
  //       } else {
  //         toast.success("Login Success!");
  //         navigate("/profile");
  //         window.location.reload();
  //       }
  //     })
  //     .then((res) => {
  //       toast.success("Login Success!");
  //       navigate("/");
  //       window.location.reload();
  //     })
  //     .then((res) => {
  //       if (res.data.isBlocked) {
  //         toast.warning(res.data.message);
  //         navigate("/");
  //         window.location.reload();
  //       } else {
  //         toast.success("Login Success!");
  //         navigate("/");
  //         window.location.reload();
  //       }
  //     })

  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .post(
        `${server}/user/login-user`,
        {
          email,
          password,
        },
        { withCredentials: true }
      ).then((res) => {
        if (res.data.isBlocked) {
          toast.warning(res.data.message);
          navigate("/");
          window.location.reload();
          // Navigate them to a restricted page or just show the warning
        } else {
          toast.success("Login Success!");
          navigate("/profile");
          window.location.reload();
        }
      }).catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <Header />
      <Meta title={"Login Page"} />
      <div className="flex min-h-full flex-1 flex-col justify-center bg-gray-100 bg-cover bg-center" style={{ backgroundImage: `url('../images/background.jpg')` }}>
        <div className="w-full h-auto bg-black bg-opacity-70">
          <Container>
            <div className="mt-0 sm:mx-auto sm:w-full sm:max-w-[480px] bg-transparent py-8 rounded-lg">
              <img
                className="mx-auto h-20 w-auto "
                src="../images/logo.png"
                alt="Your Company"
              />

              <h2 className="mt-2 text-center text-2xl font-semibold leading-9 tracking-tight text-white">
                User Account SignIn
              </h2>

              <div className="bg-deepblue px-4 py-4 md:py-8 shadow sm:rounded-lg sm:px-12 mt-2 md:mt-2">
                <form className="space-y-6" onSubmit={handleSubmit}>
                  <div>
                    <div className="mt-2">
                      <input
                        type="email"
                        name="email"
                        autoComplete="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="mt-2 relative">
                      <input
                        type={visible ? "text" : "password"}
                        name="password"
                        autoComplete="current-password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                      {visible ? (
                        <AiOutlineEye
                          className="absolute cursor-pointer right-2 top-2"
                          size={25}
                          onClick={() => setVisible(false)}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="absolute cursor-pointer right-2 top-2"
                          size={25}
                          onClick={() => setVisible(true)}
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Custominput id="remember-me" name="remember-me" type="checkbox" placeholder="Enter your password" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                      <label htmlFor="remember-me" className="ml-3 block text-sm md:text-base lg:text-base leading-6 text-white">
                        Remember me
                      </label>
                    </div>

                    <div className="leading-6 flex">
                      <Link to="/user-forgot-password" className="font-semibold text-sm md:text-base lg:text-base text-white hover:text-lightenblue">
                        Forgot password?
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center bg-lightenblue px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Sign In
                    </button>
                  </div>
                </form>

                <div>
                  <div className="relative mt-10">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex justify-center text-sm font-medium leading-6">
                      <span className="bg-white px-6 text-gray-900 font-bold">OR</span>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-between gap-2">
                    <Link
                      to="/shop-login"
                      className="flex gap-1 items-center justify-center rounded-sm bg-lightenblue px-2 py-2 text-sm font-normal text-white shadow-sm ring-1 ring-inset ring-gray-100 focus-visible:ring-transparent"
                    >
                      <span className="text-md">
                        <FaUserFriends />
                      </span>
                      Staff Login
                    </Link>

                    <Link
                      to="/sign-up"
                      className="flex gap-1 items-center justify-center rounded-sm bg-darkblue px-2 py-2 text-xs font-normal text-white shadow-sm ring-1 ring-inset ring-gray-100 focus-visible:ring-transparent"
                    >
                      <span className="text-md">
                        <FaUserAlt />
                      </span>
                      Create User Account
                    </Link>

                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center mt-4 mb-4 text-center text-sm font-semibold text-white">
                Not a User?{' '}
                <Link to="/" className="flex gap-2 items-center justify-center ml-3 font-semibold leading-6 text-white hover:text-white px-4 py-2 bg-deepblue">
                  <span> <IoHome /> </span>
                  Back to Homepage
                </Link>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  )

};



export default Login;
