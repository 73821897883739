import React from 'react'

const Custominput = (props) => {
    const { id, type, name, placeholder, required, className } = props
    return (
        <div>
            <input
                id={id}
                name={name}
                type={type}
                required={required}
                placeholder={placeholder}
                className={`${className}`}
            />
        </div>
    )
}

export default Custominput
