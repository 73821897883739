import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Faq from "../components/Faq";
import CourseBanner from "../components/CourseBanner";
import banner from '../images/banner.png';
import Heading from "../components/Heading";

const FAQPage = () => {
  return (
    <div>
      <Header activeHeading={5} />
      <CourseBanner imageUrl={banner}>
        <h1 className=" font-bold"> FAQS PAGE</h1>
        {/* <p className="mt-2 text-lg md:text-xl lg:text-2xl xl:text-3xl">Your banner subheading or description goes here.</p> */}
      </CourseBanner>
      <div className="py-0 px-0 mb-10 lg:py-4 mt-6 lg:mt-10 md:mb-28 lg:px-2">
        <div className='flex w-full md:px-6 lg:px-8 xl:px-20 md:ml-0 ml-8'>
          <Heading left title="Our Frequently Asked Questions" />
        </div>
        <div className="max-w-[1920px] mx-auto xl:py-0 xl:px-20 lg:px-8 md:px-8 px-6">
          <Faq />
        </div>
      </div>
      
      <Footer />
    </div>
  );
};



export default FAQPage;
