import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import styles from "../../styles/styles";
import { addToWishlist, removeFromWishlist } from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import DOMPurify from 'dompurify';

import banner from '../../images/banner.png';
import CourseBanner from "../CourseBanner";
import Faq from "../Faq";

const ProductDetails = ({ data }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { products } = useSelector((state) => state.products);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);


  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductsShop(data && data?.shop._id));
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [data, wishlist, dispatch]);

 
  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };


  const addToCartHandler = (id) => {

    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      navigate("/checkout");
    } else {
      if (data.stock < 1) {
        toast.error("Course not available limited!");
      } else {
        const cartData = { ...data, qty: count, };
        dispatch(addTocart(cartData)); // This will automatically handle removing previous items
        navigate("/checkout");
      }
    }
    
  };


  // Toggle the state and call appropriate handler
  const handleClick = () => {
    setClick(prevClick => !prevClick);
    if (click) {
      removeFromWishlistHandler(data);
    } else {
      addToWishlistHandler(data);
    }
  };


  // Function to remove HTML tags from the description
  const stripHtmlTags = (html) => {
    const cleanText = DOMPurify.sanitize(html, { ALLOWED_TAGS: [] });
    return cleanText;
  };

  // Time Format
  const formatTime = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM
    return `${String(formattedHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${period}`;
  };

  return (

    <>
      

      {/* second coppied section goes here */}

      <div className="bg-white  mb-12">

        <CourseBanner imageUrl={banner}>
          <h1 className="font-bold">COURSE DISCRIPTION PAGE</h1>
        </CourseBanner>

        {/* <Container> */}
        <div className="mx-auto xl:py-10 xl:px-4 lg:px-6 md:px-8 px-6">
          {data ? (
            <div className={`${styles.section2} w-[90%] 800px:w-[90%] pt-0 `}>
              <div className="w-full">
                {/* Upper Section of single course goes here */}
                <div className="mt-10 flex flex-grow w-full bg-white">
                  <div className=" md:w-full lg:w-7/12  flex flex-col bg-white p-4">
                    {/* <h1 className="text-lg md:text-5xl lg:text-5xl xl:text-6xl font-bold leading-tight font-serif">Business Analysis - Practical Job Ready</h1> */}
                    <h1 className="text-lg md:text-5xl lg:text-5xl xl:text-6xl font-bold leading-tight font-serif">{data.name}</h1>
                    <h3 className=" text-medium md:text-2xl lg:text-3xl xl:text-3xl md:mt-4 font-bold font-sans ">{data.modeoftutor}</h3>
                    <div className="w-full bg-lightenblue mt-2 md:mt-4 p-4 rounded-xl">
                      <p className="text-sm md:text-base lg:text-base xl:text-lg font-semibold leading-8">
                        <span> Start Date:{" "}{new Date(data.startDate).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</span> <br /> <span> Program Duration: {" "} {data.duration} </span> <br />
                        <div className='flex'>Class Days/times:
                          <span className="grid grid-cols-1 ml-2">  ({data.days}: {formatTime(data.startTime)} -  {formatTime(data.endTime)} {data.timezone}) <br /> ({data.enddays}: {formatTime(data.startTime)} -  {formatTime(data.endTime)} {data.timezone})</span>
                        </div>
                      </p>
                    </div>
                    <div className="w-full xl:font-medium lg:font-normal md:font-normal bg-white mt-2 md:mt-4 py-4 rounded-xl text-justify">
                      {stripHtmlTags(data.description)}
                    </div>

                    <div className=" grid grid-cols-1 md:flex w-full items-center justify-start gap-4 md:mt-2">
                      {/* <Link to="/applicationform" className="connectAdvisorButton text-bold rounded-lg">
                  Enrol Now
                </Link> */}

                      <div className="">
                        <p
                          size={30}
                          className="items-center w-full px-2 py-3 mt-3 text-sm font-semibold text-center text-white rounded-lg md:px-4 md:py-2 md:items-start md:text-base hover:text-white md:w-auto cursor-pointer"
                          onClick={handleClick}
                          style={{ backgroundColor: click ? "red" : "blue" }}
                          title={click ? "Remove from course wishlist" : "Add to course wishlist"}
                        >
                          {click ? "Remove from wishlist" : "Add to wishlist"}
                        </p>

                      </div>

                      <span className="connectAdvisorButton text-bold rounded-lg cursor-pointer" onClick={() => addToCartHandler(data._id)}>
                        Register Now
                      </span>
                      {/* <Link to="/applicationform" className="connectAdvisorButton text-bold rounded-lg">
                  Enrol Now
                </Link> */}
                      <Link to="/applicationform" className="connectAdvisorButton text-bold rounded-lg">
                        Consult with a course advisor
                      </Link>
                    </div>
                  </div>

                  <div className="hidden lg:block w-5/12 xl:flex bg-white items-start justify-end">
                    {/* <img src={careerservices} alt="" className="md:mt-6 rounded-3xl" /> */}
                    <img src={`${data && data.images2[select]?.url}`} alt="" className="md:mt-6 rounded-3xl" />
                  </div>
                </div>
                {/* Upper Section of single course end here */}



                {/* Lower Section of single course goes here  */}
                <div className="mt-2 grid grid-cols-1 md:flex md:flex-grow w-full bg-white md:pb-32">
                  <div className="hidden sm:block md:flex flex-col w-full md:w-5/12 lg:w-4/12 xl:w-3/12 gap-6 p-2 bg-transparent asideDiv">
                    {/* Payment method section goes here */}
                    <div className="sticky top-20 flex flex-col w-full p-0 overflow-hidden rounded-lg shopbycategoryDiv bg-deepblue">
                      <div className='items-center w-full px-4 py-2 text-white bg-transparent border-b-2 border-gray-300 shopbycategoryHeading'>
                        <h4 className="font-bold text-xl"> Business Analysis - Practical Job Ready </h4>
                      </div>
                      <div className='shopbycategoryList'>
                        <ul className='pb-4 text-decoration-0'>
                          <li> <a href="#aboutthecourse" className="mt-2 font-semibold text-black"> About the Course </a> </li>
                          <li> <a href="#learningobjective" className="mt-2 font-semibold text-black"> Learning Objectives </a> </li>
                          <li> <a href="#whatyouwillget" className="mt-2 font-semibold text-black"> What you'will get  </a> </li>
                          <li> <a href="#paymentoption" className="mt-2 font-semibold text-black"> Payment Options </a> </li>
                          <li> <a href="#frequentlyaskedquestions" className="mt-2 font-semibold text-black"> FAQs </a> </li>
                          {/* <li> <a href="/" className="mt-2 font-semibold text-black"> Register Now </a> </li> */}
                        </ul>
                        <Link to="/" className="connectAdvisorButton rounded-lg" target="_blank">
                          Back to Courses
                        </Link>
                      </div>
                    </div>
                    {/*  payment method section ends here */}
                  </div>

                  <div className="w-full md:w-8/12 lg:w-8/12 xl:w-9/12 p-2 overflow-hidden bg-white rounded-md mainDiv md:px-6">
                    {/* About the course section goes here */}
                    <div id="aboutthecourse" className="aboutthecourse">
                      <h4 className='h3'> About the Course</h4>
                      <div className="w-full md:mt-4 text-justify xl:font-medium lg:font-normal md:font-normal "> {stripHtmlTags(data.aboutthecourse)} </div>

                    </div>
                    {/* About the course section ends here */}

                    {/* Learning Objective section goes here */}
                    <div id="learningobjective" className="aboutthecourse mt-8 md:mt-10">
                      <h4 className='h3'>
                        A Learning Objectives <br />
                        Upon completion of this program, you will learn to
                      </h4>
                      <div className="w-full md:mt-2 text-justify list-decimal leading-6 px-0 xl:font-medium lg:font-normal md:font-normal">
                        {stripHtmlTags(data.aboutthecourse)}
                      </div>
                    </div>
                    {/* Learning Objective section ends here */}

                    {/* what you will get section goes here */}
                    <div id="whatyouwillget" className="aboutthecourse mt-8 md:mt-10">
                      <h4 className='h3'>
                        What You'll Get
                      </h4>
                      <div className="flex py-4 px-0  w-full">
                        <img src={`${data && data.images3[select]?.url}`} alt="" className='w-full h-full object-cover rounded-lg' />
                      </div>
                    </div>
                    {/* what you will get section ends here */}

                    {/* Payment and Pricing section goes here */}
                    <div id="paymentoption" className="aboutthecourse md:mt-10">
                      <h4 className='h3'>
                        Payment Options <br />
                        <span className="text-deepblue">Flexible & Affordable Pricing </span>
                      </h4>

                      <p className="md:mt-4 text-justify">
                        Our premium experiential learning programs with incredible support have been thoughtfully designed to support your career
                        growth and acceleration. We understand that your journey to success may need a flexible approach. That’s why we allow installment
                        options. Invest in yourself today and supercharge your career dreams
                      </p>

                      {/* <div className=" grid grid-cols-1 md:flex w-full items-center justify-start gap-4 md:mt-2">
                      <Link to="/applicationform" className="connectAdvisorButton text-bold rounded-lg">
                        Enrol Now
                      </Link>
                    </div> */}
                    </div>
                    {/* payment and pricing section ends here */}

                    <ProductDetailsInfo
                      data={data}
                      products={products}
                      // totalReviewsLength={totalReviewsLength}
                      // averageRating={averageRating}
                    />

                    {/* Accordion Section goes here  */}
                    <div id="frequentlyaskedquestions" className="aboutthecourse mt-8 md:mt-10 lg:mt-14 mb-16 md:mb-0">
                      <h4 className='h3 lg:mb-0 md:mb-4 mb-2'>
                        Frequently Asked Questions
                      </h4>
                      <Faq />
                    </div>

                    {/* Accordion Section ends here */}
                  </div>

                </div>
                {/* Lower Section of single course ends here  */}

              </div>
            </div>
          ) : null}
        </div>



      </div>

      {/* secons coppied section ends here */}
    </>
  );
};


const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
}) => {
  const [active, setActive] = useState(1);

  // Function to remove HTML tags from the description
  const stripHtmlTags = (html) => {
    const cleanText = DOMPurify.sanitize(html, { ALLOWED_TAGS: [] });
    return cleanText;
  };

  return (
    <div className="mt-4 px-3 800px:px-0 py-2 rounded">
      <div className="w-full flex gap-2 border-b pt-4 md:pt-10 pb-2">
        <div className="relative">
          <h5
            className={
              "text-[13px] md:text-[15px] px-2 py-2 leading-4 md:leading-5 font-[400] md:font-[500] cursor-pointer text-white 800px:text-[15px] active border-b-4 border-red-500 active:border-red-500 hover:border-lightenblue bg-deepblue rounded-xl"
            }
            onClick={() => setActive(1)}
          >
            Course Details
          </h5>
          {active === 1 ? (
            <div className={`${styles.active_indicator2}`} />
          ) : null}
        </div>

        <div className="relative">
          <h5
            className={
              "text-[13px] md:text-[15px] px-2 py-2 leading-4 md:leading-5 font-[400] md:font-[500] cursor-pointer text-white 800px:text-[15px] active border-b-4 border-red-500 active:border-red-500 hover:border-lightenblue bg-deepblue rounded-xl"
            }
            onClick={() => setActive(3)}
          >
            Tutor Information
          </h5>
          {active === 3 ? (
            <div className={`${styles.active_indicator2}`} />
          ) : null}
        </div>
      </div>

      {active === 1 ? (
        <>
          <p className="py-2 text-[15px] md:text-[18px] leading-4 p-2 md:p-4 md:leading-5 md:pb-10 whitespace-pre-line rounded-md text-white bg-deepblue">
            {stripHtmlTags(data.description)}
          </p>
        </>
      ) : null}

     
      {active === 3 && (
        <div className="w-full block 800px:flex p-2 md:p-4 rounded-md text-white bg-deepblue">
          <div className="w-full 800px:w-[50%]">
            <Link to={`/shop/preview/${data.shop._id}`}>
              <div className="flex items-center">
                <img
                  src={`${data?.shop?.avatar?.url}`}
                  className="w-[50px] h-[50px] rounded-full"
                  alt=""
                />
                <div className="pl-3">
                  <h3 className={`${styles.shop_name2} font-semibold !text-white`}>{data.shop.name}</h3>
                  {/* <h5 className="pb-2 text-[15px]">
                    <span className="font-[500]">
                      ({averageRating}/5) Ratings
                    </span>
                  </h5> */}
                </div>
              </div>
            </Link>
            <p className="pt-2">{data.shop.description}</p>
          </div>
          <div className="w-full 800px:w-[50%] mt-5 800px:mt-0 800px:flex flex-col items-end">
            <div className="flex items-center justify-start text-left">
              {/* <h5 className="font-[600]">
                Joined on:{" "}
                <span className="font-[500]">
                  {data.shop?.createdAt?.slice(0, 10)}
                </span>
              </h5> */}
              <h5 className="font-[500] pt-3">
                Total Courses Taking:{" "}
                <span className="font-[500]">
                  {products && products.length}
                </span>
              </h5>
              {/* <h5 className="font-[500] pt-3">
                Total Reviews:{" "}
                <span className="font-[500]">{totalReviewsLength}</span>
              </h5> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
